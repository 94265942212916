import React, { useState, useEffect, useCallback } from 'react'
import { Form, Input, Button, message, Card, Steps, Result, Alert } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import api from '../utils/api'
import { useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const { Step } = Steps

const PageContainer = styled.div`
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
`

const StyledCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const StepContent = styled.div`
  margin-top: 24px;
`

const WelcomeText = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
`

const LineUserEmailSetup = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [email, setEmail] = useState('')
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const checkUserStatus = useCallback(async () => {
    try {
      const response = await api.get('/api/user/me')
      const user = response.data.data
      if (!user.email.startsWith('line_')) {
        // 用戶已經設置了郵箱，重定向到主頁
        navigate('/')
      }
    } catch (error) {
      console.error('Error checking user status:', error)
      message.error('無法檢查用戶狀態')
    }
  }, [navigate])

  const handleEmailSubmit = async (values) => {
    setIsSubmitting(true)
    setErrorMessage('')
    try {
      await api.post('/api/auth/request-change-line-user-email', {
        newEmail: values.email
      })
      setEmail(values.email)
      message.success('驗證郵件已發送，請查看您的郵箱並點擊驗證鏈接')
      setCurrentStep(1)
    } catch (error) {
      console.error('Error requesting email change:', error)
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error)
      } else {
        setErrorMessage('發送驗證郵件失敗，請稍後再試')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleVerificationSubmit = useCallback(
    async (token, newEmail, complete = false) => {
      try {
        await api.post(
          `/api/auth/verify-and-change-line-user-email?token=${token}`,
          {
            newEmail: newEmail
          }
        )
        message.success('郵箱驗證成功！')
        if (complete) {
          setCurrentStep(2)
        } else {
          setCurrentStep(1)
        }
      } catch (error) {
        console.error('Error verifying email:', error)
        message.error('郵箱驗證失敗')
        setCurrentStep(0)
      }
    },
    [setCurrentStep]
  )

  useEffect(() => {
    checkUserStatus()
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const newEmail = params.get('email')
    const complete = params.get('complete')
    if (token && newEmail) {
      handleVerificationSubmit(token, newEmail, complete === 'true')
    }
  }, [location, checkUserStatus, handleVerificationSubmit])

  const steps = [
    {
      title: '設置郵箱',
      content: (
        <Form form={form} onFinish={handleEmailSubmit}>
          <WelcomeText>
            歡迎使用我們的服務！請設置您的郵箱，以便接收預約提醒和獨家優惠。
          </WelcomeText>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type='error'
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
          <Form.Item
            name='email'
            rules={[
              { required: true, message: '請輸入您的郵箱' },
              { type: 'email', message: '請輸入有效的郵箱地址' }
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder='您的郵箱地址' />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              block
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {isSubmitting ? '發送中...' : '發送驗證郵件'}
            </Button>
          </Form.Item>
        </Form>
      )
    },
    {
      title: '驗證郵箱',
      content: (
        <div>
          <WelcomeText>
            我們已經向您的郵箱 {email}{' '}
            發送了一封驗證郵件。請查看您的郵箱並點擊驗證鏈接來完成驗證。
          </WelcomeText>
        </div>
      )
    },
    {
      title: '完成',
      content: (
        <Result
          status='success'
          title='郵箱設置成功！'
          subTitle='您現在可以接收預約提醒和獨家優惠了。'
          extra={[
            <Button type='primary' key='console' onClick={() => navigate('/')}>
              返回首頁
            </Button>
          ]}
        />
      )
    }
  ]

  return (
    <PageContainer>
      <Helmet>
        <title>{`全台美業一站式線上預約平台 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <StyledCard>
        <Steps current={currentStep}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <StepContent>{steps[currentStep].content}</StepContent>
      </StyledCard>
    </PageContainer>
  )
}

export default LineUserEmailSetup
