import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  LeftOutlined,
  RightOutlined,
  ShoppingCartOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons'
import { motion, AnimatePresence } from 'framer-motion' // 需要安裝 framer-motion
import { Toast } from 'antd-mobile'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../utils/api'
import { PHOTO_URL } from '../utils/apiutils'
import Loading from '../utils/Loading'
import { useAuth } from '../hooks/AuthContext'
import CheckoutModal from './CheckoutModal'

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background: #f8f8f8;
  text-align: left !important;

  @media (max-width: 820px) {
    padding: 10px;
    overflow-x: hidden; // 防止水平滾動
  }
`

const ProductLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 40px;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 0;
  }
`

const ImageSection = styled.div`
  position: relative;
  width: 540px;
  height: 100%;
  border-radius: 8px;
  overflow: scroll;

  @media (max-width: 820px) {
    margin-bottom: 0;
    width: 100%;
  }
`

const MainImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 8px;
  background: #fff;

  @media (max-width: 820px) {
    width: 100vw;
    height: auto;
    margin-left: -10px;
    margin-right: -10px;
    border-radius: 0;
  }
`

const ImageWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  @media (max-width: 820px) {
    width: 100vw;
  }
`

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 820px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`

const ThumbnailContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  padding-top: 10px;
  width: 100%;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 820px) {
    padding-left: 10px;
  }
`

const ThumbnailArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.$left ? 'left: 0;' : 'right: 0;')}
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 820px) {
    width: 36px;
    height: 36px;
  }

  ${(props) => (props.$left ? 'left: 10px;' : 'right: 10px;')}
`

const ImageCounter = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  z-index: 2;
`

const Thumbnail = styled.img`
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.$active ? '#d5728a' : 'transparent')};
  transition: all 0.3s ease;
  flex-shrink: 0;

  &:hover {
    transform: translateY(-2px);
  }

  @media (max-width: 820px) {
    width: 85px;
    height: 85px;
  }
  @media (max-width: 500px) {
    width: 65px;
    height: 65px;
  }
`

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`

const ProductTitle = styled.h1`
  font-size: 24px;
  text-align: left;
  margin: 0;
  color: #333;
`

const ProductTag = styled.span`
  display: inline-block;
  padding: 4px 8px;
  background: #ffe5e5;
  color: #d5728a;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
`

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: #f8f8f8;
  /* padding: 20px; */
  border-radius: 8px;
`

const Price = styled.div`
  font-size: 28px;
  color: #d5728a;
  font-weight: bold;
  text-align: left;

  span {
    font-size: 16px;
    color: #999;
    text-decoration: line-through;
    margin-left: 10px;
  }
`

const QuantitySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 10px;
  margin: 20px 0;
  span {
    font-size: 14px;
    text-align: left;
  }
`

const QuantityButton = styled.button`
  width: 50px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`

const QuantityInput = styled.input`
  width: 100%;
  height: 36px;
  margin: 0 5px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
`

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 820px) {
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    padding: 10px;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
`

const Button = styled.button`
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;

  ${(props) =>
    props.$primary
      ? `
    background: #d5728a;
    color: white;
    &:hover {
      background: #bf5170;
    }
  `
      : `
    background: #2c3e50;
    color: white;
    &:hover {
      background: #34495e;
    }
  `}
`

const ProductDescription = styled.div`
  margin-top: 40px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  text-align: left;
  font-size: 16px;
  h2 {
    font-size: 18px;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #f6f6f6;
    text-align: left;
  }
`

const ImageModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
  user-select: none;
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 20px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

const ModalNavButton = styled(NavigationButton)`
  background: rgba(255, 255, 255, 0.2);
  width: 50px;
  height: 50px;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 820px) {
    width: 40px;
    height: 40px;
  }
`

// 添加浮動結帳按鈕樣式
const FloatingCheckoutButton = styled(motion.button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #d5728a;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(213, 114, 138, 0.4);
  z-index: 100;
  flex-direction: column;
  line-height: 1.2;
  font-size: 14px;

  &:hover {
    background: #bf5170;
  }

  @media (max-width: 820px) {
    bottom: 150px;
    width: 80px;
    height: 80px;
  }

  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`

const FloatingNotification = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -40%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`

const CheckIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #00c853;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  svg {
    color: white;
    font-size: 24px;
  }
`

// 添加規格選擇相關樣式
const SpecificationSection = styled.div`
  margin: 20px 0;
`

const SpecTitle = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
`

const SpecOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const SpecOption = styled.button`
  padding: 8px 16px;
  border: 1px solid ${(props) => (props.$active ? '#d5728a' : '#ddd')};
  background: ${(props) => (props.$active ? '#fff' : '#fff')};
  color: ${(props) => (props.$active ? '#d5728a' : '#666')};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: #d5728a;
    color: #d5728a;
  }
`

const ProductDetail = () => {
  const { user } = useAuth()
  const { productId, businessId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [product, setProduct] = useState(null)
  const [currentImage, setCurrentImage] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [selectedSpecs, setSelectedSpecs] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false)
  const thumbnailRef = useRef(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [cartCount, setCartCount] = useState(0)
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false)

  useEffect(() => {
    const fetchProductDetail = async () => {
      if (!productId || !businessId) {
        setError('無法獲取商品詳情')
        setLoading(false)
        return
      }

      try {
        const response = await api.get(
          `/api/client/store/${businessId}/products/${productId}`
        )
        setProduct(response.data.data)

        // 初始化規格選擇
        const initialSpecs = {}
        response.data.data.specifications?.forEach((spec) => {
          initialSpecs[spec.name] = spec.options[0]
        })
        setSelectedSpecs(initialSpecs)
      } catch (error) {
        console.error('獲取商品詳情失敗:', error)
        setError('獲取商品詳情失敗')
      } finally {
        setLoading(false)
      }
    }

    fetchProductDetail()
  }, [productId, businessId])

  const handleSpecChange = (specName, option) => {
    setSelectedSpecs((prev) => ({
      ...prev,
      [specName]: option
    }))
  }

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % product.images.length)
  }

  const prevImage = () => {
    setCurrentImage(
      (prev) => (prev - 1 + product.images.length) % product.images.length
    )
  }

  const handleQuantityChange = (value) => {
    const newValue = Math.max(1, Math.min(99, value))
    setQuantity(newValue)
  }

  const openModal = () => {
    setIsModalOpen(true)
    // 禁止背景滾動
    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsModalOpen(false)
    // 恢復背景滾動
    document.body.style.overflow = 'unset'
  }

  // 更新购物车数量的函数
  const updateCartCount = () => {
    const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
    const count = localCart.reduce((sum, item) => sum + item.quantity, 0)
    setCartCount(count)
  }

  // 修改加入購物車邏輯
  const handleAddToCart = async () => {
    if (!businessId || !productId) {
      Toast.show({
        icon: 'fail',
        content: '無法加入購物車',
        position: 'bottom'
      })
      return
    }

    try {
      const specificationArray = Object.entries(selectedSpecs).map(
        ([name, selected]) => ({
          name,
          selected
        })
      )

      const cartData = {
        productId,
        businessId,
        quantity,
        specifications: specificationArray
      }

      if (user) {
        // 已登入用戶：調用 API
        await api.put('/api/client/store/cart', {
          ...cartData,
          action: 'add'
        })
        // 觸發購物車更新事件
        window.dispatchEvent(new CustomEvent('cartUpdated'))
      } else {
        // 未登入用戶：更新 localStorage
        const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
        const existingItemIndex = localCart.findIndex(
          (item) =>
            item.productId === productId &&
            JSON.stringify(item.specifications) ===
              JSON.stringify(specificationArray)
        )

        if (existingItemIndex !== -1) {
          localCart[existingItemIndex].quantity += quantity
        } else {
          localCart.push({
            ...cartData,
            image: product.images[0],
            title: product.title,
            price: product.price,
            originalPrice: product.originalPrice
          })
        }

        localStorage.setItem('cart', JSON.stringify(localCart))
        // 觸發購物車更新事件
        window.dispatchEvent(new CustomEvent('cartUpdated'))
      }

      setShowCheckout(true)
      setShowNotification(true)

      setTimeout(() => {
        setShowNotification(false)
      }, 3000)
    } catch (error) {
      console.error('加入購物車失敗:', error)
      Toast.show({
        icon: 'fail',
        content: '加入購物車失敗',
        position: 'bottom'
      })
    }
  }

  // 修改直接結帳邏輯
  const handleDirectCheckout = () => {
    // 創建單個商品的結帳數據
    const checkoutItem = {
      productId,
      businessId,
      quantity,
      specifications: Object.entries(selectedSpecs).map(([name, selected]) => ({
        name,
        selected
      })),
      image: product.images[0],
      title: product.title,
      price: product.price,
      originalPrice: product.originalPrice
    }

    // 將單個商品存儲到 localStorage 中的臨時結帳項目
    localStorage.setItem('directCheckoutItem', JSON.stringify(checkoutItem))
    setIsCheckoutModalOpen(true)
  }

  // 更新商品數量
  const handleQuantityUpdate = async (newQuantity) => {
    if (!businessId || !productId) return

    try {
      const specificationArray = Object.entries(selectedSpecs).map(
        ([name, selected]) => ({
          name,
          selected
        })
      )

      if (user) {
        // 已登錄用戶：調用 API
        await api.put('/api/client/store/cart', {
          action: 'update',
          businessId,
          productId,
          quantity: newQuantity,
          specifications: specificationArray
        })
      } else {
        // 未登錄用戶：更新 localStorage
        const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
        const updatedCart = localCart.map((item) => {
          if (
            item.productId === productId &&
            JSON.stringify(item.specifications) ===
              JSON.stringify(specificationArray)
          ) {
            return { ...item, quantity: newQuantity }
          }
          return item
        })
        localStorage.setItem('cart', JSON.stringify(updatedCart))
      }

      setQuantity(newQuantity)
      Toast.show({
        icon: 'success',
        content: '數量已更新',
        position: 'bottom'
      })
    } catch (error) {
      console.error('更新數量失敗:', error)
      Toast.show({
        icon: 'fail',
        content: '更新數量失敗',
        position: 'bottom'
      })
    }
  }

  // 從購物車移除商品
  const handleRemoveFromCart = async () => {
    if (!businessId || !productId) return

    try {
      const specificationArray = Object.entries(selectedSpecs).map(
        ([name, selected]) => ({
          name,
          selected
        })
      )

      if (user) {
        // 已登錄用戶：調用 API
        await api.put('/api/client/store/cart', {
          action: 'remove',
          businessId,
          productId,
          specifications: specificationArray
        })
      } else {
        // 未登錄用戶：更新 localStorage
        const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
        const updatedCart = localCart.filter(
          (item) =>
            !(
              item.productId === productId &&
              JSON.stringify(item.specifications) ===
                JSON.stringify(specificationArray)
            )
        )
        localStorage.setItem('cart', JSON.stringify(updatedCart))
      }

      setShowCheckout(false)
      Toast.show({
        icon: 'success',
        content: '已從購物車移除',
        position: 'bottom'
      })
    } catch (error) {
      console.error('移除商品失敗:', error)
      Toast.show({
        icon: 'fail',
        content: '移除商品失敗',
        position: 'bottom'
      })
    }
  }

  // 檢查是否需要顯示箭頭
  const checkArrows = () => {
    if (thumbnailRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = thumbnailRef.current
      setShowLeftArrow(scrollLeft > 0)
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth)
    }
  }

  // 處理縮圖滾動
  const scrollThumbnails = (direction) => {
    if (thumbnailRef.current) {
      const scrollAmount = 200
      thumbnailRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      })
    }
  }

  // 監聽滾動事件
  useEffect(() => {
    const thumbnailElement = thumbnailRef.current
    if (thumbnailElement) {
      checkArrows()
      thumbnailElement.addEventListener('scroll', checkArrows)
      return () => thumbnailElement.removeEventListener('scroll', checkArrows)
    }
  }, [])

  if (loading) return <Loading />
  if (error) return <div>{error}</div>
  if (!product) return <div>商品不存在</div>

  return (
    <Container>
      <ProductLayout>
        <ImageSection>
          <MainImageContainer>
            <ImageCounter>
              {currentImage + 1} / {product.images.length}
            </ImageCounter>

            <NavigationButton $left onClick={prevImage}>
              <LeftOutlined />
            </NavigationButton>

            <AnimatePresence mode='wait'>
              <ImageWrapper
                key={currentImage}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.3 }}
                onClick={openModal}
                style={{ cursor: 'zoom-in' }}
              >
                <ProductImage
                  src={`${PHOTO_URL}${product.images[currentImage]}`}
                  alt={product.title}
                />
              </ImageWrapper>
            </AnimatePresence>

            <NavigationButton onClick={nextImage}>
              <RightOutlined />
            </NavigationButton>
          </MainImageContainer>

          <ThumbnailContainer>
            {showLeftArrow && (
              <ThumbnailArrow $left onClick={() => scrollThumbnails('left')}>
                <LeftOutlined />
              </ThumbnailArrow>
            )}
            <div
              ref={thumbnailRef}
              style={{ overflowX: 'auto', display: 'flex', gap: '10px' }}
            >
              {product.images.map((image, index) => (
                <Thumbnail
                  key={index}
                  src={`${PHOTO_URL}${image}`}
                  alt={`${product.title} ${index + 1}`}
                  $active={currentImage === index}
                  onClick={() => setCurrentImage(index)}
                />
              ))}
            </div>
            {showRightArrow && (
              <ThumbnailArrow onClick={() => scrollThumbnails('right')}>
                <RightOutlined />
              </ThumbnailArrow>
            )}
          </ThumbnailContainer>
        </ImageSection>

        <AnimatePresence>
          {isModalOpen && (
            <ImageModal
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeModal}
            >
              <CloseButton onClick={closeModal}>
                <CloseOutlined />
              </CloseButton>

              <ModalNavButton
                $left
                onClick={(e) => {
                  e.stopPropagation()
                  prevImage()
                }}
              >
                <LeftOutlined />
              </ModalNavButton>

              <motion.div
                key={currentImage}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.2 }}
                onClick={(e) => e.stopPropagation()}
              >
                <ModalImage
                  src={`${PHOTO_URL}${product.images[currentImage]}`}
                  alt={product.title}
                />
              </motion.div>

              <ModalNavButton
                onClick={(e) => {
                  e.stopPropagation()
                  nextImage()
                }}
              >
                <RightOutlined />
              </ModalNavButton>

              <ImageCounter>
                {currentImage + 1} / {product.images.length}
              </ImageCounter>
            </ImageModal>
          )}
        </AnimatePresence>

        <ProductInfo>
          <div>
            <ProductTag>{product.tag}</ProductTag>
            <ProductTitle>{product.title}</ProductTitle>
          </div>

          <PriceSection>
            <Price>
              NT$ {product.price}
              {product.originalPrice && (
                <span>NT$ {product.originalPrice}</span>
              )}
            </Price>

            {/* 添加規格選擇 */}
            {product.specifications?.map((spec) => (
              <SpecificationSection key={spec._id}>
                <SpecTitle>{spec.name}</SpecTitle>
                <SpecOptions>
                  {spec.options.map((option) => (
                    <SpecOption
                      key={option}
                      $active={selectedSpecs[spec.name] === option}
                      onClick={() => handleSpecChange(spec.name, option)}
                    >
                      {option}
                    </SpecOption>
                  ))}
                </SpecOptions>
              </SpecificationSection>
            ))}

            <QuantitySection>
              <span>數量</span>
              <div style={{ display: 'flex', width: '100%' }}>
                <QuantityButton
                  onClick={() => handleQuantityChange(quantity - 1)}
                >
                  -
                </QuantityButton>
                <QuantityInput
                  type='number'
                  value={quantity}
                  onChange={(e) => handleQuantityChange(Number(e.target.value))}
                  min='1'
                  max='99'
                />
                <QuantityButton
                  onClick={() => handleQuantityChange(quantity + 1)}
                >
                  +
                </QuantityButton>
              </div>
            </QuantitySection>
          </PriceSection>

          <ActionButtons>
            <Button onClick={handleAddToCart}>
              <ShoppingCartOutlined /> 加入購物車
            </Button>
            <Button $primary onClick={handleDirectCheckout}>
              直接結帳
            </Button>
          </ActionButtons>
        </ProductInfo>
      </ProductLayout>

      <ProductDescription>
        <h2>產品介紹</h2>
        <div dangerouslySetInnerHTML={{ __html: product.description }} />
      </ProductDescription>

      {/* 浮動結帳按鈕 */}
      <AnimatePresence>
        {showCheckout && (
          <FloatingCheckoutButton
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            onClick={() => navigate('/cart')}
          >
            <span>立即</span>
            <span>結帳</span>
          </FloatingCheckoutButton>
        )}
      </AnimatePresence>

      {showNotification && (
        <FloatingNotification>
          <CheckIcon>
            <CheckOutlined />
          </CheckIcon>
          已加入購物車
        </FloatingNotification>
      )}

      {/* 添加 CheckoutModal */}
      <CheckoutModal
        isOpen={isCheckoutModalOpen}
        onClose={() => {
          setIsCheckoutModalOpen(false)
          localStorage.removeItem('directCheckoutItem') // 關閉時清除臨時結帳項目
        }}
        total={product.price * quantity}
        isDirectCheckout={true}
      />
    </Container>
  )
}

export default ProductDetail
