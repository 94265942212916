import { useCallback, useEffect, useRef } from 'react'
import { initSocket, getSocket, disconnectSocket } from '../utils/socket'

export const useSocket = () => {
  const isConnected = useRef(false)

  const connectSocket = useCallback((userId) => {
    if (!isConnected.current) {
      initSocket()
      const socket = getSocket()
      if (socket) {
        socket.emit('user_connected', userId)
        console.log('user_connected', userId)
        isConnected.current = true
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      if (isConnected.current) {
        disconnectSocket()
        isConnected.current = false
      }
    }
  }, [])

  return {
    connectSocket,
    disconnectSocket
  }
}

export const useSocketMessage = (callback) => {
  useEffect(() => {
    const socket = getSocket()
    if (socket) {
      socket.on('server_message', callback)
      return () => {
        socket.off('server_message', callback)
      }
    }
  }, [callback])
}
