import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { message } from 'antd'
import {
  SearchOutlined,
  DownOutlined,
  UpOutlined,
  CloseOutlined
} from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Loading from '../utils/Loading'
import { Helmet } from 'react-helmet-async'

moment.locale('zh-cn')

const Container = styled.div`
  padding: 10px 100px;
  @media (max-width: 820px) {
    padding: 10px;
  }
  @media (max-width: 480px) {
    padding: 20px 10px;
    background: #f6f6f6;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  cursor: pointer;
`

const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`
const Title = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease;
`

const StyledTag = styled.div`
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  margin-right: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#fff')};
  color: ${(props) => (props.$active ? '#fff' : '#000')};
  white-space: nowrap;
`

const ExpandButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, transparent, white 30%);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #d5728a;
`

const SearchSection = styled.div`
  display: flex;
  flex: 1;
  height: 25px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #f2b3c0;
  border-radius: 30px;
  padding: 5px 10px;
  margin-bottom: 20px;
  position: relative;
  @media (max-width: 768px) {
  }
`

const SearchInput = styled.input`
  flex: 1;
  font-size: 13px;
  border: none;
  outline: none;
  @media (max-width: 425px) {
    max-width: 120px;
  }
`

const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #d5728a;

  &:hover {
    color: #a8506a;
  }
`

const SearchButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #d5728a;

  &:hover {
    color: #a8506a;
  }
`

const ArticlesContainer = styled.div`
  column-count: 3;
  column-gap: 10px;

  @media (max-width: 1024px) {
    column-count: 2;
  }
`

const ArticleCard = styled(motion.div)`
  break-inside: avoid;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  @media (max-width: 480px) {
    border-radius: 5px;
    box-shadow: none;
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`

const ArticleImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`

const ArticleContent = styled.div`
  padding: 15px;
  @media (max-width: 480px) {
    padding: 5px;
  }
`

const ArticleTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 18px;
  color: #333;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`

const ArticleExcerpt = styled.p`
  margin: 0 0 10px;
  font-size: 14px;
  color: #666;
  @media (max-width: 820px) {
    display: ${({ content }) => (content.length > 50 ? 'block' : 'none')};
  }
  @media (max-width: 480px) {
    display: none;
  }
`

const ArticleMeta = styled.div`
  font-size: 12px;
  color: #999;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`

const BlogPage = () => {
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTag, setSelectedTag] = useState(null)
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false)
  const tagContainerRef = useRef(null)

  const tags = [
    { value: 'beauty', label: '美容保養' },
    { value: 'hair', label: '髮型風格' },
    { value: 'surgery', label: '醫美話題' },
    { value: 'body', label: '美體養生' },
    { value: 'fashion', label: '時尚話題' },
    { value: 'art', label: '人文藝術' },
    { value: 'motivation', label: '心靈勵志' },
    { value: 'nail', label: '美甲設計' },
    { value: 'about', label: '創辦日記' }
  ]

  useEffect(() => {
    fetchArticles()
  }, [])

  useEffect(() => {
    const checkOverflow = () => {
      if (tagContainerRef.current) {
        const isOverflowing =
          tagContainerRef.current.scrollWidth >
          tagContainerRef.current.clientWidth
        setIsExpanded(!isOverflowing)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  const fetchArticles = async (tag = null) => {
    setLoading(true)
    try {
      const url = tag
        ? `${API_BASE_URL}/api/articles/category/${tag}`
        : `${API_BASE_URL}/api/articles/all`
      const response = await axios.get(url)
      setArticles(response.data.data)
    } catch (error) {
      message.error('無法獲取文章數據')
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = () => {
    navigate(`/search/${searchTerm}`)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleClearSearch = () => {
    setSearchTerm('')
  }

  const handleTagClick = (tag) => {
    setSelectedTag(tag)
    fetchArticles(tag)
  }

  const handleArticleClick = (id) => {
    navigate(`/articles/${id}`)
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      {window.location.pathname === '/articles' && (
        <Helmet>
          <title>2024最新時尚話題文章專欄列表 - 美感｜BeauteFeel</title>
          <meta
            name='description'
            content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
          />
        </Helmet>
      )}
      <TitleContainer onClick={() => navigate('/articles')}>
        <TitleBar />
        <Title>文章專欄</Title>
      </TitleContainer>
      <SearchSection>
        <SearchInput
          type='text'
          placeholder='搜索文章、感興趣的話題與項目'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        {searchTerm && (
          <ClearButton onClick={handleClearSearch}>
            <CloseOutlined />
          </ClearButton>
        )}
        <SearchButton onClick={handleSearch}>
          <SearchOutlined />
        </SearchButton>
      </SearchSection>

      <TagContainer
        ref={tagContainerRef}
        style={{ maxHeight: isExpanded ? '1000px' : '40px' }}
      >
        <StyledTag
          $active={selectedTag === null}
          onClick={() => handleTagClick(null)}
        >
          全部
        </StyledTag>
        {tags.map((tag) => (
          <StyledTag
            key={tag.value}
            $active={selectedTag === tag.value}
            onClick={() => handleTagClick(tag.value)}
          >
            {tag.label}
          </StyledTag>
        ))}
        {!isExpanded && (
          <ExpandButton onClick={toggleExpand}>
            更多 <DownOutlined />
          </ExpandButton>
        )}
        {isExpanded && (
          <ExpandButton onClick={toggleExpand}>
            收起 <UpOutlined />
          </ExpandButton>
        )}
      </TagContainer>

      <ArticlesContainer>
        {articles.map((article) => (
          <ArticleCard
            key={article._id}
            onClick={() => handleArticleClick(article._id)}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ArticleImage
              src={`${PHOTO_URL}${article.image}`}
              alt={article.title}
            />
            <ArticleContent>
              <ArticleTitle>{article.title}</ArticleTitle>
              <ArticleExcerpt
                content={article.content}
                dangerouslySetInnerHTML={{
                  __html:
                    article.content.length > 50
                      ? `${article.content.substring(0, 50)}...`
                      : article.content
                }}
              />
              <ArticleMeta>
                {article.username} · {moment(article.createdAt).format('LL')}
              </ArticleMeta>
            </ArticleContent>
          </ArticleCard>
        ))}
      </ArticlesContainer>
    </Container>
  )
}

export default BlogPage
