import React, { useEffect, useState, useRef } from 'react'
import ReactQuill from 'react-quill'
import { AiOutlineInstagram, AiFillFacebook } from 'react-icons/ai'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import { Input, Modal, Upload, message, Button, Card } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { PHOTO_URL } from '../utils/apiutils'
import api from '../utils/api'

const EditorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid black;
  }

  .ql-container.ql-snow {
    border: 1px solid black;
    border-radius: 4px;
    height: 500px;
    overflow: scroll;
  }
`

const QuillWrapper = styled.div`
  flex: 2;
`

const UploadWrapper = styled.div`
  flex: 1;
`

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      image: function () {
        const quill = this.quill
        quill.root.dispatchEvent(new CustomEvent('open-image-modal'))
      }
    }
  }
}

/*
 ** Options for Social Media Embeds
 **/
const socialToolOptions = {
  instagram: {
    label: 'Instagram',
    active: true,
    description: 'Add Instagram Url'
  },
  facebook: {
    label: 'FaceBook',
    active: true,
    description: 'Add FaceBook Embed Code'
  }
}

/*
 ** Quill Toolbar component
 **/
const CustomQuillEditor = ({
  value,
  onChange,
  onCoverImageChange,
  coverImagePreview
}) => {
  const [uniqueId] = useState('dfwrbgrwkigrekhgmrkm')
  const [socialTools, setSocialTools] = useState({
    active: false,
    label: '',
    code: '',
    description: ''
  })
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [quillImagePreview, setQuillImagePreview] = useState(null)
  const [coverImagePreviewLocal, setCoverImagePreviewLocal] =
    useState(coverImagePreview)
  const [isCoverImageModalOpen, setIsCoverImageModalOpen] = useState(false)
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [coverImagePreviewUrl, setCoverImagePreviewUrl] =
    useState(coverImagePreview)

  const quillRef = useRef()

  useEffect(() => {
    const Embed = ReactQuill.Quill.import('blots/block/embed')

    /*
     ** Quill Instagram Embed component
     **/
    class InstagramPostBlot extends Embed {
      static create(actualUrl) {
        const node = super.create()
        const embedCode = `<iframe src="${actualUrl}embed" width="350" height="500" frameborder="0" allowtransparency="true"></iframe>`
        node.innerHTML = embedCode
        return node
      }
    }

    InstagramPostBlot.blotName = 'Instagram'
    InstagramPostBlot.tagName = 'div'
    InstagramPostBlot.className = `instagram-${uniqueId}`
    Quill.register(InstagramPostBlot, true)

    /*
     ** Quill Facebook Embed component
     **/
    class FacebookEmbedBlot extends Embed {
      static create(postUrl) {
        const node = super.create()
        node.innerHTML = postUrl
        return node
      }
    }

    FacebookEmbedBlot.blotName = 'Facebook'
    FacebookEmbedBlot.tagName = 'div'
    FacebookEmbedBlot.className = `facebook-${uniqueId}`
    Quill.register(FacebookEmbedBlot, true)
  }, [uniqueId])

  /*
   ** Instagram Handle Function
   **/
  const insertInstagramPost = (mediaUrl) => {
    const quill = quillRef.current.getEditor()
    quill.focus()
    const range = quill.getSelection()
    if (mediaUrl !== null && mediaUrl !== '') {
      let modifiedUrl = mediaUrl.split('?')
      let actualUrl = modifiedUrl[0]

      if (actualUrl) {
        if (range) {
          quill.insertText(range.index, '\n', Quill.sources.USER)
          quill.insertEmbed(range.index + 1, 'Instagram', actualUrl)
          quill.setSelection(range.index + 2, Quill.sources.SILENT)
        } else {
          const length = quill.getLength()
          quill.insertEmbed(length, 'Instagram', mediaUrl)
        }
      }
    }
  }

  /*
   ** Facebook Handle Function
   **/
  const handleFacebookEmbed = (embedCode) => {
    const quill = quillRef.current.getEditor()
    quill.focus()
    const range = quill.getSelection()
    if (embedCode) {
      quill.insertText(range.index, '\n', Quill.sources.USER)
      quill.insertEmbed(
        range.index + 1,
        'Facebook',
        embedCode,
        Quill.sources.USER
      )
      quill.setSelection(range.index + 2, Quill.sources.SILENT)
    }
  }

  /*
   ** Get Social Media Content
   **/
  const fetchSocialMediaContent = () => {
    setSocialTools({
      ...socialTools,
      active: false
    })
    switch (socialTools.label) {
      case 'Instagram':
        insertInstagramPost(socialTools.code)
        break
      case 'FaceBook':
        handleFacebookEmbed(socialTools.code)
        break
      default:
        console.log('none')
        break
    }
  }

  /*
   ** Modal for Social Media Content
   **/
  const modal = (data) => {
    return (
      <Modal
        title=''
        open={socialTools.active}
        onOk={fetchSocialMediaContent}
        className='listdeletemodal'
        onCancel={() =>
          setSocialTools({
            ...socialTools,
            active: false,
            label: '',
            code: '',
            description: ''
          })
        }
      >
        <h3>{data.label} Tool</h3>
        <Input
          placeholder={data.description}
          onChange={(e) => {
            setSocialTools({ ...socialTools, code: e.target.value })
          }}
        />
      </Modal>
    )
  }

  /*
   ** Handle Image Upload Success
   **/
  const handleImageUploadSuccess = async () => {
    if (!file) {
      message.error('請先選擇圖片')
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await api.post(`/api/articles/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log('Upload response:', response.data)

      const quillEditor = quillRef.current.getEditor()
      const range = quillEditor.getSelection(true)
      const imageUrl = `${PHOTO_URL}${response.data.imageUrl}`
      console.log('Image URL:', imageUrl)
      quillEditor.insertEmbed(range.index, 'image', imageUrl)
      quillEditor.setSelection(range.index + 1)

      setIsImageModalOpen(false)
      setFile(null)
      setQuillImagePreview(null)
    } catch (error) {
      message.error('圖片上傳失敗')
      console.error('Upload error:', error)
    }
  }

  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        if (file.size / 1024 / 1024 > 5) {
          message.error('圖片上傳大小不要超過5MB,請重新上傳！')
          return false
        }
        setFile(file)
        const reader = new FileReader()
        reader.onload = () => {
          setQuillImagePreview(reader.result)
        }
        reader.readAsDataURL(file)
      } else {
        message.error('圖片上傳只支持JPG/PNG格式,請重新上傳！')
        return false
      }
      return false
    }
  }

  const handleCoverImageUpload = (file) => {
    if (
      file.type === 'image/png' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/gif'
    ) {
      if (file.size / 1024 / 1024 > 5) {
        message.error('圖片上傳大小不要超過5MB,請重新上傳！')
        return false
      }
      console.log('Setting cover image file:', file)
      setCoverImageFile(file)
      const reader = new FileReader()
      reader.onload = () => {
        setCoverImagePreviewLocal(reader.result)
      }
      reader.readAsDataURL(file)
      setIsCoverImageModalOpen(true)
    } else {
      message.error('圖片上傳只支持JPG/PNG/GIF格式,請重新上傳！')
    }
    return false
  }

  const handleCoverImageUploadSuccess = async () => {
    if (!coverImageFile) {
      message.error('請先選擇圖片')
      return
    }

    const formData = new FormData()
    formData.append('file', coverImageFile)

    console.log('Uploading cover image:', coverImageFile)

    try {
      const response = await api.post(`/api/articles/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log('Cover upload response:', response.data)

      const imageUrl = `${PHOTO_URL}${response.data.imageUrl}`
      const image = `${response.data.imageUrl}`
      console.log('Cover Image URL:', imageUrl)

      if (onCoverImageChange) {
        onCoverImageChange(image)
      }

      setCoverImagePreviewUrl(imageUrl) // 更新预览图 URL
      setIsCoverImageModalOpen(false)
      setCoverImageFile(null)
      setCoverImagePreviewLocal(null)
    } catch (error) {
      console.error('Cover upload error:', error)
      message.error('封面圖片上傳失敗')
    }
  }

  const coverUploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: handleCoverImageUpload
  }

  return (
    <EditorContainer>
      <QuillWrapper>
        <div className='text-editor flex flex-col items-center'>
          <div id='toolbar' className='quillEditor-toolbar'>
            <span className='ql-formats'>
              <select className='ql-size' defaultValue='medium'>
                <option value='large'>Large</option>
                <option value='medium'>Medium</option>
                <option value='small'>Small</option>
              </select>
              <select className='ql-header' defaultValue='3'>
                <option value='1'>Heading</option>
                <option value='2'>Subheading</option>
                <option value='3'>Normal</option>
              </select>
            </span>
            <span className='ql-formats'>
              <button className='ql-bold' />
              <button className='ql-italic' />
              <button className='ql-underline' />
              <button className='ql-strike' />
            </span>
            <span className='ql-formats'>
              <button className='ql-list' value='ordered' />
              <button className='ql-list' value='bullet' />
              <button className='ql-indent' value='-1' />
              <button className='ql-indent' value='+1' />
            </span>
            <span className='ql-formats'>
              <button className='ql-script' value='super' />
              <button className='ql-script' value='sub' />
              <button className='ql-blockquote' />
              <button className='ql-direction' />
            </span>
            <span className='ql-formats'>
              <select className='ql-align' />
              <select className='ql-color' />
              <select className='ql-background' />
            </span>
            <span className='ql-formats'>
              <button className='ql-link' />
              <button
                className='ql-image'
                onClick={() => setIsImageModalOpen(true)}
              />
              <button className='ql-video' />
              <button
                className='ql-instagram'
                onClick={() => {
                  setSocialTools({
                    ...socialTools,
                    active: true,
                    ...socialToolOptions.instagram
                  })
                }}
              >
                <AiOutlineInstagram />
              </button>
              <button
                className='ql-facebook'
                onClick={() => {
                  setSocialTools({
                    ...socialTools,
                    active: true,
                    ...socialToolOptions.facebook
                  })
                }}
              >
                <AiFillFacebook />
              </button>
            </span>
            <span className='ql-formats'>
              <button className='ql-formula' />
              <button className='ql-code-block' />
              <button className='ql-clean' />
            </span>
          </div>
          <div className=' '>
            <ReactQuill
              className='quillEditor-container'
              modules={modules}
              value={value}
              placeholder='Enter Something....'
              theme='snow'
              ref={quillRef}
              onChange={onChange}
            />
            {socialTools.active === true && modal(socialTools)}
          </div>
        </div>
      </QuillWrapper>
      <UploadWrapper>
        <Card title='封面照片上傳' bordered={false}>
          {coverImagePreviewUrl && (
            <img
              src={coverImagePreviewUrl}
              alt='Cover Preview'
              style={{
                width: '100%',
                maxWidth: '300px', // 设置最大宽度
                height: 'auto',
                marginBottom: '10px',
                objectFit: 'cover' // 确保图片比例正确
              }}
            />
          )}
          <Upload {...coverUploadProps}>
            <Button icon={<UploadOutlined />}>選擇封面照片</Button>
          </Upload>
        </Card>
      </UploadWrapper>
      <Modal
        title='圖片上傳'
        open={isImageModalOpen}
        onOk={handleImageUploadSuccess}
        onCancel={() => setIsImageModalOpen(false)}
      >
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>選擇圖片</Button>
        </Upload>
        {quillImagePreview && (
          <img
            src={quillImagePreview}
            alt='預覽'
            style={{ width: '100%', marginTop: '10px' }}
          />
        )}
        {file && <p>已選擇文件：{file.name}</p>}
      </Modal>
      <Modal
        title='封面圖片上傳'
        open={isCoverImageModalOpen}
        onOk={handleCoverImageUploadSuccess}
        onCancel={() => setIsCoverImageModalOpen(false)}
      >
        <Upload {...coverUploadProps}>
          <Button icon={<UploadOutlined />}>選擇封面圖片</Button>
        </Upload>
        {coverImagePreviewLocal && (
          <img
            src={coverImagePreviewLocal}
            alt='預覽'
            style={{ width: '100%', marginTop: '10px' }}
          />
        )}
        {coverImageFile && <p>已選擇文件：{coverImageFile.name}</p>}
      </Modal>
    </EditorContainer>
  )
}

export default CustomQuillEditor
