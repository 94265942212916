import React, { useState } from 'react'
import { Form, Input, Button, message, Card } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import api from '../utils/api'

const Container = styled.div`
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
`

const StyledCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 24px;
  color: #333;
`

const SubmitButton = styled(Button)`
  width: 100%;
`

const ChangeEmailPasswordPage = () => {
  const [form] = Form.useForm()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')
  const { type } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (values) => {
    setIsLoading(true)
    const payload =
      type === 'email'
        ? { newEmail: values.newEmail }
        : { newPassword: values.newPassword }
    api
      .post(`/api/auth/verify-and-change?token=${token}`, payload)
      .then(() => {
        message.success(`${type === 'email' ? '郵箱' : '密碼'}更改成功`)
        navigate('/account')
      })
      .catch((error) => {
        console.error(`Error changing ${type}:`, error)
        message.error(
          error.response?.data?.error ||
            `更改${type === 'email' ? '郵箱' : '密碼'}失敗`
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Container>
      <StyledCard>
        <Title>{type === 'email' ? '更改郵箱' : '更改密碼'}</Title>
        <Form form={form} onFinish={onFinish} layout='vertical'>
          {type === 'email' ? (
            <Form.Item
              name='newEmail'
              rules={[
                { required: true, message: '請輸入新的郵箱地址' },
                { type: 'email', message: '請輸入有效的郵箱地址' }
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder='新郵箱地址' />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name='newPassword'
                rules={[
                  { required: true, message: '請輸入新密碼' },
                  { min: 8, message: '密碼長度至少為8個字符' }
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder='新密碼'
                />
              </Form.Item>
              <Form.Item
                name='confirmPassword'
                dependencies={['newPassword']}
                rules={[
                  { required: true, message: '請確認新密碼' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('兩次輸入的密碼不一致'))
                    }
                  })
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder='確認新密碼'
                />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <SubmitButton type='primary' htmlType='submit' loading={isLoading}>
              確認更改
            </SubmitButton>
          </Form.Item>
        </Form>
      </StyledCard>
    </Container>
  )
}

export default ChangeEmailPasswordPage
