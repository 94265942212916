import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
// 删除未使用的导入
// import axios from 'axios'
// import { API_BASE_URL } from '../utils/apiutils'
import { CalendarOutlined, BellOutlined } from '@ant-design/icons'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import { Empty } from 'antd'

const NotificationContainer = styled.div`
  max-width: 800px;
  height: 100%;
  margin: 0 auto 50px auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`

const NotificationList = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const NotificationItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #4285f4;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 6px;
`

const Content = styled.div`
  flex-grow: 1;
`

const NotificationTitle = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`

const Timestamp = styled.div`
  font-size: 14px;
  color: #666;
`

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`

const FilterButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`

const FilterButton = styled.button`
  padding: 5px 20px;
  margin: 0 10px 0 0;
  background-color: ${(props) => (props.active ? '#4285f4' : '#f0f0f0')};
  color: ${(props) => (props.active ? 'white' : 'black')};
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#3367d6' : '#e0e0e0')};
  }
`
const MarkAllAsReadButton = styled.button`
  padding: 5px 20px;
  margin: 0 10px 0 0;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`

const IconWrapper = styled.div`
  margin-right: 12px;
  font-size: 20px;
  color: #4285f4;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const AllNotificationsPage = () => {
  const [notifications, setNotifications] = useState([])
  const [filter, setFilter] = useState('all') // 'all' 或 'unread'
  const [totalCount, setTotalCount] = useState(0)
  const { user } = useAuth()

  const fetchAllNotifications = useCallback(async () => {
    try {
      if (!user) {
        console.error('用戶ID或令牌缺失')
        return
      }
      const response = await api.get(`/api/notifications/all/${user.userId}`)

      const { notifications, totalCount } = response.data
      setNotifications(notifications)
      setTotalCount(totalCount)
    } catch (error) {
      console.error('獲取通知失敗:', error)
      setNotifications([])
      setTotalCount(0)
    }
  }, [user])

  useEffect(() => {
    if (user) {
      fetchAllNotifications()
    }
  }, [user, fetchAllNotifications])

  const filteredNotifications =
    filter === 'all'
      ? notifications
      : notifications.filter((notification) => !notification.isRead)

  const getIcon = (type) => {
    switch (type) {
      case 'booking_update':
        return <CalendarOutlined />
      // 可以在這裡添加更多類型的圖標
      default:
        return <BellOutlined />
    }
  }

  const handleMarkAllAsRead = async () => {
    try {
      if (!user) {
        console.error('用戶ID或令牌缺失')
        return
      }
      await api.post(`/api/notifications/mark-all-read/${user.userId}`)
      await fetchAllNotifications()
    } catch (error) {
      console.error('標記所有通知為已讀失敗:', error)
    }
  }

  return (
    <NotificationContainer>
      <HeaderContainer>
        <Title>所有通知 ({totalCount})</Title>
      </HeaderContainer>
      <FilterButtons>
        <FilterButton
          active={filter === 'all'}
          onClick={() => setFilter('all')}
        >
          全部
        </FilterButton>
        <FilterButton
          active={filter === 'unread'}
          onClick={() => setFilter('unread')}
        >
          未讀
        </FilterButton>
        <MarkAllAsReadButton onClick={handleMarkAllAsRead}>
          全部已讀
        </MarkAllAsReadButton>
      </FilterButtons>
      <NotificationList>
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <NotificationItem key={notification._id}>
              <IconWrapper>{getIcon(notification.type)}</IconWrapper>
              <Dot
                style={{
                  visibility: notification.isRead ? 'hidden' : 'visible'
                }}
              />
              <Content>
                <NotificationTitle>
                  {notification?.message || '未知通知'}
                </NotificationTitle>
                <Timestamp>{notification?.createdAt || '未知時間'}</Timestamp>
              </Content>
            </NotificationItem>
          ))
        ) : (
          <NotificationItem>
            <Content>
              <NotificationTitle>
                {filter === 'all' ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ marginTop: '50px' }}
                    description='暫無通知'
                  />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ marginTop: '50px' }}
                    description='暫無未讀通知'
                  />
                )}
              </NotificationTitle>
            </Content>
          </NotificationItem>
        )}
      </NotificationList>
    </NotificationContainer>
  )
}

export default AllNotificationsPage
