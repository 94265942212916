import React, { useState } from 'react'
import { Input, Select, Slider, Rate, Avatar, Tag, Row, Col } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { categories, tags } from '../utils/utils'

const { Option } = Select

// 新增 styled-components
const SearchContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`

const SearchHeader = styled.div`
  margin-bottom: 20px;
`

const CategoryFilter = styled.div`
  margin-bottom: 20px;
`

const AdvancedFilters = styled.div`
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`

const ResultsList = styled.div`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

const StyledCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    height: 120px;
  }
`

const CardContent = styled.div`
  padding: 15px;

  @media (max-width: 768px) {
    padding: 10px;
    flex: 1;
  }
`

const CardAvatar = styled(Avatar)`
  display: block;
  margin: 0 auto 15px;

  @media (max-width: 768px) {
    margin: 0 15px 0 0;
  }
`

const CardTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`

const CardDescription = styled.p`
  margin: 0 0 10px;
  font-size: 14px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`

const TagContainer = styled.div`
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 5px;
  }
`

const StyledTag = styled(Tag)`
  margin-bottom: 5px;
`

const SearchByType = () => {
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [priceRange, setPriceRange] = useState([0, 5000])
  const [rating, setRating] = useState(0)

  // 假数据
  const dummyData = [
    {
      id: 1,
      name: '小美美甲店',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
      rating: 4.5,
      intro: '专业美甲20年,为您打造完美指尖',
      tags: ['热门项目', '新人优惠'],
      category: 'nail'
    },
    {
      id: 2,
      name: '发型师大卫',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      rating: 4.8,
      intro: '国际顶级发型师,擅长各种潮流发型',
      tags: ['独家推荐', 'APP活动'],
      category: 'hair_care'
    }
    // ... 更多假数据
  ]

  return (
    <SearchContainer>
      <SearchHeader>
        <Input
          size='large'
          placeholder='搜索店家或设计师'
          prefix={<SearchOutlined />}
        />
      </SearchHeader>

      <CategoryFilter>
        <Select
          style={{ width: '100%' }}
          value={selectedCategory}
          onChange={setSelectedCategory}
        >
          {categories.map((cat) => (
            <Option key={cat.type} value={cat.type}>
              {cat.name}
            </Option>
          ))}
        </Select>
      </CategoryFilter>

      <AdvancedFilters>
        <Row gutter={16}>
          <Col span={12}>
            <p>价格范围</p>
            <Slider
              range
              min={0}
              max={5000}
              value={priceRange}
              onChange={setPriceRange}
            />
          </Col>
          <Col span={12}>
            <p>最低评分</p>
            <Rate allowHalf value={rating} onChange={setRating} />
          </Col>
        </Row>
      </AdvancedFilters>

      <ResultsList>
        <Row gutter={[16, 16]}>
          {dummyData
            .filter(
              (item) =>
                (selectedCategory === 'all' ||
                  item.category === selectedCategory) &&
                item.rating >= rating
            )
            .map((item) => (
              <Col xs={24} sm={12} md={8} lg={6} key={item.id}>
                <StyledCard>
                  <CardAvatar size={64} src={item.avatar} />
                  <CardContent>
                    <CardTitle>{item.name}</CardTitle>
                    <CardDescription>{item.intro}</CardDescription>
                    <Rate disabled defaultValue={item.rating} />
                    <TagContainer>
                      {item.tags.map((tag) => (
                        <StyledTag
                          color={tags.find((t) => t.label === tag)?.color}
                          key={tag}
                        >
                          {tag}
                        </StyledTag>
                      ))}
                    </TagContainer>
                  </CardContent>
                </StyledCard>
              </Col>
            ))}
        </Row>
      </ResultsList>
    </SearchContainer>
  )
}

export default SearchByType
