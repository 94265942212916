import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Button,
  DatePicker,
  Radio,
  message,
  Card,
  Upload,
  Row,
  Col,
  Divider
} from 'antd'
import {
  LoadingOutlined,
  PlusOutlined,
  LockOutlined,
  MailOutlined
} from '@ant-design/icons'
import styled from 'styled-components'
import dayjs from 'dayjs'
import api from '../utils/api'
import { PHOTO_URL } from '../utils/apiutils'
import ImgCrop from 'antd-img-crop'

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Content = styled.div`
  text-align: left;
`

const FormItem = styled(Form.Item)`
  label {
    font-weight: bold;
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  background-color: #d5728a;
  border-color: #d5728a;
  &:hover {
    background-color: #bf5170;
    border-color: #bf5170;
  }
`
const TitleContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  cursor: pointer;
`

const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`
const Title = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`

const SecuritySection = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
`

const SecurityButton = styled(Button)`
  margin-right: 10px;
`

const EditAccountPage = () => {
  const [form] = Form.useForm()
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loginType, setLoginType] = useState(null)
  // 刪除以下行
  // const [imageUrl, setImageUrl] = useState(null)
  const [fileList, setFileList] = useState([])
  const [avatarPath, setAvatarPath] = useState(null)

  useEffect(() => {
    api
      .get('/api/user/me')
      .then((response) => {
        const user = response.data.data
        setLoginType(user.loginType)
        if (user.avatar) {
          setAvatarPath(user.avatar)
          // 修改這部分邏輯
          const avatarUrl =
            user.loginType === 'line' || user.loginType === 'google'
              ? user.avatar
              : `${PHOTO_URL}${user.avatar}`

          setFileList([
            {
              uid: '-1',
              url: avatarUrl,
              name: 'avatar',
              status: 'done'
            }
          ])
        }
        form.setFieldsValue({
          email: user.email,
          username: user.username,
          name: user.name,
          birthdate: user.birthdate ? dayjs(user.birthdate) : null,
          gender: user.gender,
          phone: user.phone
        })
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
        message.error('無法加載用戶信息')
      })
  }, [form])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('只能上傳 JPG/PNG 文件！')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('圖片大小應小於 2MB！')
    }
    return isJpgOrPng && isLt2M
  }

  const handleFileChange = async ({ file }) => {
    const { originFileObj } = file

    if (originFileObj) {
      const formData = new FormData()
      formData.append('avatar', originFileObj)

      try {
        const response = await api.post('/api/user/upload-avatar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        const uploadedUrl = response.data.url
        // 刪除以下行
        // setImageUrl(`${PHOTO_URL}${uploadedUrl}`)
        setAvatarPath(uploadedUrl)
        setFileList([
          {
            uid: '-1',
            url: `${PHOTO_URL}${uploadedUrl}`,
            name: 'avatar',
            status: 'done'
          }
        ])
        message.success('頭像上傳成功')
      } catch (error) {
        console.error('Error uploading avatar:', error)
        message.error('頭像上傳失敗')
      }
    } else {
      console.error('No originFileObj found in file upload.')
    }
  }

  const onFinish = async (values) => {
    try {
      const data = {
        ...values,
        avatar: avatarPath // 使用相對路徑
      }

      await api.put('/api/user/me', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      message.success('帳戶信息已成功更新！')
      setIsEditing(false)
    } catch (error) {
      console.error('Error updating user data:', error)
      message.error('更新帳戶信息失敗')
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上傳頭像</div>
    </div>
  )

  const requestEmailChange = () => {
    api
      .post('/api/auth/request-change-email')
      .then(() => {
        message.success('驗證郵件已發送，請查看您的郵箱')
      })
      .catch((error) => {
        console.error('Error requesting email change:', error)
        message.error(error.response?.data?.error || '請求更改郵箱失敗')
      })
  }

  const requestPasswordChange = () => {
    api
      .post('/api/auth/request-change-password')
      .then(() => {
        message.success('驗證郵件已發送，請查看您的郵箱')
      })
      .catch((error) => {
        console.error('Error requesting password change:', error)
        message.error(error.response?.data?.error || '請求更改密碼失敗')
      })
  }

  // 添加編輯模式切換函數
  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }

  return (
    <Container>
      <TitleContainer>
        <TitleBar />
        <Title>修改帳戶資訊</Title>
      </TitleContainer>
      <Card
        extra={
          <Button onClick={toggleEditing}>
            {isEditing ? '取消編輯' : '編輯資料'}
          </Button>
        }
      >
        <Content>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
              email: '',
              username: '',
              name: '',
              birthdate: null,
              gender: '',
              phone: ''
            }}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={16}>
                <FormItem
                  label='姓名'
                  name='name'
                  rules={[{ required: true, message: '請輸入您的姓名' }]}
                >
                  <Input placeholder='輸入您的姓名' disabled={!isEditing} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label='頭像' name='avatar'>
                  {loginType === 'line' ? (
                    <img
                      src={avatarPath}
                      alt='用戶頭像'
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%'
                      }}
                    />
                  ) : (
                    <ImgCrop rotate aspect={1}>
                      <Upload
                        listType='picture-circle'
                        fileList={fileList}
                        beforeUpload={beforeUpload}
                        onChange={handleFileChange}
                        disabled={!isEditing}
                        multiple={false}
                      >
                        {fileList.length === 0 && uploadButton}
                      </Upload>
                    </ImgCrop>
                  )}
                </FormItem>
              </Col>
            </Row>
            <FormItem
              label='Email'
              name='email'
              rules={[
                { required: true, message: '請輸入您的電子郵件地址' },
                {
                  type: 'email',
                  message: '請輸入正確Email格式'
                }
              ]}
            >
              <Input
                type='email'
                placeholder='輸入您的電子郵件地址'
                disabled={true}
              />
            </FormItem>
            <FormItem
              label='帳號 (登入帳號)'
              name='username'
              rules={[{ required: true, message: '請輸入您的登入帳號' }]}
            >
              <Input placeholder='輸入您的登入帳號' disabled={!isEditing} />
            </FormItem>
            <FormItem
              label='出生日期'
              name='birthdate'
              rules={[{ required: true, message: '請選擇您的出生日期' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder='選擇您的出生日期'
                format='YYYY-MM-DD'
                disabledDate={(current) =>
                  current && current > dayjs().endOf('day')
                }
                disabled={!isEditing}
              />
            </FormItem>

            <FormItem
              label='性別'
              name='gender'
              rules={[{ required: true, message: '請選擇您的性別' }]}
            >
              <Radio.Group disabled={!isEditing}>
                <Radio value='female'>女性</Radio>
                <Radio value='male'>男性</Radio>
              </Radio.Group>
            </FormItem>

            <FormItem
              label='手機號碼'
              name='phone'
              rules={[{ required: true, message: '請輸入您的手機號碼' }]}
            >
              <Input placeholder='輸入您的手機號碼' disabled={!isEditing} />
            </FormItem>

            <Divider />

            <SecuritySection>
              <h3>安全設置</h3>
              <p>更改郵箱需要額外的驗證步驟</p>
              <SecurityButton
                icon={<MailOutlined />}
                onClick={requestEmailChange}
              >
                更改郵箱
              </SecurityButton>
              {loginType !== 'line' && (
                <SecurityButton
                  icon={<LockOutlined />}
                  onClick={requestPasswordChange}
                >
                  更改密碼
                </SecurityButton>
              )}
            </SecuritySection>

            {isEditing && (
              <FormItem>
                <SubmitButton type='primary' htmlType='submit'>
                  更新資訊
                </SubmitButton>
              </FormItem>
            )}
          </Form>
        </Content>
      </Card>
    </Container>
  )
}

export default EditAccountPage
