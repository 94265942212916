import React, { useEffect, useRef, useState } from 'react'
import L from 'leaflet'
import styled from 'styled-components'
import 'leaflet/dist/leaflet.css'
import { useNavigate } from 'react-router-dom' // 添加這行

const MapContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`

const MapElement = styled.div`
  width: 100%;
  height: 100%;
`

const ControlPanel = styled.div`
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
`

const Button = styled.button`
  background-color: #d5728a;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c4617a;
  }
`

const SalonList = styled.div`
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  display: ${(props) => (props.show ? 'block' : 'none')};
`

const SalonItem = styled.div`
  padding: 8px;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`

const NearbyMap = ({ salons, userLocation }) => {
  const mapRef = useRef(null)
  const mapInstanceRef = useRef(null)
  const [showList, setShowList] = useState(false)
  const navigate = useNavigate() // 添加這行

  useEffect(() => {
    if (!mapInstanceRef.current) {
      const initialLocation = userLocation || [25.033, 121.5654]
      mapInstanceRef.current = L.map(mapRef.current).setView(
        initialLocation,
        12
      )

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© BeauteFeel'
      }).addTo(mapInstanceRef.current)
    }

    const customIcon = L.icon({
      iconUrl: '/assets/love.png',
      iconSize: [25, 25],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34]
    })

    if (userLocation) {
      L.marker([userLocation.lat, userLocation.lng], {
        icon: L.divIcon({
          className: 'user-location-marker',
          html: '<div style="background-color: #4285F4; width: 16px; height: 16px; border-radius: 50%; border: 3px solid white;"></div>',
          iconSize: [22, 22],
          iconAnchor: [11, 11]
        })
      })
        .addTo(mapInstanceRef.current)
        .bindPopup('您的位置')
        .openPopup()

      mapInstanceRef.current.setView([userLocation.lat, userLocation.lng], 12)
    }

    if (Array.isArray(salons) && salons.length > 0) {
      salons.forEach((salon) => {
        const popupContent = `
          <div>
            <strong>${salon.name}</strong><br>
            <a href="#" class="salon-link" data-username="${salon.username}">查看詳情</a>
          </div>
        `
        L.marker([salon.lat, salon.lng], { icon: customIcon })
          .addTo(mapInstanceRef.current)
          .bindPopup(popupContent)

        // 添加點擊事件監聽器
        mapInstanceRef.current.on('popupopen', (e) => {
          const link = e.popup._contentNode.querySelector('.salon-link')
          if (link) {
            link.addEventListener('click', (event) => {
              event.preventDefault()
              const username = event.target.getAttribute('data-username')
              navigate(`/store/${username}`)
            })
          }
        })
      })
    }

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove()
        mapInstanceRef.current = null
      }
    }
  }, [salons, userLocation, navigate])

  const handleCenterMap = () => {
    if (userLocation && mapInstanceRef.current) {
      mapInstanceRef.current.setView([userLocation.lat, userLocation.lng], 12)
    }
  }

  const handleToggleList = () => {
    setShowList(!showList)
  }

  const handleSalonClick = (salon) => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.setView([salon.lat, salon.lng], 15)
    }
  }

  return (
    <MapContainer>
      <MapElement ref={mapRef} />
      <ControlPanel>
        <Button onClick={handleCenterMap}>回到我的位置</Button>
        <Button onClick={handleToggleList}>
          {showList ? '隱藏列表' : '顯示沙龍列表'}
        </Button>
      </ControlPanel>
      <SalonList show={showList}>
        {salons.map((salon) => (
          <SalonItem key={salon.id} onClick={() => handleSalonClick(salon)}>
            {salon.name}
          </SalonItem>
        ))}
      </SalonList>
    </MapContainer>
  )
}

export default NearbyMap
