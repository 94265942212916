import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { FaStar, FaPencilAlt } from 'react-icons/fa'
import { message } from 'antd'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import Loading from '../utils/Loading'

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const Title = styled.h2`
  color: #d5728a;
  margin-bottom: 10px;
`

const AppointmentInfo = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
`

const InfoItem = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #333;
`

const RatingSection = styled.div`
  margin-bottom: 20px;
`

const RatingItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const RatingLabel = styled.span`
  width: 80px;
  font-size: 16px;
  color: #333;
`

const StarContainer = styled.div`
  display: flex;
  align-items: center;
`

const Star = styled(FaStar)`
  color: ${(props) => (props.active ? '#f1c40f' : '#ddd')};
  font-size: 24px;
  margin-right: 5px;
  cursor: pointer;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-size: 16px;
  margin-bottom: 20px;

  @media (max-width: 820px) {
    width: 95%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 820px) {
    width: 100%;
  }
`

const SubmitButton = styled.button`
  background-color: #d5728a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c76279;
  }
`

const WriteReview = () => {
  const { appointmentId } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()
  const [appointment, setAppointment] = useState(null)
  const [ratings, setRatings] = useState({
    environment: 0,
    service: 0,
    price: 0,
    rating: 0
  })
  const [comment, setComment] = useState('')

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const response = await api.get(`/api/client/bookings/${appointmentId}`)
        setAppointment(response.data)
      } catch (error) {
        message.error('獲取預約信息失敗')
        navigate('/user-appointment-list')
      }
    }

    fetchAppointment()
  }, [appointmentId, navigate])

  const handleRating = (category, value) => {
    setRatings({ ...ratings, [category]: value })
  }

  const handleSubmit = async () => {
    if (Object.values(ratings).some((rating) => rating === 0)) {
      message.error('請為所有項目評分')
      return
    }

    if (!comment.trim()) {
      message.error('請填寫評論內容')
      return
    }

    try {
      await api.post(`/api/user/write-review`, {
        bookingId: appointmentId,
        comment,
        rating: ratings.rating,
        environment: ratings.environment,
        service: ratings.service,
        price: ratings.price
      })
      message.success('評論提交成功')
      navigate(`/user-appointment-list/${user._id}`)
    } catch (error) {
      message.error('評論提交失敗，請稍後再試')
    }
  }

  if (!appointment) {
    return <Loading />
  }

  return (
    <Container>
      <Header>
        <Title>
          <FaPencilAlt /> 寫下您對這次服務的評論
        </Title>
      </Header>
      <AppointmentInfo>
        <InfoItem>
          <strong>店名：</strong>
          {appointment.businessName}
        </InfoItem>
        <InfoItem>
          <strong>服務人員：</strong>
          {appointment.stylistName}
        </InfoItem>
        <InfoItem>
          <strong>日期：</strong>
          {appointment.date}
        </InfoItem>
        <InfoItem>
          <strong>服務：</strong>
          {appointment.productName}
        </InfoItem>
      </AppointmentInfo>
      <RatingSection>
        {Object.entries({
          environment: '環境',
          service: '服務',
          price: '價位',
          rating: '整體'
        }).map(([key, label]) => (
          <RatingItem key={key}>
            <RatingLabel>{label}：</RatingLabel>
            <StarContainer>
              {[1, 2, 3, 4, 5].map((value) => (
                <Star
                  key={value}
                  active={value <= ratings[key]}
                  onClick={() => handleRating(key, value)}
                />
              ))}
            </StarContainer>
          </RatingItem>
        ))}
      </RatingSection>
      <TextArea
        placeholder='請寫下您的評論...'
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <ButtonContainer>
        <SubmitButton onClick={handleSubmit}>提交評論</SubmitButton>
      </ButtonContainer>
    </Container>
  )
}

export default WriteReview
