import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Select, message, DatePicker, Input, Empty, Flex } from 'antd'
import {
  CalendarPicker,
  SearchBar,
  CascadePicker,
  CapsuleTabs
} from 'antd-mobile'
import moment from 'moment'
import axios from 'axios'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { categories } from '../utils/utils'
import {
  areaOptions,
  cityOptions,
  cityMapping,
  areaMapping,
  timeOptions
} from '../utils/utils'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import Loading from '../utils/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const { Option } = Select
const now = new Date()

const Container = styled.div`
  padding: 40px 20px 20px 20px;
  @media (max-width: 820px) {
    padding: 0px 10px;
  }
`
const ResultsTitle = styled.div`
  display: flex;
  padding: 0 40px;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  text-align: left;
  letter-spacing: 2px;
  color: #d5728a;
  p {
    font-size: 13px;
    font-weight: normal;
    color: #000;
  }
`

const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  padding: 20px;

  @media (max-width: 820px) {
    flex-direction: column;
    padding: 15px;
  }
`

const FilterSelect = styled(Select)`
  width: 200px;
  margin: 0 10px;
  border-color: ${(props) => (props.error ? 'red' : 'initial')};

  @media (max-width: 820px) {
    display: none;
  }
`
const FilterShowSelect = styled(Select)`
  width: 200px;
  margin: 0 10px;

  @media (max-width: 820px) {
    width: 100%;
    margin-bottom: 10px;
  }
`
const FilterDate = styled.div`
  width: 170px;
  margin: 0 10px;

  @media (max-width: 820px) {
    display: none;
    margin-bottom: 10px;
  }
`
const BusinessList = styled.div`
  margin-top: 20px;
`

const BusinessCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;

  @media (max-width: 800px) {
    padding: 0 35px;
  }
`

const BusinessHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 820px) {
    flex-direction: column;
    text-align: center;
  }
`

const BusinessLogo = styled.div`
  width: 300px;
  height: 200px;
  margin: 0 50px 0 20px;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 820px) {
    width: 100%;
    height: auto;
    margin: 15px 30px;
  }
`

const BusinessImage = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  overflow: scroll;
  @media (max-width: 800px) {
    width: 200px;
    height: 150px;
    margin: 10px;
  }
`

const BusinessInfo = styled.div`
  width: 100%;
  text-align: left;

  @media (max-width: 820px) {
    text-align: center;
  }
`
const InfoHeader = styled.div`
  cursor: pointer;
`

const BusinessName = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const BusinessRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  @media (max-width: 820px) {
    justify-content: center;
  }
`

const Rating = styled.div`
  font-size: 14px;
  color: #f0c14b;
  margin-right: 10px;
`

const ReviewCount = styled.div`
  font-size: 14px;
  color: #888;
`

const BusinessAddress = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`

const ProductList = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;

  @media (max-width: 820px) {
    padding: 15px;
  }
`

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const ProductPrice = styled.div`
  color: #d5728a;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
`

const ProductName = styled.div`
  font-size: 14px;
  color: #333;
`
const RenderText = styled.div`
  margin: 0 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #333;
`

const MoreOffers = styled.div`
  font-size: 12px;
  color: #d5728a;
  padding-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`

const SliderContainer = styled.div`
  .slick-slide > div {
    display: flex;
    justify-content: center;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #d5728a;
  }
`
const MobileDate = styled.div`
  display: none;
  @media (max-width: 820px) {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
`
const CapsuleTabsContainer = styled.div`
  width: 100%;
  margin: 10px 0 20px 0;
  @media (min-width: 769px) {
    display: none;
  }
`
// 關鍵字輸入框樣式
const KeywordSearchInput = styled(Input)`
  width: 50%;
  padding: 10px;
  margin: 10px auto;
  border-radius: 20px;
  border: 1px solid #ccc;

  @media (max-width: 820px) {
    display: none;
  }
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const TitleLine = styled.div`
  flex-grow: 1;
  height: 4px;
  background-color: #f6f6f6;
`

const Title = styled.h1`
  font-size: 23px;
  font-weight: 600;
  color: #333;
  margin: 0 20px;
  white-space: nowrap;
`
const SearchButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SearchButton = styled(Button)`
  width: 200px;
  padding: 20px 0;
  font-size: 18px;
  border-radius: 20px;
  @media (min-width: 820px) {
    margin-top: 20px;
  }
`

const SearchPage = () => {
  const [city, setCity] = useState('')
  const [area, setArea] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [category, setCategory] = useState('')
  const [results, setResults] = useState([]) // 用于存储搜索结果
  const [hasSearched, setHasSearched] = useState(false) // 新增的 hasSearched 状态
  const [expandedBusinesses, setExpandedBusinesses] = useState({})
  const [visible, setVisible] = useState(false)
  const [areaVisible, setAreaVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [keyword, setKeyword] = useState('') // 关键字状态
  const navigate = useNavigate()
  const {
    city: paramCity,
    area: paramArea,
    category: paramCategory,
    date: paramDate,
    timeRange: paramTimeRange
  } = useParams()

  useEffect(() => {
    if (
      paramCity &&
      paramArea &&
      paramCategory &&
      paramDate &&
      paramTimeRange
    ) {
      const cityChinese = Object.keys(cityMapping).find(
        (key) => cityMapping[key] === paramCity
      )
      const areaChinese = Object.keys(areaMapping[paramCity]).find(
        (key) => areaMapping[paramCity][key] === paramArea
      )
      const [startTime, endTime] = paramTimeRange.split('-')

      setCity(cityChinese)
      setArea(areaChinese)
      setCategory(paramCategory)
      setSelectedDate(moment(paramDate, 'YYYY-MM-DD'))
      setStartTime(startTime)
      setEndTime(endTime)

      onSearch(
        cityChinese,
        areaChinese,
        paramDate,
        startTime,
        endTime,
        paramCategory
      )
    }
  }, [paramCity, paramArea, paramCategory, paramDate, paramTimeRange])

  const disabledDate = (current) => {
    return current && current < moment().startOf('day') // 只禁用今天之前的日期
  }

  const filterTimeOptions = (time) => {
    if (!selectedDate) {
      return false // 如果没有选择日期，则不禁用任何时间
    }

    // 确保selectedDate在正确的时区内被解析
    const currentDateMoment = moment().startOf('day') // 当前日期的起点
    const isToday = selectedDate.isSame(currentDateMoment, 'day')

    if (isToday) {
      const isBeforeNow = moment(time, 'HH:mm').isBefore(moment())
      return isBeforeNow // 只在选中日期为今天时禁用早于当前时间的选项
    }

    return false // 如果不是今天，则不禁用任何时间
  }

  const onSearch = async (
    cityParam,
    areaParam,
    dateParam,
    timeStartParam,
    timeEndParam,
    categoryParam
  ) => {
    const citySearch = cityParam || city
    const areaSearch = areaParam || area || 'all' // 默认设置为 'all'
    const dateSearch =
      dateParam ||
      (selectedDate
        ? selectedDate.format('YYYY-MM-DD')
        : moment(now).format('YYYY-MM-DD'))

    let timeStartSearch, timeEndSearch

    if (dateSearch === moment(now).format('YYYY-MM-DD')) {
      timeStartSearch = timeStartParam || startTime || moment().format('HH:mm')
      timeEndSearch = timeEndParam || endTime || '23:59'
    } else {
      timeStartSearch = timeStartParam || startTime || '00:00'
      timeEndSearch = timeEndParam || endTime || '23:59'
    }

    const categorySearch = categoryParam || category || 'all' // 默认设置为 'all'

    // if (!citySearch) {
    //   setError(true)
    //   Toast.show({
    //     content: '請選擇搜尋條件',
    //     position: 'top'
    //   })
    //   setLoading(false)
    //   return
    // }

    setError(false)
    setLoading(true) // 开始加载
    setHasSearched(true) // 标记用户已进行过搜索

    try {
      const response = await axios.get(`${API_BASE_URL}/api/search/available`, {
        params: {
          city: citySearch,
          area: areaSearch,
          date: dateSearch,
          timeStart: timeStartSearch,
          timeEnd: timeEndSearch,
          serviceType: categorySearch,
          keyword // 添加关键词搜索参数
        }
      })

      setResults(response.data)

      const newPath = `/search/${cityMapping[citySearch]}/${
        areaSearch !== 'all'
          ? areaMapping[cityMapping[citySearch]][areaSearch]
          : 'all'
      }/${
        categorySearch !== 'all' ? categorySearch : 'all'
      }/${dateSearch}/${timeStartSearch}-${timeEndSearch}`

      navigate(newPath)
    } catch (error) {
      console.error('搜索出錯:', error)
      message.error('搜索失敗，請稍後再試')
    } finally {
      setLoading(false) // 停止加载
    }
  }

  const handleSearchClick = () => {
    onSearch()
  }

  const areaOptionsArray = Object.keys(areaOptions).map((city) => ({
    label: city,
    value: city,
    children: areaOptions[city].map((area) => ({
      label: area,
      value: area
    }))
  }))

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }
    ]
  }

  const preventTouchMove = (e) => {
    e.preventDefault()
  }

  const handleSelectOpen = () => {
    document.body.style.overflow = 'hidden'
    document.body.addEventListener('touchmove', preventTouchMove, {
      passive: false
    })
  }

  const handleSelectClose = () => {
    document.body.style.overflow = 'auto'
    document.body.removeEventListener('touchmove', preventTouchMove)
  }

  const toggleExpand = (businessId) => {
    setExpandedBusinesses((prev) => ({
      ...prev,
      [businessId]: !prev[businessId]
    }))
  }

  const handleCityChange = (value) => {
    setCity(value)
    setArea('') // 重置區域選擇
  }

  const handleAreaChange = (value) => {
    setArea(value)
  }

  const handleCardClick = (businessId) => {
    navigate(`/store/${businessId}`)
  }

  const handleDateConfirm = (date) => {
    setSelectedDate(moment(date)) // 格式化为 YYYY-MM-DD
    setVisible(false) // 关闭日期选择器
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <TitleContainer>
        <TitleLine />
        <Title>24小時線上預約查詢</Title>
        <TitleLine />
      </TitleContainer>
      <Flex justify='center'>
        <KeywordSearchInput
          placeholder='搜索店家、地址或設計師'
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </Flex>

      <SearchBox>
        <MobileDate>
          <SearchBar
            placeholder='請輸入內容'
            showCancelButton
            style={{
              '--height': '42px',
              '--padding-left': '12px'
            }}
            value={keyword}
            onChange={(value) => setKeyword(value)}
          />
        </MobileDate>
        <MobileDate>
          <Button
            style={{ width: '48%', height: '35px', marginRight: '10px' }}
            onClick={() => setAreaVisible(true)}
          >
            {area ? `${city}${area}` : '按地區尋找'}
          </Button>
          <Button
            style={{ width: '48%', height: '35px' }}
            onClick={() => setVisible(true)}
          >
            {selectedDate
              ? moment(selectedDate).format('YYYY-MM-DD')
              : '按日期尋找'}
          </Button>
          <CascadePicker
            title='選擇地區'
            visible={areaVisible}
            options={areaOptionsArray} // 传入转换后的数组
            onClose={() => setAreaVisible(false)}
            onConfirm={(val) => {
              setCity(val[0])
              setArea(val[1])
              setAreaVisible(false)
            }}
          />
        </MobileDate>

        <FilterSelect
          placeholder='選擇縣市'
          size='large'
          value={city || undefined}
          onChange={handleCityChange}
          error={error ? true : undefined}
        >
          {cityOptions.map((city) => (
            <Option key={city.value} value={city.value}>
              {city.label}
            </Option>
          ))}
        </FilterSelect>
        <FilterSelect
          placeholder='選擇區域'
          size='large'
          value={area || undefined}
          onChange={handleAreaChange}
          disabled={!city}
        >
          {(areaOptions[city] || []).map((area) => (
            <Option key={area} value={area}>
              {area}
            </Option>
          ))}
        </FilterSelect>
        <CalendarPicker
          visible={visible}
          confirmText='確認選擇'
          title='選擇可預約日期'
          selectionMode='single'
          min={now}
          defaultValue={now}
          onClose={() => setVisible(false)}
          onConfirm={handleDateConfirm}
          onMaskClick={() => setVisible(false)}
        />
        <CapsuleTabsContainer>
          <RenderText>按分類查找</RenderText>
          <CapsuleTabs
            activeKey={category}
            onChange={(key) => setCategory(key)}
          >
            <CapsuleTabs.Tab title='全部' key='all' />
            <CapsuleTabs.Tab title='美甲' key='nail' />
            <CapsuleTabs.Tab title='美容美體' key='spa' />
            <CapsuleTabs.Tab title='美髮' key='hair_care' />
            <CapsuleTabs.Tab title='彩妝' key='makeup' />
            <CapsuleTabs.Tab title='牙齒美容' key='tooth' />
            <CapsuleTabs.Tab title='紋繡' key='eyebrown' />
            <CapsuleTabs.Tab title='除毛' key='shaving' />
          </CapsuleTabs>
        </CapsuleTabsContainer>
        <FilterDate>
          <DatePicker
            placeholder='選擇日期'
            size='large'
            disabledDate={disabledDate}
            onChange={(date) => setSelectedDate(date)}
          />
        </FilterDate>
        <FilterShowSelect
          placeholder='選擇開始時間'
          size='large'
          value={startTime || undefined}
          onChange={(value) => setStartTime(value)}
          onDropdownVisibleChange={(open) => {
            if (open) {
              handleSelectOpen()
            } else {
              handleSelectClose()
            }
          }}
        >
          <Option value=''>不指定時間</Option>
          {timeOptions.map((time) => (
            <Option key={time} value={time} disabled={filterTimeOptions(time)}>
              {time}
            </Option>
          ))}
        </FilterShowSelect>
        <FilterShowSelect
          placeholder='選擇結束時間'
          size='large'
          value={endTime || undefined}
          onChange={(value) => setEndTime(value)}
          disabled={!startTime}
          onDropdownVisibleChange={(open) => {
            if (open) {
              handleSelectOpen()
            } else {
              handleSelectClose()
            }
          }}
        >
          <Option value=''>不指定時間</Option>
          {timeOptions.map((time) => (
            <Option
              key={time}
              value={time}
              disabled={
                filterTimeOptions(time) ||
                (startTime &&
                  moment(time, 'HH:mm').isBefore(moment(startTime, 'HH:mm')))
              }
            >
              {time}
            </Option>
          ))}
        </FilterShowSelect>

        <FilterSelect
          placeholder='選擇分類'
          size='large'
          value={category || undefined}
          onChange={(value) => setCategory(value)}
        >
          <Option value='all'>全部</Option>
          <Option value='nail'>美甲</Option>
          <Option value='spa'>美容美體</Option>
          <Option value='hair_care'>美髮</Option>
          <Option value='makeup'>彩妝</Option>
          <Option value='tooth'>牙齒美容</Option>
          <Option value='eyebrown'>紋繡</Option>
          <Option value='shaving'>除毛</Option>
        </FilterSelect>
      </SearchBox>
      <SearchButtonContainer>
        <SearchButton type='primary' onClick={handleSearchClick}>
          搜索
        </SearchButton>
      </SearchButtonContainer>
      {/* 這裡展示搜索結果 */}
      <BusinessList>
        {results.length > 0 ? (
          <>
            <ResultsTitle>
              {results.length}{' '}
              <p>
                個有關{city}・{area}・
                {categories.find((cat) => cat.type === category)?.name ||
                  category}
                的結果
              </p>
            </ResultsTitle>
            {results.map((business, index) => (
              <BusinessCard key={index}>
                <BusinessHeader>
                  <BusinessLogo>
                    {business.photos && business.photos.length > 0 ? (
                      business.photos.length > 1 ? (
                        <SliderContainer>
                          <Slider {...sliderSettings}>
                            {business.photos.map((photo, idx) => (
                              <BusinessImage
                                key={idx}
                                src={
                                  photo && photo.url
                                    ? `${PHOTO_URL}${photo.url}`
                                    : '/assets/business_photo.png'
                                }
                                alt={`${business.name}-${idx}`}
                                onClick={() => handleCardClick(business._id)}
                              />
                            ))}
                          </Slider>
                        </SliderContainer>
                      ) : (
                        <BusinessImage
                          src={
                            business.photos[0] && business.photos[0].url
                              ? `${PHOTO_URL}${business.photos[0].url}`
                              : '/assets/business_photo.png'
                          }
                          alt={business.name}
                          onClick={() => handleCardClick(business._id)}
                        />
                      )
                    ) : (
                      <BusinessImage
                        src='/assets/business_photo.png'
                        alt={business.name}
                        onClick={() => handleCardClick(business._id)}
                      />
                    )}
                  </BusinessLogo>
                  <BusinessInfo>
                    <InfoHeader onClick={() => handleCardClick(business._id)}>
                      <BusinessName>{business.name}</BusinessName>
                      <BusinessRating>
                        <Rating>評分: {business.reviews[0]?.rating}</Rating>
                        <ReviewCount>
                          ({business.reviews.length} 評論)
                        </ReviewCount>
                      </BusinessRating>
                      <BusinessAddress>{business.address}</BusinessAddress>
                    </InfoHeader>
                    <ProductList>
                      {business.products
                        .slice(
                          0,
                          expandedBusinesses[business._id]
                            ? business.products.length
                            : 2
                        )
                        .map((product, index) => (
                          <ProductItem key={index}>
                            <ProductPrice>$ {product.price}</ProductPrice>
                            <ProductName>{product.name}</ProductName>
                          </ProductItem>
                        ))}
                      {business.products.length > 2 && (
                        <MoreOffers onClick={() => toggleExpand(business._id)}>
                          {expandedBusinesses[business._id] ? (
                            <>
                              收起優惠 <CaretUpOutlined />
                            </>
                          ) : (
                            <>
                              更多{business.products.length - 2}個優惠{' '}
                              <CaretDownOutlined />
                            </>
                          )}
                        </MoreOffers>
                      )}
                    </ProductList>
                  </BusinessInfo>
                </BusinessHeader>
              </BusinessCard>
            ))}
          </>
        ) : hasSearched ? ( // 仅当用户已进行搜索且结果为空时显示 Empty 组件
          <Empty
            image={`${PHOTO_URL}/public/assets/nodata.svg`}
            imageStyle={{
              height: 100
            }}
            description='没有符合条件的商家'
          />
        ) : null}
      </BusinessList>
    </Container>
  )
}

export default SearchPage
