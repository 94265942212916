import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { message } from 'antd'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { Toast } from 'antd-mobile'

const VerifyEmailPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [verificationStatus, setVerificationStatus] = useState('pending')

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search)
      const token = params.get('token')

      console.log('開始驗證郵箱，token:', token)

      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/auth/verify-email?token=${token}`
        )
        console.log('API 響應:', response.data)
        setVerificationStatus('success')
        console.log('設置狀態為 success')
        Toast.show({
          content: '驗證成功，請重新登錄',
          position: 'center',
          duration: 5000
        })
        setTimeout(() => {
          navigate('/login')
        }, 5000)
      } catch (error) {
        console.error('驗證失敗:', error)
        setVerificationStatus('failed')
        Toast.show({
          content: '驗證失敗，請重試或聯繫支援',
          position: 'center',
          duration: 5000
        })
      }
    }

    verifyEmail()
  }, [location, navigate])

  useEffect(() => {
    console.log('當前驗證狀態：', verificationStatus)
  }, [verificationStatus])

  return (
    <div style={{ padding: '50px 0', textAlign: 'center' }}>
      <h1>電子信箱驗證</h1>
      {verificationStatus === 'pending' && (
        <p>正在驗證您的電子信箱，請耐心等候</p>
      )}
      {verificationStatus === 'success' && (
        <div>
          <img
            src={`${PHOTO_URL}/public/assets/welldone.svg`}
            alt='驗證成功'
            style={{ width: '100px', height: '100px' }}
          />
          <p>驗證成功！即將跳轉到登錄頁面...</p>
        </div>
      )}
      {verificationStatus === 'failed' && (
        <div>
          <img
            src={`${PHOTO_URL}/public/assets/nodata.svg`}
            alt='驗證失敗'
            style={{ width: '100px', height: '100px' }}
          />
          <p>驗證失敗，請重試或聯繫支援</p>
        </div>
      )}
    </div>
  )
}

export default VerifyEmailPage
