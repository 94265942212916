// export const API_BASE_URL = 'http://localhost:8080'
// export const PHOTO_URL = 'http://localhost:8080'

// export const API_BASE_URL = 'https://beauty-server.onrender.com'

// export const API_BASE_URL = 'http://18.181.177.50:8080'

export const PHOTO_URL = 'https://f005.backblazeb2.com/file/beautyapp'
export const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://beautefeel.com'
    : 'http://localhost:8080'
