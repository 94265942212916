import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
  FaStar,
  FaUserAlt,
  FaCamera,
  FaInstagram,
  FaShoppingBag,
  FaTint,
  FaCheckCircle
} from 'react-icons/fa'
import { PHOTO_URL } from '../utils/apiutils'
import { GiHairStrands } from 'react-icons/gi'
import { Helmet } from 'react-helmet-async'

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  overflow: hidden;
`

const Header = styled(motion.header)`
  text-align: center;
  margin-bottom: 60px;
`

const Title = styled(motion.h1)`
  font-size: 35px;
  color: #333;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`

const Subtitle = styled(motion.p)`
  font-size: 1.4rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 30px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`

const Button = styled(motion.button)`
  background-color: #bf1c5f;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(255, 99, 71, 0.3);

  &:hover {
    background-color: #d5728a;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(255, 99, 71, 0.4);
  }
`

const ModelGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 2fr));
  }
`

const ModelCard = styled(motion.div)`
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`

const ModelImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  @media (max-width: 768px) {
    height: 200px;
  }
`

const ModelInfo = styled.div`
  padding: 0px 20px;
`

const ProgramSection = styled(motion.div)`
  display: flex;
  justify-content: space-around;
  margin-top: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`

const ProgramCard = styled(motion.div)`
  background-color: #f9ebed;
  padding: 30px;
  border-radius: 20px;
  width: 40%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  p {
    font-size: 1.2rem;
  }
  ul {
    text-align: left;
  }

  li {
    font-size: 1rem;
    text-align: left;
    padding-left: 30px;
  }

  @media (max-width: 768px) {
    width: 80vw;
  }
`
const GuideSection = styled(motion.div)`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`
const GuideCard = styled(motion.div)`
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;

  p {
    font-size: 1.2rem;
  }
  li {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

const IconWrapper = styled.span`
  margin-right: 10px;
  margin-left: 10px;
  color: #bf1c5f;
`

const Highlight = styled.span`
  background: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`

const ModelsPage = () => {
  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  }

  const cardVariants = {
    initial: { opacity: 0, scale: 0.9 },
    in: { opacity: 1, scale: 1 },
    hover: { scale: 1.05 }
  }

  const modelsData = [
    {
      id: '/public/assets/0.jpeg',
      name: '林美玲',
      age: 31,
      isKOL: false,
      skin: '敏感性',
      hair: '直髮'
    },
    {
      id: '/public/assets/1.jpg',
      name: '陳佳琪',
      age: 22,
      isKOL: false,
      skin: '乾性',
      hair: '捲髮'
    },
    {
      id: '/public/assets/2.jpeg',
      name: '王雅婷',
      age: 28,
      isKOL: true,
      followers: '100萬',
      skin: '油性',
      hair: '細軟髮'
    },
    {
      id: '/public/assets/3.jpeg',
      name: '張靜怡',
      age: 25,
      isKOL: true,
      followers: '50萬',
      skin: '混合性',
      hair: '直髮'
    }
  ]

  return (
    <PageContainer>
      <Helmet>
        <title>{`全台模特兒KOL網紅合作機會 & 線上預約推薦 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <Header
        initial='initial'
        animate='in'
        exit='exit'
        variants={pageVariants}
        transition={{ duration: 0.5 }}
      >
        <Title>
          <IconWrapper>
            <FaStar />
          </IconWrapper>
          探索KOL網紅與模特兒的<Highlight>合作機會</Highlight>
        </Title>
        <Subtitle>
          在這裡，有眾多<Highlight>明星KOL</Highlight>和
          <Highlight>素人模特兒</Highlight>，
          <br />
          無論您是需要作品集還是好口碑，
          <br />
          這裡都能助你一臂之力！
          <br />
          厭倦了傳統的模特兒招募方式？
          <br />
          各大平台發佈招募模特兒體驗，常常被放鳥？訊息對接效率低？
          <br />
        </Subtitle>
        <Button
          onClick={() => {
            window.location.href = 'https://joinus.beautefeel.com/'
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          開啟合作之旅
        </Button>
      </Header>

      <ModelGrid
        initial='initial'
        animate='in'
        variants={pageVariants}
        transition={{ staggerChildren: 0.1 }}
      >
        {modelsData.map((model) => (
          <ModelCard key={model.id} variants={cardVariants} whileHover='hover'>
            <ModelImage src={`${PHOTO_URL}${model.id}`} alt={model.name} />
            <ModelInfo>
              <h3>{model.name}</h3>
              <p>
                <IconWrapper>
                  <FaUserAlt />
                </IconWrapper>
                {model.age}歲{' '}
                {model.isKOL ? (
                  <>
                    <IconWrapper>
                      <FaInstagram />
                    </IconWrapper>
                    KOL
                  </>
                ) : (
                  <>
                    <IconWrapper>
                      <FaCamera />
                    </IconWrapper>
                    素人
                  </>
                )}
              </p>
              {model.isKOL && (
                <p>
                  <IconWrapper>
                    <FaStar />
                  </IconWrapper>
                  粉絲數: {model.followers}
                </p>
              )}
              <p>
                <IconWrapper>
                  <FaTint />
                </IconWrapper>
                {model.skin}
                <IconWrapper>
                  <GiHairStrands />
                </IconWrapper>
                {model.hair}
              </p>
            </ModelInfo>
          </ModelCard>
        ))}
      </ModelGrid>

      <ProgramSection
        initial='initial'
        animate='in'
        variants={pageVariants}
        transition={{ staggerChildren: 0.2 }}
      >
        <ProgramCard variants={cardVariants}>
          <h2>
            <IconWrapper>
              <FaShoppingBag />
            </IconWrapper>
            品牌合作計劃
          </h2>
          <p>加入我們的品牌合作計劃，開啟無限可能</p>
          <ul>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              與頂級KOL和模特兒直接對接
            </li>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              免費發佈合作需求
            </li>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              專業團隊全程支持
            </li>
          </ul>
          <Button
            onClick={() => {
              window.location.href = '/login'
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            立即加入
          </Button>
        </ProgramCard>
        <ProgramCard variants={cardVariants}>
          <h2>
            <IconWrapper>
              <FaCamera />
            </IconWrapper>
            如果你是模特兒
          </h2>
          <p>免費註冊，輕鬆查看招聘資訊</p>
          <ul>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              輕鬆瀏覽海量合作機會
            </li>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              一鍵預約，快速對接品牌
            </li>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              24小時在線查看
            </li>
          </ul>
          <Button
            onClick={() => {
              window.location.href = '/login'
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            立即註冊
          </Button>
        </ProgramCard>
      </ProgramSection>
      <GuideSection>
        <GuideCard variants={cardVariants}>
          <h2>
            <IconWrapper>
              <FaStar />
            </IconWrapper>
            專業指導，助您成功
          </h2>
          <p>不確定如何開始？別擔心！我們的專家團隊隨時為您提供支持：</p>
          <ul>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              一對一諮詢服務
            </li>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              行業趨勢分析
            </li>
            <li>
              <IconWrapper>
                <FaCheckCircle />
              </IconWrapper>
              合作策略制定
            </li>
          </ul>
          <p>立即加入我們的LINE@，開啟您的成功之旅！</p>
          <div style={{ marginTop: '20px' }}>
            <a
              href='https://lin.ee/8nb2EEq'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src='https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png'
                alt='加入好友'
                height='36'
                border='0'
              />
            </a>
          </div>
        </GuideCard>
      </GuideSection>
    </PageContainer>
  )
}

export default ModelsPage
