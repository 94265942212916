// src/components/NotFoundPage.js
import React from 'react'
import { Empty } from 'antd'
import { PHOTO_URL } from '../utils/apiutils'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`

const NotFoundPage = () => (
  <Container>
    <Helmet>
      <title>{`找不到網頁 ｜ 全台線上即時預約平台優惠推薦 - 美感｜BeauteFeel`}</title>
      <meta
        name='description'
        content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
      />
    </Helmet>
    <Empty
      image={`${PHOTO_URL}/public/assets/nodata.svg`}
      imageStyle={{
        height: 300
      }}
      description='Oops! 找不到網頁，請再試試哦'
    />
  </Container>
)

export default NotFoundPage
