import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Checkbox,
  Upload,
  Button,
  message
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { categoriesList } from '../utils/utils'
import { useAuth } from '../hooks/AuthContext'
import styled from 'styled-components'
import api from '../utils/api'
import { useNavigate } from 'react-router-dom'

const { Option } = Select
const { TextArea } = Input

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  text-align: left;
  @media (max-width: 768px) {
    margin: 20px;
  }
`

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

const AboutModel = styled.div`
  background-color: #f0f5ff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: bold;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  cursor: pointer;
`

const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`

const Title = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`
const Info = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #666;
  margin-bottom: 10px;
`

const ModelRegistrationForm = () => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const [isAllergic, setIsAllergic] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [showFollowerCount, setShowFollowerCount] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    // 如果用戶信息存在，則預填表單
    if (user) {
      form.setFieldsValue({
        name: user.name,
        phone: user.phone
      })
      if (user.isModel) {
        navigate('/')
      }
    }
  }, [user, form, navigate]) // 添加 navigate 到依赖数组

  const onFinish = async (values) => {
    if (!agreeTerms) {
      message.error('請同意服務條款和隱私政策')
      return
    }

    try {
      const formData = new FormData()

      // 添加所有表單字段到formData
      Object.keys(values).forEach((key) => {
        if (key === 'photos') {
          if (values[key] && values[key].length > 0) {
            values[key].forEach((file) => {
              formData.append('photos', file.originFileObj)
            })
          }
        } else if (key === 'birthdate') {
          formData.append(key, values[key].format('YYYY-MM-DD'))
        } else {
          formData.append(key, values[key])
        }
      })

      // 添加 active: true 字段
      formData.append('active', true)

      const response = await api.post(
        '/api/user/model-registration',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      if (response.data.success) {
        message.success('模特註冊成功！')
        form.resetFields() // 清空表單
        navigate('/model-setting') // 導航到模特兒設定頁面
      } else {
        message.error('註冊失敗，請稍後重試')
      }
    } catch (error) {
      console.error('註冊錯誤：', error)
      message.error('註冊過程中出現錯誤，請稍後重試')
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const handleModelTypeChange = (value) => {
    const typesWithFollowers = [
      'modelWithFans',
      'kol',
      'influencer',
      'modelAndInfluencer'
    ]
    setShowFollowerCount(typesWithFollowers.includes(value))
  }

  return (
    <Container>
      <TitleContainer>
        <TitleBar />
        <Title>展開你與店家的合作</Title>
      </TitleContainer>
      <SubTitle>請填寫以下資料，讓店家更瞭解你</SubTitle>
      <Info>
        一旦提交，您的資料將被推送至店家系統，您可隨時查看店家是否有提出邀約，或是您可直接申請店家的招聘，為了您的隱私，店家不會看到您的聯絡方式，直到您接受邀約，完成媒合後店家才會看到您的聯繫方式。
      </Info>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        style={{ maxWidth: 800, margin: '10px auto' }}
      >
        {/* 基本信息 */}
        <AboutModel>有關你的基本信息</AboutModel>
        <Form.Item name='name' label='姓名' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name='birthdate' label='生日' rules={[{ required: true }]}>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name='phone' label='手機' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name='gender' label='性別' rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value='male'>男</Radio>
            <Radio value='female'>女</Radio>
            <Radio value='other'>其他</Radio>
          </Radio.Group>
        </Form.Item>

        {/* 狀態 */}
        <AboutModel>有關你的狀態</AboutModel>
        <Form.Item
          name='skinType'
          label='皮膚狀態'
          rules={[{ required: true }]}
        >
          <Select>
            <Option value='combination'>混合性</Option>
            <Option value='dry'>偏乾</Option>
            <Option value='oily'>偏油</Option>
          </Select>
        </Form.Item>

        <Form.Item name='hairType' label='髮質' rules={[{ required: true }]}>
          <Select>
            <Option value='coarse'>粗硬</Option>
            <Option value='fine'>細軟</Option>
            <Option value='damaged'>受損</Option>
            <Option value='normal'>適中</Option>
          </Select>
        </Form.Item>

        <Form.Item name='hairVolume' label='髮量' rules={[{ required: true }]}>
          <Select>
            <Option value='thin'>少</Option>
            <Option value='medium'>適中</Option>
            <Option value='thick'>多</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='hairLength'
          label='頭髮長度'
          rules={[{ required: true }]}
        >
          <Select>
            <Option value='short'>短</Option>
            <Option value='medium'>中</Option>
            <Option value='long'>長</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='nailCondition'
          label='指甲狀況'
          rules={[{ required: true }]}
        >
          <Select>
            <Option value='brittle'>斷裂</Option>
            <Option value='normal'>正常</Option>
            <Option value='ingrown'>嵌甲</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='bodyCondition'
          label='身體狀況'
          rules={[{ required: true }]}
        >
          <Select>
            <Option value='pregnant'>懷孕</Option>
            <Option value='breastfeeding'>哺乳</Option>
            <Option value='none'>無</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='isAllergic'
          label='是否過敏'
          rules={[{ required: true }]}
        >
          <Radio.Group onChange={(e) => setIsAllergic(e.target.value)}>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        {isAllergic && (
          <Form.Item name='allergyDetails' label='過敏詳情'>
            <TextArea rows={4} />
          </Form.Item>
        )}

        {/* 期望 */}
        <AboutModel>有關你的期望</AboutModel>
        <Form.Item
          name='preferredCategories'
          label='希望參與項目類型'
          rules={[{ required: true, type: 'array' }]}
        >
          <Select mode='multiple'>
            {categoriesList.map((category) => (
              <Option key={category.type} value={category.type}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='recruitmentType'
          label='體驗類型'
          rules={[{ required: true }]}
        >
          <Select>
            <Option value='experienceModal'>體驗模特兒(純體驗)</Option>
            <Option value='reciprocalModel'>
              互惠模特兒(可幫忙宣傳與發文)
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='expectPayment'
          label='期望收費與否'
          rules={[{ required: true }]}
        >
          <Select>
            <Option value='yes'>是</Option>
            <Option value='no'>否</Option>
            <Option value='maybe'>不確定，看情況</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='needPayment'
          label='是否需要報酬'
          rules={[{ required: true }]}
        >
          <Select>
            <Option value='yes'>是</Option>
            <Option value='no'>否</Option>
            <Option value='maybe'>不確定，看情況</Option>
          </Select>
        </Form.Item>

        {/* 關於模特的介紹 */}
        <AboutModel>有關你的個人的介紹</AboutModel>
        <Form.Item
          name='modelType'
          label='你的類型'
          rules={[{ required: true }]}
        >
          <Select onChange={handleModelTypeChange}>
            <Option value='model'>素人模特兒</Option>
            <Option value='modelWithFans'>自帶粉絲模特兒</Option>
            <Option value='kol'>KOL</Option>
            <Option value='influencer'>網紅</Option>
            <Option value='modelAndInfluencer'>模特兒兼網紅</Option>
            <Option value='other'>其他</Option>
          </Select>
        </Form.Item>

        {showFollowerCount && (
          <Form.Item
            name='followerCount'
            label='粉絲規模'
            rules={[{ required: true }]}
          >
            <Select>
              <Option value='1000'>1000-5000</Option>
              <Option value='5000'>5000-10000</Option>
              <Option value='10000'>10000-50000</Option>
              <Option value='100000'>50000-100000</Option>
              <Option value='1000000'>100000以上</Option>
              <Option value='other'>其他</Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item name='instagramUrl' label='Instagram 網址'>
          <Input />
        </Form.Item>
        <Form.Item name='facebookUrl' label='Facebook 網址'>
          <Input />
        </Form.Item>
        <Form.Item name='lineId' label='Line ID' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name='aboutMe' label='關於你自己的介紹(請簡明扼要敘述)'>
          <Input />
        </Form.Item>

        {/* 照片 */}
        <AboutModel>可以提供店家參考的照片</AboutModel>
        <Form.Item
          name='photos'
          label='上傳相關照片（最多10張，每張5MB以下）'
          valuePropName='fileList'
          getValueFromEvent={normFile}
        >
          <Upload
            listType='picture'
            maxCount={10}
            beforeUpload={(file) => {
              const isLt5M = file.size / 1024 / 1024 < 5
              if (!isLt5M) {
                message.error('圖片必須小於5MB!')
              }
              return false // 阻止自動上傳
            }}
          >
            <Button icon={<UploadOutlined />}>選擇照片</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Checkbox
            checked={agreeTerms}
            onChange={(e) => setAgreeTerms(e.target.checked)}
          >
            我已閱讀並同意<a href='/terms'>服務條款</a>和
            <a href='/privacy'>隱私政策</a>
            。我理解並確認本平臺僅作為第三方中介，不承擔任何連帶責任。如有任何問題或紛爭，我將直接與相關店家協商解決。
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' disabled={!agreeTerms}>
            提交
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}

export default ModelRegistrationForm
