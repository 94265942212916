import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react'
import axios from 'axios'
import { useSocket } from './useSocket' // 新增的 hook
import { API_BASE_URL } from '../utils/apiutils'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const { connectSocket, disconnectSocket } = useSocket()

  const handleSocketConnection = useCallback(
    (userData) => {
      if (userData && userData.userId) {
        connectSocket(userData.userId)
      } else {
        disconnectSocket()
      }
    },
    [connectSocket, disconnectSocket]
  )

  const checkUserStatus = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/auth/check-user-status`,
        { withCredentials: true }
      )
      if (response.data.success) {
        setUser(response.data)
      } else {
        setUser(null)
      }
    } catch (error) {
      console.error('檢查用戶狀態時發生錯誤:', error)
      setUser(null)
    }
  }, [])

  useEffect(() => {
    handleSocketConnection(user)
  }, [user, handleSocketConnection])

  const login = useCallback(
    async (credentials) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/auth/login/user`,
          credentials,
          { withCredentials: true }
        )
        setUser(response.data)
        handleSocketConnection(response.data)
      } catch (error) {
        throw error
      }
    },
    [handleSocketConnection]
  )

  const logout = useCallback(async () => {
    await axios.post(
      `${API_BASE_URL}/api/auth/logout`,
      {},
      { withCredentials: true }
    )
    setUser(null)
    handleSocketConnection(null)
  }, [handleSocketConnection])

  const refreshToken = useCallback(async () => {
    try {
      await axios.post(
        `${API_BASE_URL}/api/auth/refresh-token`,
        {},
        { withCredentials: true }
      )
      await checkUserStatus()
    } catch (error) {
      setUser(null)
      handleSocketConnection(null)
    }
  }, [checkUserStatus, handleSocketConnection])

  const contextValue = {
    user,
    login,
    logout,
    refreshToken,
    checkUserStatus
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
