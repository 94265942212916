import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { PHOTO_URL } from '../utils/apiutils'
import Loading from '../utils/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../utils/api'
import { useAuth } from '../hooks/AuthContext'

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 820px) {
    max-width: 100%;
    margin 0;
    padding: 0;
  }
`

const MainContent = styled.div`
  padding: 20px 20px 20px 20px;
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
`

const Tab = styled.div`
  padding: 10px 20px;
  margin: 0 10px;
  background-color: ${(props) => (props.active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.active ? '#FFF' : '#000')};
  border-radius: 20px;
  cursor: pointer;
  @media (max-width: 480px) {
    padding: 10px 12px;
    margin: 0 5px;
  }
`

const PhotoWall = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;

  @media (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px;
  }
`

const PhotoItem = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }
`

const PhotoImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const PhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`

const ItemName = styled.h3`
  color: white;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  cursor: pointer;
`

const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`
const Title = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`

const UserFavoriteList = () => {
  const { user } = useAuth()
  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { category } = useParams()
  const [activeTab, setActiveTab] = useState(category || 'store')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        let endpoint = ''
        let dataKey = ''
        switch (activeTab) {
          case 'store':
            endpoint = `/api/user/${user?.userId}/favorite-businesses`
            dataKey = 'favoriteBusinesses'
            break
          case 'stylists':
            endpoint = `/api/user/${user?.userId}/favorite-designers`
            dataKey = 'favoriteDesigners'
            break
          case 'services':
            endpoint = `/api/user/${user?.userId}/favorite-services`
            dataKey = 'favoriteServices'
            break
          default:
            endpoint = `/api/user/${user.userId}/favorite-businesses`
            dataKey = 'favoriteBusinesses'
        }
        const response = await api.get(endpoint)
        setFavorites(response.data[dataKey] || [])
        setLoading(false)
      } catch (error) {
        console.error('獲取收藏夾數據時出錯:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [activeTab, user])

  useEffect(() => {
    navigate(`/favorites/${activeTab}`)
  }, [activeTab, navigate, user])

  if (loading) {
    return <Loading />
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  const renderItem = (item) => {
    let name, image, navigatePath
    switch (activeTab) {
      case 'store':
        name = item.name
        image =
          item.photos && item.photos.length > 0 ? item.photos[0].url : null
        navigatePath = `/store/${item.username}`
        break
      case 'stylists':
        name = item.name
        image = item.avatar
        navigatePath = `/${item.username}`
        break
      case 'services':
        name = (
          <>
            {item.businessName}
            <br />
            {item.productName}
          </>
        )
        image = item.productImage
        navigatePath = `/store/${item.businessUsername}`
        break
      default:
        name = item.name
        image = item.image
        navigatePath = '/'
    }

    return (
      <PhotoItem key={item.id} onClick={() => navigate(navigatePath)}>
        <PhotoImage
          src={
            image
              ? `${PHOTO_URL}${image}`
              : `${PHOTO_URL}/public/assets/pink2.png`
          }
          alt={name}
        />
        <PhotoOverlay>
          <ItemName>{name}</ItemName>
        </PhotoOverlay>
      </PhotoItem>
    )
  }

  return (
    <Container>
      <MainContent>
        <TitleContainer>
          <TitleBar />
          <Title>我的收藏清單</Title>
        </TitleContainer>
        <TabsContainer>
          <Tab
            active={activeTab === 'store'}
            onClick={() => handleTabChange('store')}
          >
            所有商家
          </Tab>
          <Tab
            active={activeTab === 'stylists'}
            onClick={() => handleTabChange('stylists')}
          >
            所有設計師
          </Tab>
          <Tab
            active={activeTab === 'services'}
            onClick={() => handleTabChange('services')}
          >
            所有服務
          </Tab>
        </TabsContainer>

        <PhotoWall>{favorites.map((item) => renderItem(item))}</PhotoWall>
      </MainContent>
    </Container>
  )
}

export default UserFavoriteList
