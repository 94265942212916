import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userInfo: null,
  isAuthenticated: false,
  isVerified: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
      state.isAuthenticated = true
      state.isVerified = action.payload.isVerified
    },
    clearUserInfo: (state) => {
      state.userInfo = null
      state.isAuthenticated = false
      state.isVerified = false
    }
  }
})

export const { setUserInfo, clearUserInfo } = userSlice.actions
export default userSlice.reducer
