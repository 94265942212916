import React, { useState, useEffect, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import { FaStar, FaRegSadTear } from 'react-icons/fa'
import { generateRandomUsername } from '../utils/utils'
import api from '../utils/api'
import { PHOTO_URL } from '../utils/apiutils'

// 定義淡入動畫
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  padding: 30px;
  animation: ${fadeIn} 1s ease-in-out;
  @media (max-width: 820px) {
    background-color: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 820px) {
    flex-direction: column;
    gap: 10px;
  }
`

const AverageRating = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #2c3e50;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
    color: #f1c40f;
  }
`

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 820px) {
    gap: 5px;
    overflow-x: auto;
  }
`

const FilterButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background-color: ${(props) => (props.active ? '#d5728a' : '#e0e0e0')};
  color: ${(props) => (props.active ? 'white' : '#333')};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#c76279' : '#d0d0d0')};
  }
  @media (max-width: 820px) {
    padding: 5px 10px;
    font-size: 12px;
  }
`

const RatingDistribution = styled.div`
  margin-bottom: 30px;
`

const RatingBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const StarCount = styled.span`
  width: 20px;
  color: #2c3e50;
`

const Bar = styled.div`
  height: 8px;
  background-color: #e0e0e0;
  flex-grow: 1;
  margin: 0 10px;
  border-radius: 4px;
  overflow: hidden;
`

const FilledBar = styled.div`
  height: 100%;
  background-color: #d5728a;
  width: ${(props) => props.percentage}%;
  transition: width 0.3s ease;
`

const Count = styled.span`
  width: 30px;
  text-align: right;
  color: #7f8c8d;
`

const ReviewList = styled.div`
  margin-top: 20px;
`

const ReviewItem = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`

const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`

const Username = styled.span`
  font-weight: bold;
  color: #34495e;
`

const ReviewRating = styled.div`
  color: #f1c40f;
`

const ReviewDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  font-size: 14px;
  color: #7f8c8d;
`

const DetailItem = styled.div`
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  span {
    color: #d45d7a;
    font-weight: bold;
  }
`

const ReviewComment = styled.p`
  margin: 10px 0;
  color: #2c3e50;
  line-height: 1.6;
  text-align: left;
`

const EmptyState = styled.div`
  text-align: center;
  padding: 40px 0;
  color: #888;
`

const EmptyIcon = styled(FaRegSadTear)`
  font-size: 48px;
  margin-bottom: 20px;
`

const EmptyText = styled.p`
  font-size: 18px;
`

const LoadMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #d5728a;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c76279;
  }
`

// 新增 Google 標籤樣式
const GoogleTag = styled.span`
  background-color: #4285f4;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
`

const Reviews = ({ businessId, stylistId }) => {
  const [reviews, setReviews] = useState([])
  const [filterRating, setFilterRating] = useState(0)
  const [page, setPage] = useState(1)
  const [limit] = useState(20)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [hasMore, setHasMore] = useState(true)

  const fetchReviews = useCallback(async () => {
    try {
      setLoading(true)
      let url
      if (businessId) {
        url = `/api/business_C/${businessId}/reviews`
      } else if (stylistId) {
        url = `/api/stylist/${stylistId}/reviews`
      } else {
        throw new Error('必須提供 businessId 或 stylistId')
      }

      const response = await api.get(url, {
        params: {
          page,
          limit,
          rating: filterRating === 0 ? undefined : filterRating
        }
      })

      if (response.data && response.data.data) {
        const { reviews, currentPage, totalPages } = response.data.data

        if (Array.isArray(reviews)) {
          if (page === 1) {
            setReviews(reviews)
          } else {
            setReviews((prevReviews) => [...prevReviews, ...reviews])
          }
          setHasMore(currentPage < totalPages)
        } else {
          console.error('reviews 不是一個數組')
          setError('加載評論時出錯：數據格式不正確')
        }
      } else {
        console.error('API 返回的數據格式不正確')
        setError('加載評論時出錯：數據格式不正確')
      }
      setLoading(false)
    } catch (err) {
      console.error('加載評論時出錯:', err)
      setError('無法加載評論')
      setLoading(false)
    }
  }, [businessId, stylistId, page, limit, filterRating])

  useEffect(() => {
    fetchReviews()
  }, [fetchReviews])

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  if (error) {
    return <Container>{error}</Container>
  }

  if (reviews.length === 0 && !loading) {
    return (
      <Container>
        <Header>
          <AverageRating>
            平均分數: <FaStar />
          </AverageRating>
          <FilterContainer>
            {[0, 5, 4, 3, 2, 1].map((rating) => (
              <FilterButton
                key={rating}
                active={filterRating === rating}
                onClick={() => setFilterRating(rating)}
              >
                {rating === 0 ? '全部' : `${rating} 星`}
              </FilterButton>
            ))}
          </FilterContainer>
        </Header>

        <EmptyState>
          <EmptyIcon />
          <EmptyText>暫無評論</EmptyText>
        </EmptyState>
      </Container>
    )
  }

  const ratingCounts = reviews.reduce((acc, review) => {
    acc[review.rating] = (acc[review.rating] || 0) + 1
    return acc
  }, {})

  return (
    <Container>
      <Header>
        <AverageRating>
          {reviews && reviews.length > 0
            ? (
                reviews.reduce((acc, review) => acc + review.rating, 0) /
                reviews.length
              ).toFixed(1)
            : '0'}{' '}
          <FaStar />
        </AverageRating>
        <FilterContainer>
          {[0, 5, 4, 3, 2, 1].map((rating) => (
            <FilterButton
              key={rating}
              active={filterRating === rating}
              onClick={() => setFilterRating(rating)}
            >
              {rating === 0 ? '全部' : `${rating} 星`}
            </FilterButton>
          ))}
        </FilterContainer>
      </Header>

      {reviews && reviews.length > 0 ? (
        <>
          <RatingDistribution>
            {[5, 4, 3, 2, 1].map((stars) => (
              <RatingBar key={stars}>
                <StarCount>{stars}</StarCount>
                <Bar>
                  <FilledBar
                    percentage={
                      ((ratingCounts[stars] || 0) / reviews.length) * 100
                    }
                  />
                </Bar>
                <Count>{ratingCounts[stars] || 0}</Count>
              </RatingBar>
            ))}
          </RatingDistribution>

          <ReviewList>
            {(filterRating === 0
              ? reviews
              : reviews.filter((review) => review.rating === filterRating)
            ).map((review) => (
              <ReviewItem key={review._id}>
                <ReviewHeader>
                  <UserInfo>
                    {review.user && review.user.avatar && (
                      <UserAvatar
                        src={
                          review.user.avatar.startsWith('https://')
                            ? review.user.avatar
                            : `${PHOTO_URL}${review.user.avatar}`
                        }
                        alt={review.user.name}
                      />
                    )}
                    <Username>
                      {review.source === 'google'
                        ? review.authorName
                        : review.user && review.user.name
                        ? review.user.name
                        : generateRandomUsername()}
                      {review.source === 'google' && (
                        <GoogleTag>Google</GoogleTag>
                      )}
                    </Username>
                  </UserInfo>
                  <ReviewRating>
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        color={index < review.rating ? '#f1c40f' : '#e0e0e0'}
                      />
                    ))}
                  </ReviewRating>
                </ReviewHeader>
                <ReviewComment>{review.comment}</ReviewComment>
                <ReviewDetails>
                  {review.serviceName && (
                    <DetailItem>預約項目: {review.serviceName}</DetailItem>
                  )}
                  {review.environment && (
                    <DetailItem>
                      環境: <span>{review.environment}</span>
                    </DetailItem>
                  )}
                  {review.service && (
                    <DetailItem>
                      服務: <span>{review.service}</span>
                    </DetailItem>
                  )}
                  {review.price && (
                    <DetailItem>
                      價格: <span>{review.price}</span>
                    </DetailItem>
                  )}
                  <DetailItem>
                    發佈日期:{' '}
                    {review.source === 'google'
                      ? new Date(review.time).toLocaleDateString()
                      : new Date(review.createdAt).toLocaleDateString()}
                  </DetailItem>
                </ReviewDetails>
              </ReviewItem>
            ))}
          </ReviewList>
        </>
      ) : (
        <EmptyState>
          <EmptyIcon />
          <EmptyText>{loading ? '加載中...' : error || '暫無評論'}</EmptyText>
        </EmptyState>
      )}

      {!loading && !error && reviews && reviews.length > 0 && hasMore && (
        <LoadMoreButton onClick={loadMore}>加載更多</LoadMoreButton>
      )}
    </Container>
  )
}

export default Reviews
