export const promotionItems = [
  {
    image:
      'https://f005.backblazeb2.com/file/beautyapp/uploads/product_images/66fb960c80b1ceec6affd98d_1727772751051.png',
    title: 'VIO全除＋腋下',
    link: '/store/Rubeauty95?productId=66fbb85080b1ceec6a004fd1',
    productId: '66fbb85080b1ceec6a004fd1',
    tags: ['新客限定'],
    description: 'VIO全除＋腋下線上預約享 $1800 → $1200',
    price: '$1200',
    discountCode: 'RUVIO1200'
  },
  {
    image:
      'https://f005.backblazeb2.com/file/beautyapp/uploads/product_images/66fb960c80b1ceec6affd98d_1727805162882.jpg',
    title: '半永久輕奢仙霧眉',
    link: '/store/Rubeauty95?productId=66fbae4080b1ceec6a003c19',
    productId: '66fbae4080b1ceec6a003c19',
    tags: ['預約專享'],
    description: '半永久輕奢仙霧眉線上預約享 $6000 → $4000',
    price: '$4000',
    discountCode: 'RU4000'
  },
  {
    image:
      'https://f005.backblazeb2.com/file/beautyapp/uploads/product_images/66fb960c80b1ceec6affd98d_1727772221378.png',
    title: '韓式基礎皮膚管理',
    link: '/store/Rubeauty95?productId=66fbb63e80b1ceec6a004e7f',
    productId: '66fbb63e80b1ceec6a004e7f',
    tags: ['預約專享'],
    description: '韓式基礎皮膚管理線上預約享 $999 → $600',
    price: '$600',
    discountCode: 'RU600'
  },
  {
    image:
      'https://f005.backblazeb2.com/file/beautyapp/uploads/product_images/66fb9c5d80b1ceec6affe384_1727769309032.jpeg',
    title: '3D單根嫁接-自然款',
    link: '/store/wen_880921?productId=66fbaade80b1ceec6a000e5a',
    productId: '66fbaade80b1ceec6a000e5a',
    tags: ['預約專享'],
    description: '3D單根嫁接-自然款線上預約享 $1500 → $999',
    price: '$999',
    discountCode: 'DIS999'
  },
  {
    image:
      'https://f005.backblazeb2.com/file/beautyapp/uploads/product_images/67023af623a8598beb574314_1730213327798.png/4ab85971-9d12-4f93-b39a-0822cc2a49ee',
    title: '指甲彩繪',
    link: '/store/ning_studio?productId=67023d4e23a8598beb5746ca',
    productId: '67023d4e23a8598beb5746ca',
    tags: ['新客限定'],
    description: '指甲彩繪線上預約享 $1900 → $999',
    price: '$999',
    discountCode: 'DIS999'
  },
  {
    image: '/assets/promotion.webp',
    title: '首次體驗折扣',
    link: '/store/owner_winspa',
    productId: '66988f4360ea48feada65118',
    description: '新用戶消費1000即可享受優惠(測試用)',
    tags: ['新客限定'],
    price: '999元現金折扣',
    discountCode: 'new999'
  }
]
