import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { useAuth } from '../hooks/AuthContext'

const NotificationCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 300px;
  height: auto;
  max-height: 400px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`

const MarkAllAsReadButton = styled(Button)`
  margin-bottom: 16px;
`

const NotificationList = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const NotificationItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.2s ease;
  cursor: pointer;
  font-weight: ${(props) => (props.isNew ? '500' : 'normal')};
  background-color: ${(props) => (props.isNew ? '#f5f5f5' : 'white')};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f5f5f5;
    color: ${(props) => (props.isNew ? '#4285f4' : '#000')};
  }
`

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #4285f4;
  border-radius: 50%;
  margin-right: 12px;
  margin-top: 6px;
`

const Content = styled.div`
  flex-grow: 1;
`

const NotificationTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`

const Timestamp = styled.div`
  font-size: 12px;
  color: #666;
`

const Bottom = styled.div`
  margin-top: 16px;
  text-align: center;
`

const ViewAll = styled.div`
  color: #4285f4;
  font-size: 14px;
  cursor: pointer;
`

const NotificationComponent = ({
  notifications,
  onMarkAllAsRead,
  fetchNotifications
}) => {
  const navigate = useNavigate()
  const { user } = useAuth()

  const handleMarkAllAsRead = async () => {
    await onMarkAllAsRead()
    await fetchNotifications()
  }

  const handleNotificationClick = (notification) => {
    navigate(`/user-appointment-list/${user.username}`)
  }

  return (
    <NotificationCard>
      <Header>
        <Title>通知</Title>
        <MarkAllAsReadButton onClick={handleMarkAllAsRead}>
          全部已讀
        </MarkAllAsReadButton>
      </Header>
      <NotificationList>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification._id}
            isNew={!notification.isRead}
            onClick={() => handleNotificationClick(notification)}
          >
            {notification.isRead ? null : <Dot />}
            <Content>
              <NotificationTitle>{notification.message}</NotificationTitle>
              <Timestamp>
                {new Date(notification.createdAt).toLocaleString()}
              </Timestamp>
            </Content>
          </NotificationItem>
        ))}
      </NotificationList>
      <Bottom>
        <ViewAll onClick={() => navigate('/notifications')}>
          查看全部通知
        </ViewAll>
      </Bottom>
    </NotificationCard>
  )
}

export default NotificationComponent
