import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Tag, Empty } from 'antd'
import {
  HeartOutlined,
  MessageOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { PHOTO_URL, API_BASE_URL } from '../utils/apiutils'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import Loading from '../utils/Loading'

const PageContainer = styled.div`
  padding: 20px;
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  @media (max-width: 820px) {
    padding: 20px 0;
    background-color: #fafafa;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 6px;
  align-items: center; // 垂直居中
  justify-content: center; // 水平居中

  @media (max-width: 820px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 4px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative; // 添加這行
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #f6f6f6;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px;
  opacity: 0;
  transition: opacity 0.3s;

  ${ImageContainer}:hover & {
    opacity: 1;
  }
`

const Title = styled.h3`
  color: white;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 12px;
    text-align: left;
  }
`

const EnhancedTag = styled(Tag)`
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 20px;
  margin-right: 12px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 820px) {
    font-size: 15px;
    padding: 4px 12px;
    margin-right: 4px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    padding: 4px 7px;
    margin-right: 4px;
  }
`

const ModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow: auto;
  }
`

const ModalImageContainer = styled.div`
  position: relative;
  width: 65%;
  height: 100%;

  @media (max-width: 820px) {
    width: 100%;
    height: 80%;
  }
`

const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  @media (max-width: 820px) {
    width: 100%;
    height: 100%;
  }
`

const ModalInfo = styled.div`
  width: 35%;
  padding: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: 820px) {
    width: 90%;
  }
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 16px;
  @media (max-width: 820px) {
    padding-bottom: 8px;
    margin-bottom: 0px;
  }
`

const ModalTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  @media (max-width: 820px) {
    margin-bottom: 0;
  }
`

const ModalDescription = styled.p`
  font-size: 16px;
  margin-bottom: 16px;
  text-align: left;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`

const ModalTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  z-index: 1200;

  @media (max-width: 820px) {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }
`

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  color: #262626;
`

const ActionIcon = styled.span`
  margin-right: 16px;
  font-size: 24px;
  cursor: pointer;
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ImageNavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #262626;
  border: none;
  cursor: pointer;
  z-index: 1200;
`

const StylistPortfolio = ({ username }) => {
  const [portfolios, setPortfolios] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const { portfolioId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchStylistData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/business_C/stylist/${username}`
        )
        setPortfolios(response.data.stylist.portfolio)
      } catch (error) {
        console.error('獲取造型師數據失敗:', error)
      } finally {
        setIsLoading(false)
      }
    }
    if (username) {
      fetchStylistData()
    }
  }, [username])

  const showPortfolio = (id) => {
    navigate(`/${username}/portfolio/${id}`)
  }

  const closePortfolio = () => {
    navigate(`/${username}/portfolio`)
  }

  // 渲染作品集列表
  const renderPortfolioGrid = () => {
    return (
      <Grid>
        {portfolios && portfolios.length > 0 ? (
          portfolios.map((portfolio) => (
            <ImageContainer
              key={portfolio._id}
              onClick={() => showPortfolio(portfolio._id)}
            >
              <Image
                src={
                  portfolio.imageUrls && portfolio.imageUrls.length > 0
                    ? `${PHOTO_URL}${portfolio.imageUrls[0]}`
                    : `${PHOTO_URL}/portfolio/669a0ae21cf15090caf3effe_portfolio_2024-09-06T11%3A13%3A15.309Z_%C3%A6%C2%88%C2%AA%C3%A5%C2%9C%C2%96%202024-09-06%20%C3%A6%C2%99%C2%9A%C3%A4%C2%B8%C2%8A8.12.41.png`
                }
                alt={portfolio.title || '作品集圖片'}
              />
              <Overlay>
                <Title>
                  {portfolio.title.length > 5
                    ? portfolio.title.slice(0, 5) + '..'
                    : portfolio.title}
                </Title>
              </Overlay>
            </ImageContainer>
          ))
        ) : (
          <div style={{ gridColumn: '1 / -1', textAlign: 'center' }}>
            <Empty description='暫無作品集' />
          </div>
        )}
      </Grid>
    )
  }

  // 渲染作品集详情模态框
  const renderPortfolioModal = () => {
    const selectedPortfolio = portfolios.find((p) => p._id === portfolioId)
    if (!selectedPortfolio) return null

    const handlePrevImage = (e) => {
      e.stopPropagation()
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? selectedPortfolio.imageUrls.length - 1 : prevIndex - 1
      )
    }

    const handleNextImage = (e) => {
      e.stopPropagation()
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % selectedPortfolio.imageUrls.length
      )
    }

    return (
      <ModalOverlay
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 1500
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            background: 'none',
            width: '100%',
            height: '100%',
            maxWidth: '935px',
            zIndex: 1600
          }}
        >
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closePortfolio} />
            <ModalImageContainer>
              <ModalImage
                src={`${PHOTO_URL}${selectedPortfolio.imageUrls[currentImageIndex]}`}
                alt={selectedPortfolio.title}
              />
              {selectedPortfolio.imageUrls.length > 1 && (
                <>
                  <ImageNavButton
                    style={{ left: '10px' }}
                    onClick={handlePrevImage}
                  >
                    &#10094;
                  </ImageNavButton>
                  <ImageNavButton
                    style={{ right: '10px' }}
                    onClick={handleNextImage}
                  >
                    &#10095;
                  </ImageNavButton>
                </>
              )}
            </ModalImageContainer>
            <ModalInfo>
              <ModalHeader>
                <ModalTitle>{selectedPortfolio.title}</ModalTitle>
              </ModalHeader>
              <ModalDescription>{selectedPortfolio.content}</ModalDescription>
              <ModalTags>
                {selectedPortfolio.tags &&
                  selectedPortfolio.tags.map((tag, index) => (
                    <EnhancedTag key={index} color='#1890ff'>
                      {tag}
                    </EnhancedTag>
                  ))}
              </ModalTags>
              {/* <ModalActions>
                <ActionIcon>
                  <HeartOutlined />
                </ActionIcon>
                <ActionIcon>
                  <MessageOutlined />
                </ActionIcon>
              </ModalActions> */}
            </ModalInfo>
          </ModalContent>
        </div>
      </ModalOverlay>
    )
  }

  if (isLoading) {
    return <Loading />
  }

  // 如果URL中包含portfolioId，顯示單個作品集詳情
  if (portfolioId) {
    const selectedPortfolio = portfolios.find((p) => p._id === portfolioId)
    if (!selectedPortfolio) return <p>作品集未找到</p>

    return <PageContainer>{renderPortfolioModal()}</PageContainer>
  }

  // 顯示作品集網格
  return <PageContainer>{renderPortfolioGrid()}</PageContainer>
}

export default StylistPortfolio
