import React, { useState, useEffect } from 'react'
import { Button, message } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_BASE_URL } from '../utils/apiutils'
import { useAuth } from '../hooks/AuthContext'

const CheckEmailPage = () => {
  const navigate = useNavigate()
  const { user, checkUserStatus } = useAuth()
  const [canResend, setCanResend] = useState(true)

  useEffect(() => {
    const lastSentTime = localStorage.getItem('lastVerificationSentTime')
    if (lastSentTime) {
      const timeDiff = Date.now() - parseInt(lastSentTime)
      if (timeDiff < 30 * 60 * 1000) {
        setCanResend(false)
        const remainingTime = 30 * 60 * 1000 - timeDiff
        setTimeout(() => setCanResend(true), remainingTime)
      }
    }
  }, [])

  const handleResendVerification = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/auth/resend-verification`, {
        email: user.email
      })
      message.success('驗證郵件已重新發送，請檢查您的電子信箱。')
      setCanResend(false)
      localStorage.setItem('lastVerificationSentTime', Date.now().toString())
      setTimeout(() => setCanResend(true), 30 * 60 * 1000)
    } catch (error) {
      message.error('重新發送驗證郵件失敗，請稍後再試。')
    }
  }

  useEffect(() => {
    let isSubscribed = true

    const checkStatus = async () => {
      if (isSubscribed) {
        await checkUserStatus()
      }
    }

    checkStatus()

    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>請檢查您的電子信箱</h1>
      <p>為了完成註冊，請檢查您的電子信箱並點擊驗證鏈接。</p>
      <p>如果您沒有收到郵件，您可以點擊下面的按鈕重新發送驗證郵件。</p>
      {canResend ? (
        <Button
          type='primary'
          onClick={handleResendVerification}
          style={{ marginRight: '10px' }}
        >
          重新發送驗證郵件
        </Button>
      ) : (
        <div style={{ margin: '10px 0' }}>
          <CheckCircleOutlined
            style={{ color: '#52c41a', fontSize: '24px', marginRight: '8px' }}
          />
          已完成重新發送驗證信件
        </div>
      )}
      <Button
        type='default'
        style={{ marginTop: '20px' }}
        onClick={() => navigate('/')}
      >
        返回首頁
      </Button>
    </div>
  )
}

export default CheckEmailPage
