import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { PHOTO_URL } from '../utils/apiutils'
import { AddToCalendarButton } from 'add-to-calendar-button-react'

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`

const Title = styled.h1`
  color: #333;
  margin-bottom: 10px;
`

const Message = styled.p`
  color: #666;
  font-size: 18px;
  margin-bottom: 40px;
`

const CalendarContainer = styled.div`
  width: 300px;
  margin-bottom: 20px;
`

const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`

const BookingSuccessPage = () => {
  const location = useLocation()
  console.log(location)
  const { locationName, locationAddress, selectedDate, selectedTime } =
    location.state || {}

  return (
    <SuccessContainer>
      <Image
        src={`${PHOTO_URL}/public/assets/welldone.svg`}
        alt='Success'
      ></Image>
      <Title>謝謝！</Title>
      <Message>預訂成功！感謝您的信任，期待您的光臨！</Message>

      <CalendarContainer>添加到行事曆</CalendarContainer>

      <AddToCalendarButton
        name={`在${locationName}的預約`}
        options={['Apple', 'Google']}
        location={locationAddress}
        startDate={selectedDate}
        endDate={selectedDate}
        startTime={selectedTime}
        endTime={selectedTime}
        timeZone='Asia/Taipei'
      ></AddToCalendarButton>
    </SuccessContainer>
  )
}

export default BookingSuccessPage
