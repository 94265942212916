import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Empty, message } from 'antd'
import axios from 'axios'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import Loading from '../utils/Loading'
import Modal from 'react-modal'
import {
  CloseOutlined,
  HeartOutlined,
  MessageOutlined
} from '@ant-design/icons'

const PageContainer = styled.div``

const PhotoWall = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 6px;

  @media (max-width: 820px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 4px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: 820px) {
    width: 100%;
    height: 100%;
  }
`

const ModalInfo = styled.div`
  width: 35%;
  padding: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: 820px) {
    width: 90%;
    /* height: 50%; */
  }
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 16px;
`

const ModalAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
  object-position: top;

  @media (max-width: 820px) {
    width: 28px;
    height: 28px;
  }
`

const ModalStylistName = styled.span`
  font-weight: 600;
`

const ModalTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`

const ModalDescription = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`

const ModalTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`

const EnhancedTag = styled.span`
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 20px;
  margin-right: 12px;
  margin-bottom: 5px;
  background-color: #1890ff;
  color: white;

  @media (max-width: 820px) {
    font-size: 15px;
    padding: 4px 12px;
    margin-right: 4px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    padding: 4px 7px;
    margin-right: 4px;
  }
`

const CloseButton = styled(CloseOutlined)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;

  @media (max-width: 768px) {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }
`

const ModalImageContainer = styled.div`
  position: relative;
  width: 65%;
  height: 100%;

  @media (max-width: 820px) {
    width: 100%;
    height: 80%;
  }
`

const ModalNavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #262626;
  border: none;
  cursor: pointer;
  z-index: 1100;
`

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  color: #262626;
`

const ActionIcon = styled.span`
  margin-right: 16px;
  font-size: 24px;
  cursor: pointer;
`

const StorePortfolio = ({ businessId }) => {
  const [portfolios, setPortfolios] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const fetchStylistsPortfolios = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/business_C/${businessId}/stylists-portfolios`
      )
      const allStylists = response.data.data
      const allPortfolios = allStylists.flatMap((stylist) =>
        stylist.portfolio.map((portfolio) => ({
          ...portfolio,
          stylistName: stylist.name,
          stylistAvatar: stylist.avatar
        }))
      )
      setPortfolios(allPortfolios)
    } catch (error) {
      console.error('獲取造型師作品集失敗:', error)
      message.error('獲取造型師作品集失敗')
    }
    setIsLoading(false)
  }, [businessId])

  useEffect(() => {
    fetchStylistsPortfolios()
  }, [fetchStylistsPortfolios])

  const openModal = (portfolio) => {
    setSelectedPortfolio(portfolio)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    setSelectedPortfolio(null)
  }

  const handlePrevImage = (e) => {
    e.stopPropagation()
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedPortfolio.imageUrls.length - 1 : prevIndex - 1
    )
  }

  const handleNextImage = (e) => {
    e.stopPropagation()
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % selectedPortfolio.imageUrls.length
    )
  }

  return (
    <PageContainer>
      {isLoading ? (
        <Loading />
      ) : portfolios.length > 0 ? (
        <PhotoWall>
          {portfolios.map((portfolio) => (
            <ImageContainer
              key={portfolio._id}
              onClick={() => openModal(portfolio)}
            >
              <Image
                src={`${PHOTO_URL}${portfolio.imageUrls[0]}`}
                alt={portfolio.title}
              />
            </ImageContainer>
          ))}
        </PhotoWall>
      ) : (
        <Empty description='暫無造型師作品集' style={{ marginTop: '50px' }} />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1500 // 提高 z-index 以確保在導航欄之上
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            background: 'none',
            width: '100%',
            height: '100%',
            maxWidth: '935px',
            zIndex: 1600 // 確保內容在遮罩層之上
          }
        }}
      >
        {selectedPortfolio && (
          <ModalContent>
            <CloseButton onClick={closeModal} />
            <ModalImageContainer>
              <ModalImage
                src={`${PHOTO_URL}${selectedPortfolio.imageUrls[currentImageIndex]}`}
                alt={selectedPortfolio.title}
              />
              {selectedPortfolio.imageUrls.length > 1 && (
                <>
                  <ModalNavButton
                    style={{ left: '10px' }}
                    onClick={handlePrevImage}
                  >
                    &#10094;
                  </ModalNavButton>
                  <ModalNavButton
                    style={{ right: '10px' }}
                    onClick={handleNextImage}
                  >
                    &#10095;
                  </ModalNavButton>
                </>
              )}
            </ModalImageContainer>
            <ModalInfo>
              <ModalHeader>
                <ModalAvatar
                  src={`${PHOTO_URL}${selectedPortfolio.stylistAvatar}`}
                  alt={selectedPortfolio.stylistName}
                />
                <ModalStylistName>
                  {selectedPortfolio.stylistName}
                </ModalStylistName>
              </ModalHeader>
              <ModalTitle>{selectedPortfolio.title}</ModalTitle>
              <ModalDescription>{selectedPortfolio.content}</ModalDescription>
              <ModalTags>
                {selectedPortfolio.tags &&
                  selectedPortfolio.tags.map((tag, idx) => (
                    <EnhancedTag key={idx}>{tag}</EnhancedTag>
                  ))}
              </ModalTags>
              {/* <ModalActions>
                <ActionIcon>
                  <HeartOutlined />
                </ActionIcon>
                <ActionIcon>
                  <MessageOutlined />
                </ActionIcon>
              </ModalActions> */}
            </ModalInfo>
          </ModalContent>
        )}
      </Modal>
    </PageContainer>
  )
}

export default StorePortfolio
