import React, { useEffect, useState } from 'react'
import { Result, Spin } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

const PaymentResult = () => {
  const [status, setStatus] = useState('loading')
  const [message, setMessage] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // 從 URL 中獲取 ret 參數
    const searchParams = new URLSearchParams(location.search)
    const ret = searchParams.get('ret')

    if (ret === 'ok') {
      setStatus('success')
      setMessage('付款成功！')
    } else {
      setStatus('error')
      setMessage(`付款失敗：${ret || '未知錯誤'}`)
    }

    // 3秒後自動跳轉回首頁
    const timer = setTimeout(() => {
      navigate('/')
    }, 3000)

    return () => clearTimeout(timer)
  }, [location, navigate])

  if (status === 'loading') {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size='large' />
        <p>正在處理付款結果...</p>
      </div>
    )
  }

  return (
    <Result
      status={status}
      title={message}
      subTitle='頁面將在3秒後自動跳轉...'
    />
  )
}

export default PaymentResult
