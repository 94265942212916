import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Drawer, Button, message, Tag, Flex, Tooltip } from 'antd'
import axios from 'axios'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { MdAttachMoney, MdCheckCircle } from 'react-icons/md'
import { colors } from '../utils/utils'
import moment from 'moment'
import api from '../utils/api'
import { Helmet } from 'react-helmet-async'

const Container = styled.div`
  font-family: 'Noto Sans TC', sans-serif;
  background-color: ${colors.background};
  color: ${colors.text};
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`

const JobGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const JobItem = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative; // 添加這行

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
`

const JobImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
`

const JobTitle = styled.h2`
  font-size: 18px;
  color: ${colors.primary};
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
  text-align: left;
`
const AreaTag = styled(Tag)`
  margin-bottom: 10px;
  color: #000;
  background-color: #ecf7fe;
  border: none;
`

const JobInfo = styled.p`
  font-size: 14px;
  color: ${colors.text};
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: left;

  svg {
    margin-right: 5px;
  }
`

const JobType = styled(Tag)`
  margin-bottom: 10px;
  font-weight: bold;
  background-color: ${colors.lightPink};
  color: ${colors.white};
  border: none;
`

const JobSalary = styled.p`
  font-size: 15px;
  font-weight: bold;
  color: ${colors.grey};
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-align: left;
  svg {
    margin-right: 5px;
  }
`

const DrawerContent = styled.div`
  padding: 0 30px 30px 30px;
`

const DrawerTitle = styled.h2`
  font-size: 20px;
  color: ${colors.primary};
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: bold;
`

const DrawerDescription = styled.p`
  font-size: 16px;
  color: ${colors.text};
  margin-bottom: 20px;
  line-height: 1.6;
  background-color: ${colors.paleYellow};
  padding: 10px;
  border-radius: 10px;
`

const DrawerInfo = styled.p`
  font-size: 14px;
  color: ${colors.text};
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`

const ApplyButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 20px;
`

const PortfolioImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
`

const TitleContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  cursor: pointer;
`
const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`
const Title = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`

const AppliedTag = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${colors.primary};
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1; // 確保標籤在其他元素之上

  svg {
    margin-right: 5px;
  }
`

const InteractedTag = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4f;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1; // 確保標籤在其他元素之上

  svg {
    margin-right: 5px;
  }
`

const HiringPage = () => {
  const [jobs, setJobs] = useState([])
  const [selectedJob, setSelectedJob] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [portfolios, setPortfolios] = useState([])

  useEffect(() => {
    fetchJobs()
  }, [])

  const fetchJobs = async () => {
    try {
      const response = await api.get(`/api/user/jobs`)
      const formattedJobs = response.data.data.map((item) => ({
        ...item.recruitment,
        businessId: item.businessId,
        businessName: item.businessName,
        businessUsername: item.businessUsername,
        businessAddress: item.businessAddress,
        businessPhone: item.businessPhone,
        hasApplied: item.recruitment.hasApplied,
        hasInteracted: item.recruitment.hasInteracted
      }))
      setJobs(formattedJobs)
    } catch (error) {
      console.error('獲取招聘信息失敗:', error)
      message.error('獲取招聘信息失敗,請稍後再試')
    }
  }

  const handleJobClick = async (job) => {
    setSelectedJob(job)
    setDrawerVisible(true)

    // 獲取店家作品集
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/business_C/${job.businessId}/stylists-portfolios`
      )
      const allStylists = response.data.data
      const allPortfolios = allStylists.flatMap((stylist) =>
        stylist.portfolio.map((portfolio) => ({
          ...portfolio,
          stylistName: stylist.name,
          stylistAvatar: stylist.avatar
        }))
      )
      setPortfolios(allPortfolios.slice(0, 10)) // 只取前10個作品
    } catch (error) {
      console.error('獲取店家作品集失敗:', error)
      message.error('獲取店家作品集失敗')
    }
  }

  const handleApply = async () => {
    try {
      await api.post(`/api/user/jobs/${selectedJob._id}/apply`)
      message.success('應徵成功!')
      setDrawerVisible(false)
    } catch (error) {
      console.error('應徵失敗:', error)
      message.error('應徵失敗,請稍後再試')
    }
  }

  const renderJobType = (job) => {
    switch (job.recruitmentType) {
      case 'employee':
        return '正式員工'
      case 'experienceModel':
        return job.experienceModelType === 'paid'
          ? '付費體驗模特兒'
          : '免費體驗模特兒'
      case 'reciprocalModel':
        return '互惠模特兒'
      default:
        return '未知類型'
    }
  }

  const renderSalaryInfo = (job) => {
    if (job.recruitmentType === 'employee') {
      return job.salaryType === 'negotiable'
        ? '薪資面議'
        : `薪資: ${job.salaryAmount} 元`
    } else if (
      job.recruitmentType === 'experienceModel' &&
      job.experienceModelType === 'paid'
    ) {
      return `體驗費用: ${job.experienceModelFee} 元`
    } else if (
      job.recruitmentType === 'reciprocalModel' &&
      job.reciprocalCompensationType === 'compensation'
    ) {
      return `給予報酬: ${job.reciprocalCompensationAmount} 元`
    }
    return '無費用'
  }

  const renderReciprocalOptions = (options) => {
    const optionMap = {
      socialMediaPost: 'IG/FB/Google其他管道發文',
      allowPhotoshoot: '同意店家拍攝成果作品照並上傳社群網路'
    }

    return options.map((option) => optionMap[option] || option).join('、')
  }

  const renderRecruitmentItems = (items) => {
    return items.map((item) => item.name).join('、')
  }

  return (
    <Container>
      <Helmet>
        <title>{`全台美業商家招聘體驗募集中 & 線上預約系統 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <TitleContainer>
        <TitleBar />
        <Title>探索商家招聘機會</Title>
      </TitleContainer>
      <JobGrid>
        {jobs.map((job) => (
          <JobItem
            key={job._id || job.businessId}
            onClick={() => handleJobClick(job)}
          >
            {job.hasApplied && (
              <AppliedTag>
                <MdCheckCircle /> 已申請
              </AppliedTag>
            )}
            {job.hasInteracted && (
              <InteractedTag>
                <MdCheckCircle /> 已互動
              </InteractedTag>
            )}
            <JobImage
              src={`${PHOTO_URL}${job.recruitmentItems[0]?.image}`}
              alt={job.title}
            />

            <Flex gap={2}>
              <JobType>{renderJobType(job)}</JobType>
              <AreaTag>{job.location?.city}</AreaTag>
              <AreaTag>{job.location?.district}</AreaTag>
            </Flex>
            <JobTitle>{job.title}</JobTitle>
            <JobInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                <circle cx='12' cy='10' r='3'></circle>
              </svg>
              {job.businessName}
            </JobInfo>
            <JobInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <rect x='3' y='4' width='18' height='18' rx='2' ry='2'></rect>
                <line x1='16' y1='2' x2='16' y2='6'></line>
                <line x1='8' y1='2' x2='8' y2='6'></line>
                <line x1='3' y1='10' x2='21' y2='10'></line>
              </svg>
              {moment(job.dateTimeRange?.start).format('MM/DD')} -{' '}
              {moment(job.dateTimeRange?.end).format('MM/DD')}
            </JobInfo>
            <JobSalary>
              <MdAttachMoney />
              {renderSalaryInfo(job)}
            </JobSalary>
          </JobItem>
        ))}
      </JobGrid>

      <Drawer
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        placement='right'
        width={window.innerWidth > 768 ? 500 : '100%'}
      >
        {selectedJob && (
          <DrawerContent>
            <DrawerTitle>{selectedJob.title}</DrawerTitle>
            <Flex gap={2}>
              <JobType>{renderJobType(selectedJob)}</JobType>
              <AreaTag>{selectedJob.location?.city}</AreaTag>
              <AreaTag>{selectedJob.location?.district}</AreaTag>
            </Flex>
            <DrawerInfo
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
              onClick={() => {
                window.open(
                  `https://beautefeel.com/store/${selectedJob.businessUsername}`,
                  '_blank'
                )
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                <circle cx='12' cy='10' r='3'></circle>
              </svg>
              {selectedJob.businessName}
            </DrawerInfo>
            <DrawerInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <rect x='3' y='4' width='18' height='18' rx='2' ry='2'></rect>
                <line x1='16' y1='2' x2='16' y2='6'></line>
                <line x1='8' y1='2' x2='8' y2='6'></line>
                <line x1='3' y1='10' x2='21' y2='10'></line>
              </svg>
              {moment(selectedJob.dateTimeRange?.start).format(
                'YYYY/MM/DD HH:mm'
              )}{' '}
              -
              {moment(selectedJob.dateTimeRange?.end).format(
                'YYYY/MM/DD HH:mm'
              )}
            </DrawerInfo>
            <DrawerInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <line x1='12' y1='1' x2='12' y2='23'></line>
                <path d='M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6'></path>
              </svg>
              {renderSalaryInfo(selectedJob)}
            </DrawerInfo>
            {selectedJob.recruitmentType === 'employee' && (
              <DrawerInfo>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <circle cx='12' cy='8' r='7'></circle>
                  <polyline points='8.21 13.89 7 23 12 20 17 23 15.79 13.88'></polyline>
                </svg>
                要求工作經驗: {selectedJob.experienceYears} 年
              </DrawerInfo>
            )}
            {selectedJob.recruitmentType === 'reciprocalModel' && (
              <DrawerInfo>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                  <polyline points='22 4 12 14.01 9 11.01'></polyline>
                </svg>
                互惠選項:{' '}
                {renderReciprocalOptions(selectedJob.reciprocalOptions)}
              </DrawerInfo>
            )}
            <DrawerInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>
                <polyline points='14 2 14 8 20 8'></polyline>
                <line x1='16' y1='13' x2='8' y2='13'></line>
                <line x1='16' y1='17' x2='8' y2='17'></line>
                <polyline points='10 9 9 9 8 9'></polyline>
              </svg>
              招聘項目: {renderRecruitmentItems(selectedJob.recruitmentItems)}
            </DrawerInfo>
            <DrawerDescription>{selectedJob.description}</DrawerDescription>
            <DrawerTitle
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  `https://beautefeel.com/store/${selectedJob.businessUsername}/portfolio`,
                  '_blank'
                )
              }}
            >
              店家作品集
            </DrawerTitle>
            <PortfolioGrid>
              {portfolios.map((portfolio) => (
                <PortfolioImage
                  key={portfolio._id}
                  src={`${PHOTO_URL}${portfolio.imageUrls[0]}`}
                  alt={portfolio.title}
                  onClick={() => {
                    window.open(
                      `https://beautefeel.com/store/${selectedJob.businessUsername}/portfolio`,
                      '_blank'
                    )
                  }}
                />
              ))}
            </PortfolioGrid>
            <ApplyButton
              type='primary'
              onClick={handleApply}
              disabled={selectedJob.hasApplied || selectedJob.hasInteracted}
            >
              {selectedJob.hasApplied
                ? '已申請'
                : selectedJob.hasInteracted
                ? '已互動'
                : '立即應徵'}
            </ApplyButton>
          </DrawerContent>
        )}
      </Drawer>
    </Container>
  )
}

export default HiringPage
