// utils.js

const isStoreOpen = (openingHours) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  const now = new Date()
  const currentDay = days[now.getDay()]
  const currentTime = `${now.getHours()}:${now.getMinutes()}`

  const dayHours = openingHours[currentDay]

  if (!dayHours || !dayHours.enabled) {
    return { isOpen: false, nextOpening: null }
  }

  for (let period of dayHours.periods) {
    if (currentTime >= period.start && currentTime <= period.end) {
      return { isOpen: true, todayHours: period }
    }
  }

  return { isOpen: false, todayHours: dayHours.periods[0] }
}

const translateDayToChinese = (day) => {
  const dayMap = {
    Sunday: '星期日',
    Monday: '星期一',
    Tuesday: '星期二',
    Wednesday: '星期三',
    Thursday: '星期四',
    Friday: '星期五',
    Saturday: '星期六'
  }
  return dayMap[day] || day
}

// const getRandomAvatar = () => {
//   return avatars[Math.floor(Math.random() * avatars.length)]
// }

// 配色方案
const colors = {
  primary: '#d5728a',
  secondary: '#F0F0F0',
  text: '#333333',
  background: '#FFFFFF',
  lightGrey: '#F0F0F0',
  darkGrey: '#333333',
  grey: '#666666',
  white: '#FFFFFF',

  // 新增颜色
  lightPink: '#F2B3C0',
  mediumPink: '#FF8A9D',
  darkPink: '#FF536F',
  palePink: '#FDDAE1',
  softPink: '#F9EBED',
  skinPink: '#F9ACA0',
  deepRed: '#E14A63',
  gold: '#C1A268',
  darkGold: '#AF863E',
  sloganText: '#9F8E93',
  deepMagenta: '#BF1C5F',
  orange: '#FFC061',
  coral: '#FAB3B0',
  brightPink: '#FF6B6B',
  paleRose: '#FBE2E7',
  paleYellow: '#FBFBE2'
}

const categories = [
  {
    icon: '/assets/medical.svg',
    name: '全部',
    type: 'all',
    label: '人員'
  },
  {
    icon: '/assets/nail.svg',
    name: '美甲',
    type: 'nail',
    label: '美甲設計師'
  },
  {
    icon: '/assets/cut.svg',
    name: '美髮',
    type: 'hair_care',
    label: '美髮造型師'
  },
  {
    icon: '/assets/face.svg',
    name: '美容',
    type: 'beauty_salon',
    label: '美容師'
  },
  {
    icon: '/assets/eyelash.svg',
    name: '美睫',
    type: 'eyelash',
    label: '美睫設計師'
  },
  {
    icon: '/assets/makeup.svg',
    name: '彩妝',
    type: 'makeup',
    label: '彩妝師'
  },
  // {
  //   icon: '/assets/tooth.svg',
  //   name: '牙齒美容',
  //   type: 'dental',
  //   label: '牙醫師'
  // },
  {
    icon: '/assets/eyebrown.svg',
    name: '紋繡',
    type: 'eyebrown',
    label: '紋繡設計師'
  },
  {
    icon: '/assets/shaving.svg',
    name: '除毛',
    type: 'shaving',
    label: '除毛操作人員'
  },
  {
    icon: '/assets/massage.svg',
    name: '按摩',
    type: 'spa',
    label: '按摩技術師'
  }
]

const categoriesList = [
  { name: '美甲', type: 'nail' },
  { name: '美髮', type: 'hair_care' },
  { name: '美睫', type: 'eyelash' },
  { name: '美容', type: 'beauty_salon' },
  { name: '彩妝', type: 'makeup' },
  { name: '牙齒美容', type: 'dental' },
  { name: '紋繡', type: 'eyebrown' },
  { name: '除毛', type: 'shaving' },
  { name: '按摩', type: 'spa' },
  { name: '其他', type: 'other' }
]

const tags = [
  { label: '熱門項目', color: '#F5DBDB' },
  { label: '預約專享', color: '#C6D3BE' },
  { label: '會員獨享', color: '#EACEC8' },
  { label: '新人優惠', color: '#E3D2CA' },
  { label: '獨家推薦', color: '#E79820' },
  { label: '團購專享', color: '#B1DDEE' },
  { label: '福利活動', color: '#FFB5D1' },
  { label: '公益活動', color: '#FF6969' },
  { label: 'APP活動', color: '#D7D2D2' }
]

const getTagColor = (tagLabel) => {
  const tag = tags.find((t) => t.label === tagLabel)
  return tag ? tag.color : '#777' // Default color if tag is not found
}

/**
 * 格式化数字，加入千位逗号
 * @param {number} num - 需要格式化的数字
 * @returns {string} - 格式化后的字符串
 */
export function formatNumberWithCommas(num) {
  if (typeof num !== 'number') {
    return num
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const getBookingTimes = (startTime, endTime, interval) => {
  const times = []
  const [startHour, startMinute] = startTime.split(':').map(Number)
  const [endHour, endMinute] = endTime.split(':').map(Number)
  let currentHour = startHour
  let currentMinute = startMinute

  while (
    currentHour < endHour ||
    (currentHour === endHour && currentMinute <= endMinute)
  ) {
    const time = `${String(currentHour).padStart(2, '0')}:${String(
      currentMinute
    ).padStart(2, '0')}`
    times.push(time)
    currentMinute += interval
    if (currentMinute >= 60) {
      currentHour += 1
      currentMinute -= 60
    }
  }

  return times
}

const cityOptions = [
  { value: '台北市', label: '台北市' },
  { value: '新北市', label: '新北市' }
]

const areaOptions = {
  台北市: [
    '中正區',
    '大同區',
    '中山區',
    '松山區',
    '大安區',
    '萬華區',
    '信義區',
    '士林區',
    '北投區',
    '內湖區',
    '南港區',
    '文山區'
  ],
  新北市: [
    '板橋區',
    '新莊區',
    '中和區',
    '永和區',
    '土城區',
    '樹林區',
    '三峽區',
    '三重區',
    '蘆洲區',
    '瑞芳區',
    '鶯歌區',
    '淡水區',
    '汐止區',
    '林口區',
    '五股區',
    '八里區',
    '泰山區'
  ]
  // 更多區域選項...
}

const cityMapping = {
  台北市: 'taipei',
  新北市: 'newtaipei'
}

const areaMapping = {
  taipei: {
    中正區: 'zhongzheng',
    大同區: 'datong',
    中山區: 'zhongshan',
    松山區: 'songshan',
    大安區: 'daan',
    萬華區: 'wanhua',
    信義區: 'xinyi',
    士林區: 'shilin',
    北投區: 'beitou',
    內湖區: 'neihu',
    南港區: 'nangang',
    文山區: 'wen-shan'
  },
  newtaipei: {
    板橋區: 'banqiao',
    新莊區: 'xinzhuang',
    中和區: 'zhonghe',
    永和區: 'yonghe',
    土城區: 'tucheng',
    樹林區: 'shulin',
    三峽區: 'sanxia',
    三重區: 'sanchong',
    蘆洲區: 'luzhou',
    瑞芳區: 'ruifang',
    鶯歌區: 'yingge',
    淡水區: 'tamsui',
    汐止區: 'xizhi',
    林口區: 'linkou',
    五股區: 'wugu',
    八里區: 'bali',
    泰山區: 'taishan'
  }
}

const generateTimeOptions = () => {
  const times = []
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hour = i.toString().padStart(2, '0')
      const minute = j.toString().padStart(2, '0')
      const time = `${hour}:${minute}`
      times.push(time)
    }
  }
  return times
}

const timeOptions = generateTimeOptions()
const TIMEZONE = 'Asia/Taipei'

const generateRandomUsername = () => {
  return `用户${Math.random().toString(36).substr(2, 8)}`
}

const translateCategoryToEnglish = (chineseName) => {
  const category = categoriesList.find((cat) => cat.name === chineseName)
  return category ? category.type : chineseName
}

const translateCategoryToChinese = (englishType) => {
  const category = categoriesList.find((cat) => cat.type === englishType)
  return category ? category.name : englishType
}

export {
  isStoreOpen,
  translateDayToChinese,
  categories,
  tags,
  colors,
  getTagColor,
  getBookingTimes,
  cityOptions,
  areaOptions,
  cityMapping,
  areaMapping,
  timeOptions,
  categoriesList,
  generateRandomUsername,
  translateCategoryToEnglish,
  translateCategoryToChinese
}
