import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/AuthContext'
import Loading from '../utils/Loading'

const ProtectedRoute = ({ children }) => {
  const { user, checkUserStatus } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    const verifyUser = async () => {
      await checkUserStatus()
      setIsLoading(false)
    }
    verifyUser()
  }, [checkUserStatus])

  if (isLoading) {
    return <Loading />
  }

  if (!user) {
    return <Navigate to='/login' state={{ from: location.pathname }} replace />
  }

  // 優先處理 LINE 用戶郵箱設置
  if (user.loginType === 'line' && !user.isVerified) {
    if (location.pathname !== '/line-user-email-setup') {
      return <Navigate to='/line-user-email-setup' replace />
    }
    return children
  }

  // 其次處理一般用戶郵箱驗證
  if (!user.isVerified && user.loginType !== 'line') {
    if (
      location.pathname !== '/check-email' &&
      location.pathname !== '/verify-email'
    ) {
      return <Navigate to='/check-email' replace />
    }
    return children
  }

  // 如果用戶已驗證，但嘗試訪問驗證相關頁面，重定向到首頁
  if (
    user.isVerified &&
    (location.pathname === '/check-email' ||
      location.pathname === '/verify-email')
  ) {
    return <Navigate to='/' replace />
  }

  return children
}

export default ProtectedRoute
