// 添加中文到英文的映射
export const cityNameMapping = {
  // 城市
  台北市: 'taipei',
  基隆市: 'keelung',
  新北市: 'new-taipei',
  宜蘭縣: 'yilan',
  桃園市: 'taoyuan',
  新竹市: 'hsinchu-city',
  新竹縣: 'hsinchu',
  苗栗縣: 'miaoli',
  台中市: 'taichung',
  彰化縣: 'changhua',
  南投縣: 'nantou',
  嘉義市: 'chiayi-city',
  嘉義縣: 'chiayi',
  雲林縣: 'yunlin',
  台南市: 'tainan',
  高雄市: 'kaohsiung',
  屏東縣: 'pingtung',
  台東縣: 'taitung',
  花蓮縣: 'hualien',
  all: 'all'
}

// 添加英文到中文的反向映射
export const cityNameMappingReverse = Object.entries(cityNameMapping).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key
  }),
  {}
)

// 添加區域的英文映射
export const districtNameMapping = {
  // 台北市
  中正區: 'zhongzheng',
  大同區: 'datong',
  中山區: 'zhongshan',
  松山區: 'songshan',
  大安區: 'daan',
  萬華區: 'wanhua',
  信義區: 'xinyi',
  士林區: 'shilin',
  北投區: 'beitou',
  內湖區: 'neihu',
  南港區: 'nangang',
  文山區: 'wenshan',

  // 新北市
  板橋區: 'banqiao',
  三重區: 'sanchong',
  中和區: 'zhonghe',
  永和區: 'yonghe',
  新莊區: 'xinzhuang',
  新店區: 'xindian',
  樹林區: 'shulin',
  鶯歌區: 'yingge',
  三峽區: 'sanxia',
  淡水區: 'tamsui',
  汐止區: 'xizhi',
  瑞芳區: 'ruifang',
  土城區: 'tucheng',
  蘆洲區: 'luzhou',
  五股區: 'wugu',
  泰山區: 'taishan',
  林口區: 'linkou',
  深坑區: 'shenkeng',
  石碇區: 'shiding',
  坪林區: 'pinglin',
  三芝區: 'sanzhi',
  石門區: 'shimen',
  八里區: 'bali',
  平溪區: 'pingxi',
  雙溪區: 'shuangxi',
  貢寮區: 'gongliao',
  金山區: 'jinshan',
  萬里區: 'wanli',
  烏來區: 'wulai',

  // 基隆市
  仁愛區: 'renai',
  信義區: 'xinyi',
  中正區: 'zhongzheng',
  中山區: 'zhongshan',
  安樂區: 'anle',
  暖暖區: 'nuannuan',
  七堵區: 'qidu',

  // 桃園市
  桃園區: 'taoyuan-district',
  中壢區: 'zhongli',
  平鎮區: 'pingzhen',
  八德區: 'bade',
  楊梅區: 'yangmei',
  蘆竹區: 'luzhu',
  大溪區: 'daxi',
  龜山區: 'guishan',
  龍潭區: 'longtan',
  大園區: 'dayuan',
  觀音區: 'guanyin',
  新屋區: 'xinwu',
  復興區: 'fuxing',

  // 新竹市
  東區: 'east',
  北區: 'north',
  香山區: 'xiangshan',

  // 新竹縣
  竹北市: 'zhubei',
  竹東鎮: 'zhudong',
  新埔鎮: 'xinpu',
  關西鎮: 'guanxi',
  湖口鄉: 'hukou',
  新豐鄉: 'xinfeng',
  芎林鄉: 'qionglin',
  橫山鄉: 'hengshan',
  北埔鄉: 'beipu',
  寶山鄉: 'baoshan',
  峨眉鄉: 'emei',
  尖石鄉: 'jianshi',
  五峰鄉: 'wufeng',

  // 苗栗縣
  苗栗市: 'miaoli-city',
  頭份市: 'toufen',
  竹南鎮: 'zhunan',
  後龍鎮: 'houlong',
  通霄鎮: 'tongxiao',
  苑裡鎮: 'yuanli',
  卓蘭鎮: 'zhuolan',
  造橋鄉: 'zaoqiao',
  西湖鄉: 'xihu',
  頭屋鄉: 'touwu',
  公館鄉: 'gongguan',
  銅鑼鄉: 'tongluo',
  三義鄉: 'sanyi',
  大湖鄉: 'dahu',
  獅潭鄉: 'shitan',
  泰安鄉: 'taian',
  南庄鄉: 'nanzhuang',
  三灣鄉: 'sanwan',

  // 台中市
  中區: 'central',
  東區: 'east',
  南區: 'south',
  西區: 'west',
  北區: 'north',
  北屯區: 'beitun',
  西屯區: 'xitun',
  南屯區: 'nantun',
  太平區: 'taiping',
  大里區: 'dali',
  霧峰區: 'wufeng',
  烏日區: 'wuri',
  豐原區: 'fengyuan',
  后里區: 'houli',
  石岡區: 'shigang',
  東勢區: 'dongshi',
  和平區: 'heping',
  新社區: 'xinshe',
  潭子區: 'tanzi',
  大雅區: 'daya',
  神岡區: 'shengang',
  大肚區: 'dadu',
  沙鹿區: 'shalu',
  龍井區: 'longjing',
  梧棲區: 'wuqi',
  清水區: 'qingshui',
  大甲區: 'dajia',
  外埔區: 'waipu',
  大安區: 'daan',

  // 彰化縣
  彰化市: 'changhua-city',
  員林市: 'yuanlin',
  和美鎮: 'hemei',
  鹿港鎮: 'lugang',
  溪湖鎮: 'xihu',
  二林鎮: 'erlin',
  田中鎮: 'tianzhong',
  北斗鎮: 'beidou',
  花壇鄉: 'huatan',
  芬園鄉: 'fenyuan',
  大村鄉: 'dacun',
  永靖鄉: 'yongjing',
  伸港鄉: 'shengang',
  線西鄉: 'xianxi',
  福興鄉: 'fuxing',
  秀水鄉: 'xiushui',
  埔心鄉: 'puxin',
  埔鹽鄉: 'puyan',
  大城鄉: 'dacheng',
  芳苑鄉: 'fangyuan',
  竹塘鄉: 'zhutang',
  社頭鄉: 'shetou',
  二水鄉: 'ershui',
  田尾鄉: 'tianwei',
  埤頭鄉: 'pitou',
  溪州鄉: 'xizhou',

  // 南投縣
  南投市: 'nantou-city',
  埔里鎮: 'puli',
  草屯鎮: 'caotun',
  竹山鎮: 'zhushan',
  集集鎮: 'jiji',
  名間鄉: 'mingjian',
  鹿谷鄉: 'lugu',
  中寮鄉: 'zhongliao',
  魚池鄉: 'yuchi',
  國姓鄉: 'guoxing',
  水里鄉: 'shuili',
  信義鄉: 'xinyi',
  仁愛鄉: 'renai',

  // 雲林縣
  斗六市: 'douliu',
  斗南鎮: 'dounan',
  虎尾鎮: 'huwei',
  西螺鎮: 'xiluo',
  土庫鎮: 'tuku',
  北港鎮: 'beigang',
  古坑鄉: 'gukeng',
  大埤鄉: 'dapi',
  莿桐鄉: 'citong',
  林內鄉: 'linnei',
  二崙鄉: 'erlun',
  崙背鄉: 'lunbei',
  麥寮鄉: 'mailiao',
  東勢鄉: 'dongshi',
  褒忠鄉: 'baozhong',
  台西鄉: 'taixi',
  元長鄉: 'yuanchang',
  四湖鄉: 'sihu',
  口湖鄉: 'kouhu',
  水林鄉: 'shuilin',

  // 嘉義市
  東區: 'east',
  西區: 'west',

  // 嘉義縣
  太保市: 'taibao',
  朴子市: 'puzi',
  布袋鎮: 'budai',
  大林鎮: 'dalin',
  民雄鄉: 'minxiong',
  溪口鄉: 'xikou',
  新港鄉: 'xingang',
  六腳鄉: 'liujiao',
  東石鄉: 'dongshi',
  義竹鄉: 'yizhu',
  鹿草鄉: 'lucao',
  水上鄉: 'shuishang',
  中埔鄉: 'zhongpu',
  竹崎鄉: 'zhuqi',
  梅山鄉: 'meishan',
  番路鄉: 'fanlu',
  大埔鄉: 'dapu',
  阿里山鄉: 'alishan',

  // 台南市
  中西區: 'zhongxi',
  東區: 'east',
  南區: 'south',
  北區: 'north',
  安平區: 'anping',
  安南區: 'annan',
  永康區: 'yongkang',
  歸仁區: 'guiren',
  新化區: 'xinhua',
  左鎮區: 'zuozhen',
  玉井區: 'yujing',
  楠西區: 'nanxi',
  南化區: 'nanhua',
  仁德區: 'rende',
  關廟區: 'guanmiao',
  龍崎區: 'longqi',
  官田區: 'guantian',
  麻豆區: 'madou',
  佳里區: 'jiali',
  西港區: 'xigang',
  七股區: 'qigu',
  將軍區: 'jiangjun',
  學甲區: 'xuejia',
  北門區: 'beimen',
  新營區: 'xinying',
  後壁區: 'houbi',
  白河區: 'baihe',
  東山區: 'dongshan',
  六甲區: 'liujia',
  下營區: 'xiaying',
  柳營區: 'liuying',
  鹽水區: 'yanshui',
  善化區: 'shanhua',
  大內區: 'danei',
  山上區: 'shanshang',
  新市區: 'xinshi',
  安定區: 'anding',

  // 高雄市
  楠梓區: 'nanzi',
  左營區: 'zuoying',
  鼓山區: 'gushan',
  三民區: 'sanmin',
  鹽埕區: 'yancheng',
  前金區: 'qianjin',
  新興區: 'xinxing',
  苓雅區: 'lingya',
  前鎮區: 'qianzhen',
  旗津區: 'qijin',
  小港區: 'xiaogang',
  鳳山區: 'fengshan',
  大寮區: 'daliao',
  鳥松區: 'niaosong',
  林園區: 'linyuan',
  仁武區: 'renwu',
  大樹區: 'dashu',
  大社區: 'dashe',
  岡山區: 'gangshan',
  路竹區: 'luzhu',
  橋頭區: 'qiaotou',
  梓官區: 'ziguan',
  彌陀區: 'mituo',
  永安區: 'yongan',
  燕巢區: 'yanchao',
  田寮區: 'tianliao',
  阿蓮區: 'alian',
  茄萣區: 'qieding',
  湖內區: 'hunei',
  旗山區: 'qishan',
  美濃區: 'meinong',
  內門區: 'namen',
  杉林區: 'shanlin',
  甲仙區: 'jiaxian',
  六龜區: 'liugui',
  茂林區: 'maolin',
  桃源區: 'taoyuan',
  那瑪夏區: 'namaxia',

  // 屏東縣
  屏東市: 'pingtung-city',
  潮州鎮: 'chaozhou',
  東港鎮: 'donggang',
  恆春鎮: 'hengchun',
  萬丹鄉: 'wandan',
  長治鄉: 'changzhi',
  麟洛鄉: 'linluo',
  九如鄉: 'jiuru',
  里港鄉: 'ligang',
  鹽埔鄉: 'yanpu',
  高樹鄉: 'gaoshu',
  萬巒鄉: 'wanluan',
  內埔鄉: 'neipu',
  竹田鄉: 'zhutian',
  新埤鄉: 'xinpi',
  枋寮鄉: 'fangliao',
  新園鄉: 'xinyuan',
  崁頂鄉: 'kanding',
  林邊鄉: 'linbian',
  南州鄉: 'nanzhou',
  佳冬鄉: 'jiadong',
  琉球鄉: 'liuqiu',
  車城鄉: 'checheng',
  滿州鄉: 'manzhou',
  枋山鄉: 'fangshan',
  三地門鄉: 'sandimen',
  霧台鄉: 'wutai',
  瑪家鄉: 'majia',
  泰武鄉: 'taiwu',
  來義鄉: 'laiyi',
  春日鄉: 'chunri',
  獅子鄉: 'shizi',
  牡丹鄉: 'mudan',

  // 台東縣
  台東市: 'taitung-city',
  成功鎮: 'chenggong',
  關山鎮: 'guanshan',
  卑南鄉: 'beinan',
  鹿野鄉: 'luye',
  池上鄉: 'chishang',
  東河鄉: 'donghe',
  長濱鄉: 'changbin',
  太麻里鄉: 'taimali',
  大武鄉: 'dawu',
  綠島鄉: 'ludao',
  蘭嶼鄉: 'lanyu',
  海端鄉: 'haiduan',
  延平鄉: 'yanping',
  金峰鄉: 'jinfeng',
  達仁鄉: 'daren',

  // 花蓮縣
  花蓮市: 'hualien-city',
  鳳林鎮: 'fenglin',
  玉里鎮: 'yuli',
  新城鄉: 'xincheng',
  吉安鄉: 'jian',
  壽豐鄉: 'shoufeng',
  光復鄉: 'guangfu',
  豐濱鄉: 'fengbin',
  瑞穗鄉: 'ruisui',
  富里鄉: 'fuli',
  秀林鄉: 'xiulin',
  萬榮鄉: 'wanrong',
  卓溪鄉: 'zhuoxi',

  // 宜蘭縣
  宜蘭市: 'yilan-city',
  羅東鎮: 'luodong',
  蘇澳鎮: 'suao',
  頭城鎮: 'toucheng',
  礁溪鄉: 'jiaoxi',
  壯圍鄉: 'zhuangwei',
  員山鄉: 'yuanshan',
  冬山鄉: 'dongshan',
  五結鄉: 'wujie',
  三星鄉: 'sanxing',
  大同鄉: 'datong',
  南澳鄉: 'nanao',

  // 澎湖縣
  馬公市: 'magong',
  湖西鄉: 'huxi',
  白沙鄉: 'baisha',
  西嶼鄉: 'xiyu',
  望安鄉: 'wangan',
  七美鄉: 'qimei',

  // 金門縣
  金城鎮: 'jincheng',
  金湖鎮: 'jinhu',
  金沙鎮: 'jinsha',
  金寧鄉: 'jinning',
  烈嶼鄉: 'lieyu',
  烏坵鄉: 'wuqiu',

  // 連江縣
  南竿鄉: 'nangan',
  北竿鄉: 'beigan',
  莒光鄉: 'juguang',
  東引鄉: 'dongyin'
}

// 添加區域英文到中文的反向映射
export const districtNameMappingReverse = Object.entries(
  districtNameMapping
).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key
  }),
  {}
)

// 將城市名稱轉換為英文代碼
export const convertToEngName = (chineseName) => {
  return cityNameMapping[chineseName] || chineseName
}

// 將英文代碼轉換為中文名稱
export const convertToChineseName = (engName) => {
  // 先檢查是否為特殊值
  if (engName === 'all-city' || engName === 'all-district') return 'all'

  // 先嘗試從城市映射中查找
  const chineseCity = cityNameMappingReverse[engName]
  if (chineseCity) return chineseCity

  // 如果不是城市，則從區域映射中查找
  const chineseDistrict = districtNameMappingReverse[engName]
  if (chineseDistrict) return chineseDistrict

  // 如果都找不到，返回原值
  return engName
}

// 將英文地址轉換回中文
export const convertEngToFullAddress = (engAddress) => {
  if (!engAddress || engAddress === 'all-city') return 'all'

  try {
    // 先找出完整的城市名稱
    const engCity = Object.values(cityNameMapping).find((value) =>
      engAddress.startsWith(value)
    )
    if (!engCity) return 'all'

    const chineseCity = cityNameMappingReverse[engCity]

    // 獲取區域部分
    const engDistrict = engAddress.slice(engCity.length + 1) // +1 for the hyphen
    if (!engDistrict || engDistrict === 'all-district') return chineseCity

    // 找到對應的中文區域名稱
    const districtKey = Object.entries(districtNameMapping).find(
      ([, value]) => value === engDistrict
    )?.[0]

    return districtKey ? `${chineseCity}${districtKey}` : chineseCity
  } catch (error) {
    console.error('Error converting address:', error)
    return 'all'
  }
}
// 將完整地址轉換為英文（城市+區域）
export const convertFullAddressToEng = (address) => {
  if (!address || address === 'all') return 'all'

  try {
    // 檢查是否包含區域
    for (const [chineseCity, engCity] of Object.entries(cityNameMapping)) {
      if (address.startsWith(chineseCity)) {
        const district = address.slice(chineseCity.length)
        if (district) {
          const engDistrict = districtNameMapping[district]
          return engDistrict ? `${engCity}-${engDistrict}` : engCity
        }
        return engCity
      }
    }
    return 'all'
  } catch (error) {
    console.error('Error converting address:', error)
    return 'all'
  }
}

const cityData = {
  counties: [
    '台北市',
    '基隆市',
    '新北市',
    '宜蘭縣',
    '桃園市',
    '新竹市',
    '新竹縣',
    '苗栗縣',
    '台中市',
    '彰化縣',
    '南投縣',
    '嘉義市',
    '嘉義縣',
    '雲林縣',
    '台南市',
    '高雄市',
    // '澎湖縣',
    // '金門縣',
    '屏東縣',
    '台東縣',
    '花蓮縣'
    // '連江縣'
  ],
  districts: [
    // 台北市
    {
      name: '台北市',
      areas: [
        '中正區',
        '大同區',
        '中山區',
        '松山區',
        '大安區',
        '萬華區',
        '信義區',
        '士林區',
        '北投區',
        '內湖區',
        '南港區',
        '文山區'
      ],
      zipCodes: [
        '100',
        '103',
        '104',
        '105',
        '106',
        '108',
        '110',
        '111',
        '112',
        '114',
        '115',
        '116'
      ]
    },
    // 基隆市
    {
      name: '基隆市',
      areas: [
        '仁愛區',
        '信義區',
        '中正區',
        '中山區',
        '安樂區',
        '暖暖區',
        '七堵區'
      ],
      zipCodes: ['200', '201', '202', '203', '204', '205', '206']
    },
    // 新北市
    {
      name: '新北市',
      areas: [
        '萬里區',
        '金山區',
        '板橋區',
        '汐止區',
        '深坑區',
        '石碇區',
        '瑞芳區',
        '平溪區',
        '雙溪區',
        '貢寮區',
        '新店區',
        '坪林區',
        '烏來區',
        '永和區',
        '中和區',
        '土城區',
        '三峽區',
        '樹林區',
        '鶯歌區',
        '三重區',
        '新莊區',
        '泰山區',
        '林口區',
        '蘆洲區',
        '五股區',
        '八里區',
        '淡水區',
        '三芝區',
        '石門區'
      ],
      zipCodes: [
        '207',
        '208',
        '220',
        '221',
        '222',
        '223',
        '224',
        '226',
        '227',
        '228',
        '231',
        '232',
        '233',
        '234',
        '235',
        '236',
        '237',
        '238',
        '239',
        '241',
        '242',
        '243',
        '244',
        '247',
        '248',
        '249',
        '251',
        '252',
        '253'
      ]
    },
    // 宜蘭縣
    {
      name: '宜蘭縣',
      areas: [
        '宜蘭市',
        '頭城鎮',
        '礁溪鄉',
        '壯圍鄉',
        '員山鄉',
        '羅東鎮',
        '三星鄉',
        '大同鄉',
        '五結鄉',
        '冬山鄉',
        '蘇澳鎮',
        '南澳鄉',
        '釣魚台列嶼'
      ],
      zipCodes: [
        '260',
        '261',
        '262',
        '263',
        '264',
        '265',
        '266',
        '267',
        '268',
        '269',
        '270',
        '272',
        '290'
      ]
    },
    // 桃園市
    {
      name: '桃園市',
      areas: [
        '中壢區',
        '平鎮區',
        '龍潭區',
        '楊梅區',
        '新屋區',
        '觀音區',
        '桃園區',
        '龜山區',
        '八德區',
        '大溪區',
        '復興區',
        '大園區',
        '蘆竹區'
      ],
      zipCodes: [
        '320',
        '324',
        '325',
        '326',
        '327',
        '328',
        '330',
        '333',
        '334',
        '335',
        '336',
        '337',
        '338'
      ]
    },
    // 新竹市
    {
      name: '新竹市',
      areas: ['東區', '北區', '香山區'],
      zipCodes: ['300', '300', '300']
    },
    // 新竹縣
    {
      name: '新竹縣',
      areas: [
        '竹北市',
        '湖口鄉',
        '新豐鄉',
        '新埔鎮',
        '關西鎮',
        '芎林鄉',
        '寶山鄉',
        '竹東鎮',
        '五峰鄉',
        '橫山鄉',
        '尖石鄉',
        '北埔鄉',
        '峨眉鄉'
      ],
      zipCodes: [
        '302',
        '303',
        '304',
        '305',
        '306',
        '307',
        '308',
        '310',
        '311',
        '312',
        '313',
        '314',
        '315'
      ]
    },
    // 苗栗縣
    {
      name: '苗栗縣',
      areas: [
        '竹南鎮',
        '頭份市',
        '三灣鄉',
        '南庄鄉',
        '獅潭鄉',
        '後龍鎮',
        '通霄鎮',
        '苑裡鎮',
        '苗栗市',
        '造橋鄉',
        '頭屋鄉',
        '公館鄉',
        '大湖鄉',
        '泰安鄉',
        '銅鑼鄉',
        '三義鄉',
        '西湖鄉',
        '卓蘭鎮'
      ],
      zipCodes: [
        '350',
        '351',
        '352',
        '353',
        '354',
        '356',
        '357',
        '358',
        '360',
        '361',
        '362',
        '363',
        '364',
        '365',
        '366',
        '367',
        '368',
        '369'
      ]
    },
    // 台中市
    {
      name: '台中市',
      areas: [
        '中區',
        '東區',
        '南區',
        '西區',
        '北區',
        '北屯區',
        '西屯區',
        '南屯區',
        '太平區',
        '大里區',
        '霧峰區',
        '烏日區',
        '豐原區',
        '后里區',
        '石岡區',
        '東勢區',
        '和平區',
        '新社區',
        '潭子區',
        '大雅區',
        '神岡區',
        '大肚區',
        '沙鹿區',
        '龍井區',
        '梧棲區',
        '清水區',
        '大甲區',
        '外埔區',
        '大安區'
      ],
      zipCodes: [
        '400',
        '401',
        '402',
        '403',
        '404',
        '406',
        '407',
        '408',
        '411',
        '412',
        '413',
        '414',
        '420',
        '421',
        '422',
        '423',
        '424',
        '426',
        '427',
        '428',
        '429',
        '432',
        '433',
        '434',
        '435',
        '436',
        '437',
        '438',
        '439'
      ]
    },
    // 彰化縣
    {
      name: '彰化縣',
      areas: [
        '彰化市',
        '芬園鄉',
        '花壇鄉',
        '秀水鄉',
        '鹿港鎮',
        '福興鄉',
        '線西鄉',
        '和美鎮',
        '伸港鄉',
        '員林市',
        '社頭鄉',
        '永靖鄉',
        '埔心鄉',
        '溪湖鎮',
        '大村鄉',
        '埔鹽鄉',
        '田中鎮',
        '北斗鎮',
        '田尾鄉',
        '埤頭鄉',
        '溪州鄉',
        '竹塘鄉',
        '二林鎮',
        '大城鄉',
        '芳苑鄉',
        '二水鄉'
      ],
      zipCodes: [
        '500',
        '502',
        '503',
        '504',
        '505',
        '506',
        '507',
        '508',
        '509',
        '510',
        '511',
        '512',
        '513',
        '514',
        '515',
        '516',
        '520',
        '521',
        '522',
        '523',
        '524',
        '525',
        '526',
        '527',
        '528',
        '530'
      ]
    },
    // 南投縣
    {
      name: '南投縣',
      areas: [
        '南投市',
        '中寮鄉',
        '草屯鎮',
        '國姓鄉',
        '埔里鎮',
        '仁愛鄉',
        '名間鄉',
        '集集鎮',
        '水里鄉',
        '魚池鄉',
        '信義鄉',
        '竹山鎮',
        '鹿谷鄉'
      ],
      zipCodes: [
        '540',
        '541',
        '542',
        '544',
        '545',
        '546',
        '551',
        '552',
        '553',
        '555',
        '556',
        '557',
        '558'
      ]
    },
    // 嘉義市
    {
      name: '嘉義市',
      areas: ['東區', '西區'],
      zipCodes: ['600', '600']
    },
    // 嘉義縣
    {
      name: '嘉義縣',
      areas: [
        '番路鄉',
        '梅山鄉',
        '竹崎鄉',
        '阿里山',
        '中埔鄉',
        '大埔鄉',
        '水上鄉',
        '鹿草鄉',
        '太保市',
        '朴子市',
        '東石鄉',
        '六腳鄉',
        '新港鄉',
        '民雄鄉',
        '大林鎮',
        '溪口鄉',
        '義竹鄉',
        '布袋鎮'
      ],
      zipCodes: [
        '602',
        '603',
        '604',
        '605',
        '606',
        '607',
        '608',
        '611',
        '612',
        '613',
        '614',
        '615',
        '616',
        '621',
        '622',
        '623',
        '624',
        '625'
      ]
    },
    // 雲林縣
    {
      name: '雲林縣',
      areas: [
        '斗南鎮',
        '大埤鄉',
        '虎尾鎮',
        '土庫鎮',
        '褒忠鄉',
        '東勢鄉',
        '台西鄉',
        '崙背鄉',
        '麥寮鄉',
        '斗六市',
        '林內鄉',
        '古坑鄉',
        '莿桐鄉',
        '西螺鎮',
        '二崙鄉',
        '北港鎮',
        '水林鄉',
        '口湖鄉',
        '四湖鄉',
        '元長鄉'
      ],
      zipCodes: [
        '630',
        '631',
        '632',
        '633',
        '634',
        '635',
        '636',
        '637',
        '638',
        '640',
        '643',
        '646',
        '647',
        '648',
        '649',
        '651',
        '652',
        '653',
        '654',
        '655'
      ]
    },
    // 台南市
    {
      name: '台南市',
      areas: [
        '中西區',
        '東區',
        '南區',
        '北區',
        '安平區',
        '安南區',
        '永康區',
        '歸仁區',
        '新化區',
        '左鎮區',
        '玉井區',
        '楠西區',
        '南化區',
        '仁德區',
        '關廟區',
        '龍崎區',
        '官田區',
        '麻豆區',
        '佳里區',
        '西港區',
        '七股區',
        '將軍區',
        '學甲區',
        '北門區',
        '新營區',
        '後壁區',
        '白河區',
        '東山區',
        '六甲區',
        '下營區',
        '柳營區',
        '鹽水區',
        '善化區',
        '大內區',
        '山上區',
        '新市區',
        '安定區'
      ],
      zipCodes: [
        '700',
        '701',
        '702',
        '704',
        '708',
        '709',
        '710',
        '711',
        '712',
        '713',
        '714',
        '715',
        '716',
        '717',
        '718',
        '719',
        '720',
        '721',
        '722',
        '723',
        '724',
        '725',
        '726',
        '727',
        '730',
        '731',
        '732',
        '733',
        '734',
        '735',
        '736',
        '737',
        '741',
        '742',
        '743',
        '744',
        '745'
      ]
    },
    // 高雄市
    {
      name: '高雄市',
      areas: [
        '新興區',
        '前金區',
        '苓雅區',
        '鹽埕區',
        '鼓山區',
        '旗津區',
        '前鎮區',
        '三民區',
        '楠梓區',
        '小港區',
        '左營區',
        '仁武區',
        '大社區',
        '東沙群島',
        '南沙群島',
        '岡山區',
        '路竹區',
        '阿蓮區',
        '田寮區',
        '燕巢區',
        '橋頭區',
        '梓官區',
        '彌陀區',
        '永安區',
        '湖內區',
        '鳳山區',
        '大寮區',
        '林園區',
        '鳥松區',
        '大樹區',
        '旗山區',
        '美濃區',
        '六龜區',
        '內門區',
        '杉林區',
        '甲仙區',
        '桃源區',
        '那瑪夏區',
        '茂林區',
        '茄萣區'
      ],
      zipCodes: [
        '800',
        '801',
        '802',
        '803',
        '804',
        '805',
        '806',
        '807',
        '811',
        '812',
        '813',
        '814',
        '815',
        '817',
        '819',
        '820',
        '821',
        '822',
        '823',
        '824',
        '825',
        '826',
        '827',
        '828',
        '829',
        '830',
        '831',
        '832',
        '833',
        '840',
        '842',
        '843',
        '844',
        '845',
        '846',
        '847',
        '848',
        '849',
        '851',
        '852'
      ]
    },
    // 澎湖縣
    {
      name: '澎湖縣',
      areas: ['馬公市', '西嶼鄉', '望安鄉', '七美鄉', '白沙鄉', '湖西鄉'],
      zipCodes: ['880', '881', '882', '883', '884', '885']
    },
    // 金門縣
    {
      name: '金門縣',
      areas: ['金沙鎮', '金湖鎮', '金寧鄉', '金城鎮', '烈嶼鄉', '烏坵鄉'],
      zipCodes: ['890', '891', '892', '893', '894', '896']
    },
    // 屏東縣
    {
      name: '屏東縣',
      areas: [
        '屏東市',
        '三地門鄉',
        '霧台鄉',
        '瑪家鄉',
        '九如鄉',
        '里港鄉',
        '高樹鄉',
        '鹽埔鄉',
        '長治鄉',
        '麟洛鄉',
        '竹田鄉',
        '內埔鄉',
        '萬丹鄉',
        '潮州鎮',
        '泰武鄉',
        '來義鄉',
        '萬巒鄉',
        '崁頂鄉',
        '新埤鄉',
        '南州鄉',
        '林邊鄉',
        '東港鎮',
        '琉球鄉',
        '佳冬鄉',
        '新園鄉',
        '枋寮鄉',
        '枋山鄉',
        '春日鄉',
        '獅子鄉',
        '車城鄉',
        '牡丹鄉',
        '恆春鎮',
        '滿州鄉'
      ],
      zipCodes: [
        '900',
        '901',
        '902',
        '903',
        '904',
        '905',
        '906',
        '907',
        '908',
        '909',
        '911',
        '912',
        '913',
        '920',
        '921',
        '922',
        '923',
        '924',
        '925',
        '926',
        '927',
        '928',
        '929',
        '931',
        '932',
        '940',
        '941',
        '942',
        '943',
        '944',
        '945',
        '946',
        '947'
      ]
    },
    // 台東縣
    {
      name: '台東縣',
      areas: [
        '台東市',
        '綠島鄉',
        '蘭嶼鄉',
        '延平鄉',
        '卑南鄉',
        '鹿野鄉',
        '關山鎮',
        '海端鄉',
        '池上鄉',
        '東河鄉',
        '成功鎮',
        '長濱鄉',
        '太麻里',
        '金峰鄉',
        '大武鄉',
        '達仁鄉'
      ],
      zipCodes: [
        '950',
        '951',
        '952',
        '953',
        '954',
        '955',
        '956',
        '957',
        '958',
        '959',
        '961',
        '962',
        '963',
        '964',
        '965',
        '966'
      ]
    },
    // 花蓮縣
    {
      name: '花蓮縣',
      areas: [
        '花蓮市',
        '新城鄉',
        '秀林鄉',
        '吉安鄉',
        '壽豐鄉',
        '鳳林鎮',
        '光復鄉',
        '豐濱鄉',
        '瑞穗鄉',
        '萬榮鄉',
        '玉里鎮',
        '卓溪鄉',
        '富里鄉'
      ],
      zipCodes: [
        '970',
        '971',
        '972',
        '973',
        '974',
        '975',
        '976',
        '977',
        '978',
        '979',
        '981',
        '982',
        '983'
      ]
    },
    // 連江縣
    {
      name: '連江縣',
      areas: ['南竿鄉', '北竿鄉', '莒光鄉', '引鄉'],
      zipCodes: ['209', '210', '211', '212']
    }
  ]
}

export default cityData

// 使用示例:
// import cityData from './cityData';
// console.log(cityData.counties);
// console.log(cityData.districts[0].name);
// console.log(cityData.districts[0].areas);
// console.log(cityData.districts[0].zipCodes);
