import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { PHOTO_URL, API_BASE_URL } from '../utils/apiutils'
import { Tabs, Input, Button } from 'antd'
import { colors } from '../utils/utils'
import Loading from '../utils/Loading'
import NotFoundPage from './NotFoundPage'
import { Helmet } from 'react-helmet-async'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  animation: ${fadeIn} 0.5s ease-in;

  @media (max-width: 820px) {
    padding: 20px 10px;
  }
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 40px;
`

const Title = styled.h1`
  color: ${colors.primary};
  font-size: 30px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 820px) {
    font-size: 24px;
  }
`

const SearchInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: ${colors.darkGrey};
`

const ResultCount = styled.span`
  font-weight: bold;
  color: ${colors.primary};
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`

const SearchInput = styled(Input)`
  flex-grow: 1;
  margin-right: 15px;
  border-radius: 25px;
  font-size: 16px;

  &:focus {
    box-shadow: 0 0 0 2px rgba(213, 114, 138, 0.2);
  }
`

const SearchButton = styled(Button)`
  background-color: ${colors.primary};
  border-color: ${colors.primary};
  border-radius: 25px;
  font-size: 16px;
  height: 40px;
  color: white;

  &:hover,
  &:focus {
    background-color: ${colors.primaryDark};
    border-color: ${colors.primaryDark};
  }
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav::before {
    border-bottom: 2px solid ${colors.lightGrey};
  }

  .ant-tabs-tab {
    font-size: 16px;
    padding: 12px 0;
  }

  .ant-tabs-tab-active {
    font-weight: bold;
  }

  .ant-tabs-ink-bar {
    background-color: ${colors.primary};
  }
`

const ResultGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
`

const ResultCard = styled.div`
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
`

const CardContent = styled.div`
  padding: 20px;
`

const CardTitle = styled.h3`
  margin: 0 0 10px 0;
  color: ${colors.darkGrey};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
`

const CardInfo = styled.p`
  margin: 5px 0;
  color: ${colors.grey};
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
`

const Price = styled.div`
  font-weight: bold;
  color: ${colors.primary};
  margin-top: 10px;
  font-size: 18px;
`

const TimeSlotContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  cursor: pointer;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const TimeSlotGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 5px;
  margin-top: 10px;
  padding-bottom: 10px;
`

const TimeSlot = styled.div`
  background-color: ${colors.lightGrey};
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`

const StylistItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${colors.lightGrey};
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: ${colors.lightGrey};
  }
`

const StylistImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
`

const StylistContent = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const StylistName = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: ${colors.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const StylistDescription = styled.p`
  font-size: 14px;
  color: ${colors.grey};
  margin: 0;
`

const SearchAllResultsPage = () => {
  const location = useLocation()
  const [searchResults, setSearchResults] = useState({
    businesses: [],
    designers: [],
    products: []
  })
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [activeTab, setActiveTab] = useState('businesses')
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState(location.state?.keyword || '')
  const [searchedKeyword, setSearchedKeyword] = useState(
    location.state?.keyword || ''
  )

  const handleCardClickBusiness = (username) => {
    navigate(`/store/${username}`)
  }

  const handleCardClickProduct = (businessUsername, productId) => {
    navigate(`/booking/${businessUsername}/${productId}`)
  }

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true)
      try {
        let results
        if (location.state && location.state.results) {
          results = location.state.results
        } else if (location.state && location.state.keyword) {
          const response = await axios.get(`${API_BASE_URL}/api/search/all`, {
            params: { keyword: location.state.keyword }
          })
          results = response.data
        } else {
          throw new Error('沒有搜索結果或關鍵字')
        }

        setSearchResults({
          businesses: Array.isArray(results.businesses)
            ? results.businesses
            : [],
          designers: Array.isArray(results.designers) ? results.designers : [],
          products: Array.isArray(results.products) ? results.products : []
        })
      } catch (err) {
        setError('獲取搜索結果時出錯: ' + err.message)
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchResults()
  }, [location])

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/search/all`, {
        params: { keyword }
      })
      setSearchResults(response.data)
      setSearchedKeyword(keyword) // 更新已搜索的关键词
    } catch (error) {
      console.error('搜索失敗:', error)
      // 這裡可以添加錯誤處理,比如顯示一個錯誤提示
    }
  }

  const renderBusinessCard = (business) => (
    <ResultCard key={business._id}>
      <CardImage
        src={
          business.photos && business.photos.length > 0
            ? `${PHOTO_URL}${business.photos[0].url}`
            : '/assets/business_photo.png'
        }
        alt={business.name}
        onClick={() => handleCardClickBusiness(business.username)}
      />
      <CardContent>
        <CardTitle onClick={() => handleCardClickBusiness(business.username)}>
          {business.name}
        </CardTitle>
        <CardInfo>
          <EnvironmentOutlined /> {business.address}
        </CardInfo>
        <CardInfo>{business.description}</CardInfo>
        <CardInfo>{business.reviewCount} 則評論</CardInfo>
        {business.availableTimes && business.availableTimes.length > 0 && (
          <>
            <CardInfo>
              <ClockCircleOutlined /> 現在可預約時間
            </CardInfo>
            <TimeSlotContainer>
              <TimeSlotGrid
                onClick={() => handleCardClickBusiness(business.username)}
              >
                {business.availableTimes.map((time, index) => (
                  <TimeSlot key={index}>{time}</TimeSlot>
                ))}
              </TimeSlotGrid>
            </TimeSlotContainer>
          </>
        )}
      </CardContent>
    </ResultCard>
  )

  const renderDesignerCard = (designer) => (
    <StylistItem to={`/${designer.username}`} key={designer._id}>
      <StylistImage
        src={`${PHOTO_URL}${designer.avatar}`}
        alt={designer.username}
      />
      <StylistContent>
        <StylistName>{designer.name || designer.username}</StylistName>
        <StylistDescription>{designer.description}</StylistDescription>
      </StylistContent>
    </StylistItem>
  )

  const renderProductCard = (product) => (
    <ResultCard
      key={product._id}
      onClick={() =>
        handleCardClickProduct(product.businessUsername, product._id)
      }
      style={{ cursor: 'pointer' }}
    >
      <CardImage src={`${PHOTO_URL}${product.image}`} alt={product.name} />
      <CardContent>
        <CardTitle>{product.name}</CardTitle>
        <CardInfo>{product.description}</CardInfo>
        <Price>${product.price}</Price>
        {product.availableTimes && product.availableTimes.length > 0 && (
          <>
            <CardInfo>
              <ClockCircleOutlined /> 現在可預約時間
            </CardInfo>
            <TimeSlotContainer>
              <TimeSlotGrid>
                {product.availableTimes.map((time, index) => (
                  <TimeSlot key={index}>{time}</TimeSlot>
                ))}
              </TimeSlotGrid>
            </TimeSlotContainer>
          </>
        )}
      </CardContent>
    </ResultCard>
  )

  if (isLoading) return <Loading />
  if (error) return <NotFoundPage />

  const totalResults =
    searchResults.businesses.length +
    searchResults.designers.length +
    searchResults.products.length

  return (
    <Container>
      <Helmet>
        <title>{`台灣美容美髮店家設計師與服務推薦 ｜ 線上即時預約平台 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <Header>
        <Title>搜索結果</Title>
      </Header>
      <SearchInfo>
        <ResultCount>{totalResults}個結果</ResultCount>
        <div>關鍵字: {searchedKeyword || '無'}</div>
      </SearchInfo>
      <SearchContainer>
        <SearchInput
          placeholder='搜索美容服務、沙龍或造型師'
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch()
            }
          }}
        />
        <SearchButton onClick={handleSearch} icon={<SearchOutlined />}>
          搜索
        </SearchButton>
      </SearchContainer>
      <StyledTabs
        activeKey={activeTab}
        onChange={setActiveTab}
        items={[
          {
            key: 'businesses',
            label: `商家 (${searchResults.businesses.length})`,
            children: (
              <ResultGrid>
                {searchResults.businesses.map(renderBusinessCard)}
              </ResultGrid>
            )
          },
          {
            key: 'designers',
            label: `設計師 (${searchResults.designers.length})`,
            children: (
              <ResultGrid>
                {searchResults.designers.map(renderDesignerCard)}
              </ResultGrid>
            )
          },
          {
            key: 'products',
            label: `服務產品 (${searchResults.products.length})`,
            children: (
              <ResultGrid>
                {searchResults.products.map(renderProductCard)}
              </ResultGrid>
            )
          }
        ]}
      />
    </Container>
  )
}

export default SearchAllResultsPage
