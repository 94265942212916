import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Tabs,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Upload,
  Button,
  message,
  Image,
  Row,
  Col,
  Switch,
  Empty
} from 'antd'
import { UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'
import { useNavigate } from 'react-router-dom'
import {
  categoriesList,
  translateCategoryToEnglish,
  translateCategoryToChinese
} from '../utils/utils'
import { PHOTO_URL } from '../utils/apiutils'
import AppliedJobs from '../components/AppliedJobs'
import InvitedJobs from '../components/InvitedJobs'
import Loading from '../utils/Loading'

const { Option } = Select
const { TextArea } = Input
const { TabPane } = Tabs

// 樣式組件
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  cursor: pointer;
`

const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`

const Title = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`

const StyledForm = styled(Form)`
  max-width: 800px;
  margin: 0 auto;
  text-align: left;

  .ant-form-item {
    margin-bottom: 0;
  }
`

const AboutModel = styled.div`
  background-color: #f2b3c0;
  color: #7b1c30;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: bold;
`

const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px; // 添加底部间距
`

// 新增樣式組件
const FormItemWrapper = styled.div`
  margin-bottom: 16px;
  /* background: linear-gradient(145deg, #f0f0f0, #ffffff); */
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 5px 16px;
`

const StyledLabel = styled.label`
  font-weight: bold;
  color: #333;
  margin-bottom: 6px;
  display: block;
  font-size: 14px;
`

const ContentWrapper = styled.div`
  padding: 8px 0;
  color: #666;
  font-size: 14px;
`

// 主組件
const UserModelSetting = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuth()
  const [modelProfile, setModelProfile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [isActive, setIsActive] = useState(true)

  const fetchModelProfile = async () => {
    try {
      setLoading(true)
      const response = await api.get('/api/user/model-profile')
      const userData = response.data.data

      setModelProfile({
        ...userData,
        photos: userData.modelProfile.photos
      })

      let preferredCategories = []
      if (
        userData.modelProfile &&
        userData.modelProfile.preferredCategories &&
        userData.modelProfile.preferredCategories.length > 0
      ) {
        preferredCategories =
          userData.modelProfile.preferredCategories[0].split(',')
      }

      form.setFieldsValue({
        name: userData.name,
        phone: userData.phone,
        gender: userData.gender,
        birthdate: userData.birthdate ? dayjs(userData.birthdate) : null,
        ...userData.modelProfile,
        preferredCategories: preferredCategories.map(translateCategoryToChinese)
      })
    } catch (error) {
      console.error('獲取模特信息失敗:', error)
      message.error('獲取模特信息失敗，請稍後重試')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchModelProfile()
  }, [])

  const onFinish = async (values) => {
    try {
      const formData = new FormData()
      Object.keys(values).forEach((key) => {
        if (key === 'photos') {
          values[key].forEach((file) => {
            if (file.originFileObj) {
              formData.append('photos', file.originFileObj)
            }
          })
        } else if (key === 'birthdate') {
          formData.append(key, values[key].format('YYYY-MM-DD'))
        } else if (key === 'preferredCategories') {
          formData.append(
            key,
            values[key].map(translateCategoryToEnglish).join(',')
          )
        } else {
          formData.append(key, values[key])
        }
      })

      await api.put('/api/user/model-profile', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      message.success('模特信息更新成功')
      fetchModelProfile()
      setIsEditing(false)
    } catch (error) {
      console.error('更新模特信息失敗:', error)
      message.error('更新模特信息失敗，請稍後重試')
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  // 輔助函數來處理可能的 "undefined" 字符串
  const displayValue = (value) => {
    return value && value !== 'undefined' ? value : ''
  }

  // 添加一個新的函數來處理開關狀態變化
  const handleActiveChange = async (checked) => {
    try {
      await api.post('/api/user/toggle-model-status', { active: checked })
      setIsActive(checked)
      message.success(checked ? '您的模特兒信息已啟用' : '您的模特兒信息已停用')
    } catch (error) {
      console.error('更新模特兒信息狀態失敗:', error)
      message.error('更新狀態失敗，請稍後重試')
    }
  }

  const handleDeletePhoto = async (photoUrl) => {
    try {
      await api.delete('/api/user/model-photo', { data: { photoUrl } })
      message.success('照片刪除成功')
      fetchModelProfile() // 重新獲取模特資料以更新照片列表
    } catch (error) {
      console.error('刪除照片失敗:', error)
      message.error('刪除照片失敗，請稍後重試')
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <TitleContainer>
        <TitleBar />
        <Title>模特兒設置</Title>
      </TitleContainer>

      <AboutModel>
        <Row justify='space-between' align='middle'>
          <Col>
            <strong>模特兒信息狀態</strong>
          </Col>
          <Col>
            <Switch
              checked={isActive}
              onChange={handleActiveChange}
              checkedChildren='啟用'
              unCheckedChildren='停用'
            />
          </Col>
        </Row>
      </AboutModel>

      {isActive ? (
        <div>
          <p style={{ marginBottom: '5px', color: '#d5728a' }}>
            您的模特兒信息目前處於啟用狀態。品牌可以看到您的資料並向您發送邀約。
          </p>
          <p style={{ marginTop: 0 }}>
            只有在雙方媒合成功時您的聯繫方式才會顯示在店家系統。
          </p>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='模特兒設置' key='1'>
              <Button
                icon={<EditOutlined />}
                onClick={() => setIsEditing(!isEditing)}
                style={{ float: 'right', margin: '5px' }}
              >
                {isEditing ? '取消編輯' : '編輯信息'}
              </Button>
              <StyledForm form={form} layout='vertical' onFinish={onFinish}>
                <AboutModel>基本信息</AboutModel>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='name'
                        label={<StyledLabel>姓名</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        {isEditing ? (
                          <Input />
                        ) : (
                          <ContentWrapper>
                            {displayValue(form.getFieldValue('name'))}
                          </ContentWrapper>
                        )}
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='birthdate'
                        label={<StyledLabel>生日</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        {isEditing ? (
                          <DatePicker style={{ width: '100%' }} />
                        ) : (
                          <ContentWrapper>
                            {form.getFieldValue('birthdate')
                              ? form
                                  .getFieldValue('birthdate')
                                  .format('YYYY-MM-DD')
                              : ''}
                          </ContentWrapper>
                        )}
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='phone'
                        label={<StyledLabel>手機</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        {isEditing ? (
                          <Input />
                        ) : (
                          <ContentWrapper>
                            {displayValue(form.getFieldValue('phone'))}
                          </ContentWrapper>
                        )}
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='gender'
                        label={<StyledLabel>性別</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group disabled={!isEditing}>
                          <Radio value='male'>男</Radio>
                          <Radio value='female'>女</Radio>
                          <Radio value='other'>其他</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <AboutModel>模特兒資料</AboutModel>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='skinType'
                        label={<StyledLabel>皮膚狀態</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='combination'>混合性</Option>
                          <Option value='dry'>偏乾</Option>
                          <Option value='oily'>偏油</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='hairType'
                        label={<StyledLabel>髮質</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='coarse'>粗硬</Option>
                          <Option value='fine'>細軟</Option>
                          <Option value='damaged'>受損</Option>
                          <Option value='normal'>適中</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='hairVolume'
                        label={<StyledLabel>髮量</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='thin'>少</Option>
                          <Option value='medium'>適中</Option>
                          <Option value='thick'>多</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='hairLength'
                        label={<StyledLabel>頭髮長度</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='short'>短</Option>
                          <Option value='medium'>中</Option>
                          <Option value='long'>長</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='nailCondition'
                        label={<StyledLabel>指甲狀況</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='brittle'>斷裂</Option>
                          <Option value='normal'>正常</Option>
                          <Option value='ingrown'>嵌甲</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='bodyCondition'
                        label={<StyledLabel>身體狀況</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='pregnant'>懷孕</Option>
                          <Option value='breastfeeding'>哺乳</Option>
                          <Option value='none'>無</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <FormItemWrapper>
                      <Form.Item
                        name='isAllergic'
                        label={<StyledLabel>是否過敏</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group disabled={!isEditing}>
                          <Radio value={true}>是</Radio>
                          <Radio value={false}>否</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <AboutModel>期望信息</AboutModel>
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItemWrapper>
                      <Form.Item
                        name='preferredCategories'
                        label={<StyledLabel>希望參與項目類型</StyledLabel>}
                        rules={[{ required: true, type: 'array' }]}
                      >
                        <Select mode='multiple' disabled={!isEditing}>
                          {categoriesList.map((category) => (
                            <Option key={category.type} value={category.name}>
                              {category.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='recruitmentType'
                        label={<StyledLabel>體驗類型</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='experienceModal'>
                            體驗模特兒(純體驗)
                          </Option>
                          <Option value='reciprocalModel'>
                            互惠模特兒(可幫忙宣傳與發文)
                          </Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='expectPayment'
                        label={<StyledLabel>期望收費與否</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='yes'>是</Option>
                          <Option value='no'>否</Option>
                          <Option value='maybe'>不確定，看情況</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <FormItemWrapper>
                      <Form.Item
                        name='needPayment'
                        label={<StyledLabel>是否需要報酬</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='yes'>是</Option>
                          <Option value='no'>否</Option>
                          <Option value='maybe'>不確定，看情況</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <AboutModel>個人介紹</AboutModel>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='modelType'
                        label={<StyledLabel>你的類型</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!isEditing}>
                          <Option value='model'>素人模特兒</Option>
                          <Option value='modelWithFans'>自帶粉絲模特兒</Option>
                          <Option value='kol'>KOL</Option>
                          <Option value='influencer'>網紅</Option>
                          <Option value='modelAndInfluencer'>
                            模特兒兼網紅
                          </Option>
                          <Option value='other'>其他</Option>
                        </Select>
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='instagramUrl'
                        label={<StyledLabel>Instagram 網址</StyledLabel>}
                      >
                        {isEditing ? (
                          <Input />
                        ) : (
                          <ContentWrapper>
                            {form.getFieldValue('instagramUrl') &&
                            form.getFieldValue('instagramUrl') !== 'undefined'
                              ? form.getFieldValue('instagramUrl')
                              : ''}
                          </ContentWrapper>
                        )}
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='facebookUrl'
                        label={<StyledLabel>Facebook 網址</StyledLabel>}
                      >
                        {isEditing ? (
                          <Input />
                        ) : (
                          <ContentWrapper>
                            {form.getFieldValue('facebookUrl') &&
                            form.getFieldValue('facebookUrl') !== 'undefined'
                              ? form.getFieldValue('facebookUrl')
                              : ''}
                          </ContentWrapper>
                        )}
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                  <Col span={12}>
                    <FormItemWrapper>
                      <Form.Item
                        name='lineId'
                        label={<StyledLabel>Line ID</StyledLabel>}
                        rules={[{ required: true }]}
                      >
                        <Input disabled={!isEditing} />
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <FormItemWrapper>
                      <Form.Item
                        name='aboutMe'
                        label={
                          <StyledLabel>
                            關於你自己的介紹(請簡明扼要敘述)
                          </StyledLabel>
                        }
                      >
                        {isEditing ? (
                          <Input.TextArea />
                        ) : (
                          <ContentWrapper>
                            {form.getFieldValue('aboutMe') &&
                            form.getFieldValue('aboutMe') !== 'undefined'
                              ? form.getFieldValue('aboutMe')
                              : ''}
                          </ContentWrapper>
                        )}
                      </Form.Item>
                    </FormItemWrapper>
                  </Col>
                </Row>

                <AboutModel>照片</AboutModel>
                <PhotosContainer>
                  {modelProfile &&
                  modelProfile.photos &&
                  modelProfile.photos.length > 0 ? (
                    modelProfile.photos.map((photo, index) => {
                      const fullPhotoUrl = `${PHOTO_URL}${photo}`
                      return (
                        <div key={index} style={{ position: 'relative' }}>
                          <Image
                            src={fullPhotoUrl}
                            width={100}
                            height={100}
                            style={{ objectFit: 'cover' }}
                            alt={`模特兒照片 ${index + 1}`}
                          />
                          {isEditing && (
                            <Button
                              icon={<DeleteOutlined />}
                              size='small'
                              style={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                background: 'rgba(255, 255, 255, 0.7)'
                              }}
                              onClick={() => handleDeletePhoto(photo)}
                            />
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <Empty />
                  )}
                </PhotosContainer>
                {isEditing && (
                  <Form.Item
                    name='photos'
                    label='上傳相關照片（最多10張，每張5MB以下）'
                    valuePropName='fileList'
                    getValueFromEvent={normFile}
                    showUploadList={false} // 禁用antd默认的预览列表
                  >
                    <Upload
                      listType='picture'
                      maxCount={10}
                      beforeUpload={(file) => {
                        const isLt5M = file.size / 1024 / 1024 < 5
                        if (!isLt5M) {
                          message.error('圖片必須小於5MB!')
                        }
                        return false
                      }}
                    >
                      <Button icon={<UploadOutlined />}>選擇照片</Button>
                    </Upload>
                  </Form.Item>
                )}

                {isEditing && (
                  <Form.Item style={{ marginTop: '20px' }}>
                    {' '}
                    {/* 添加上边距 */}
                    <Button type='primary' htmlType='submit'>
                      更新信息
                    </Button>
                  </Form.Item>
                )}
              </StyledForm>
            </TabPane>
            <TabPane tab='查看邀約' key='2'>
              <InvitedJobs />
            </TabPane>
            <TabPane tab='查看應徵記錄' key='3'>
              <AppliedJobs />
            </TabPane>
          </Tabs>
        </div>
      ) : (
        <div style={{ textAlign: 'center', margin: '50px 0' }}>
          <h2>您的模特兒信息目前已停用</h2>
          <p>
            當您的信息處於停用狀態時，品牌將無法看到您的資料或向您發送邀約。
          </p>
          <p>如果您想重新接收邀約，請將開關切換至"啟用"狀態。</p>
        </div>
      )}
    </Container>
  )
}

export default UserModelSetting
