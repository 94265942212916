import React, { useState } from 'react'
import styled from 'styled-components'
import { Input, Tag, Button } from 'antd'

const TagInputWrapper = styled.div`
  margin-bottom: 20px;
`

const TagList = styled.div`
  display: flex;
  max-width: 680px;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-height: 50px;
`

const TagItem = styled(Tag)`
  display: flex;
  height: 30px;
  align-items: center;
  margin-bottom: 5px;
`

const InputWrapper = styled.div`
  max-width: 680px;
  margin: 10px 0;
`

const Box = styled.div`
  display: flex;
  max-width: 680px;
  justify-content: end;
`

const TagInput = ({ tags, setTags }) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue])
    }
    setInputValue('')
  }

  const handleTagClose = (removedTag) => {
    setTags(tags.filter((tag) => tag !== removedTag))
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault()
      handleInputConfirm()
    }
  }

  const handleRemoveAll = () => {
    setTags([])
  }

  return (
    <TagInputWrapper>
      <InputWrapper>
        <Input
          type='text'
          size='large'
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onKeyPress={handleKeyPress}
          placeholder='使用逗號分隔或是Enter可自動生成標籤 Press enter or add a comma after each tag'
        />
      </InputWrapper>
      <TagList>
        {tags.map((tag, index) => (
          <TagItem key={index} closable onClose={() => handleTagClose(tag)}>
            {tag}
          </TagItem>
        ))}
      </TagList>
      <Box>
        <Button onClick={handleRemoveAll} style={{ marginTop: '10px' }}>
          Remove All
        </Button>
      </Box>
    </TagInputWrapper>
  )
}

export default TagInput
