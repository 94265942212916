import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const NotificationDetailPage = () => {
  const [notification, setNotification] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    fetchNotificationDetail(id)
  }, [id])

  const fetchNotificationDetail = async (notificationId) => {
    // 實現API調用來獲取特定通知詳情
  }

  const markAsRead = async () => {
    // 實現API調用來標記通知為已讀
  }

  if (!notification) return <div>加載中...</div>

  return (
    <div>
      <h2>{notification.title}</h2>
      <p>{notification.content}</p>
      <button onClick={markAsRead}>標記為已讀</button>
    </div>
  )
}

export default NotificationDetailPage
