import { io } from 'socket.io-client'

let socket

export const initSocket = () => {
  if (!socket) {
    socket = io('http://localhost:8080', {
      autoConnect: false // 防止自动连接
    })
    console.log('Socket initialized', socket)
  }
  if (!socket.connected) {
    socket.connect()
  }
}

export const getSocket = () => {
  return socket
}

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect()
    console.log('Socket disconnected')
  }
}
