import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Avatar, List, message, Modal, Input, Button } from 'antd'
import {
  HeartOutlined,
  CalendarOutlined,
  UserAddOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  LockOutlined,
  CopyOutlined,
  CrownOutlined,
  UserSwitchOutlined
} from '@ant-design/icons'
import api from '../utils/api'
import { useAuth } from '../hooks/AuthContext'
import { PHOTO_URL } from '../utils/apiutils'

const DashboardContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
  }
`
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`

const UserInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

const UserAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
`

const UserName = styled.h2`
  margin: 0;
  color: #333;
  font-size: 20px;
`

const UserRole = styled.p`
  color: #666;
  margin: 5px 0;
  font-size: 14px;
`

const UserContact = styled.p`
  color: #666;
  margin: 2px 0;
  font-size: 14px;
`

const WalletSection = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`

const WalletItem = styled.div`
  text-align: center;
  cursor: pointer;
`

const WalletValue = styled.h3`
  margin: 0;
  color: #333;
`

const WalletLabel = styled.p`
  margin: 0;
  color: #666;
  font-size: 12px;
`

const MenuList = styled(List)`
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  .ant-list-item {
    padding: 15px;
  }
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.color || '#333'};
`

const MenuIcon = styled.span`
  margin-right: 10px;
  font-size: 18px;
`

const UserDashboard = () => {
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [inviteCode, setInviteCode] = useState('')
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false)

  const showPasswordChangeModal = () => {
    setIsModalVisible(true)
  }

  const handleModalOk = () => {
    setIsModalVisible(false)
    requestPasswordChange()
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const requestPasswordChange = () => {
    api
      .post('/api/auth/request-change-password')
      .then(() => {
        message.success('驗證郵件已發送，請查看您的郵箱')
      })
      .catch((error) => {
        console.error('Error requesting password change:', error)
        console.log('Error response:', error.response)
        console.log('Error data:', error.response?.data)
        message.error(error.response?.data?.error || '請求更改密碼失敗')
      })
  }

  const generateInviteCode = () => {
    if (user && user.userId) {
      return user.userId.substring(0, 6)
    }
    return ''
  }

  const showInviteModal = () => {
    const code = generateInviteCode()
    setInviteCode(code)
    setIsInviteModalVisible(true)
  }

  const handleInviteModalCancel = () => {
    setIsInviteModalVisible(false)
  }

  const copyInviteInfo = () => {
    const inviteLink = `https://beautefeel.com`
    const inviteText = `Hi！ 我推薦你加入美感BeauteFeel來一起獲取積分，你消費我們倆都能獲得點數50點唷！快使用我的邀請碼：${inviteCode}\n${inviteLink}`
    navigator.clipboard
      .writeText(inviteText)
      .then(() => {
        message.success('邀請信息已複製到剪貼板')
      })
      .catch(() => {
        message.error('複製失敗，請手動複製')
      })
  }

  const menuItems = [
    {
      icon: <CalendarOutlined />,
      text: '預約管理',
      onClick: () => navigate(`/user-appointment-list/${user.username}`)
    },
    {
      icon: <HeartOutlined />,
      text: '我的收藏',
      onClick: () => navigate('/favorites/store')
    },
    // {
    //   icon: <CreditCardOutlined />,
    //   text: '支付方式',
    //   onClick: () => navigate('/payment')
    // },
    {
      icon: <UserAddOutlined />,
      text: '邀請好友',
      onClick: showInviteModal
    },
    {
      icon: user.isModel ? <CrownOutlined /> : <UserSwitchOutlined />,
      text: user.isModel ? '模特兒設置' : '申請成為模特兒',
      onClick: () =>
        navigate(user.isModel ? '/model-setting' : '/register-model')
    },
    // {
    //   icon: <TagOutlined />,
    //   text: '優惠活動',
    //   onClick: () => navigate('/promotions')
    // },
    {
      icon: <SettingOutlined />,
      text: '設置',
      onClick: () => navigate('/account')
    },
    {
      icon: <LogoutOutlined />,
      text: '登出',
      onClick: logout,
      color: '#ff4d4f'
    }
  ]

  return (
    <DashboardContainer>
      <Container>
        <UserInfoSection>
          <UserAvatar
            src={
              user?.avatar
                ? user.loginType === 'line' || user.loginType === 'google'
                  ? user.avatar
                  : `${PHOTO_URL}${user.avatar}`
                : null
            }
            icon={<UserOutlined />}
          />
          <UserName>{user?.name || '還沒有名字'}</UserName>
          <UserRole>{user?.role === 'user' ? '一般用戶' : '無名號'}</UserRole>
          <UserContact>{user?.phone}</UserContact>
          <UserContact>{user?.email}</UserContact>
        </UserInfoSection>

        <WalletSection>
          <WalletItem>
            <WalletValue>100</WalletValue>
            <WalletLabel>會員積分</WalletLabel>
          </WalletItem>
          <WalletItem onClick={showPasswordChangeModal}>
            <WalletValue>
              <LockOutlined />
            </WalletValue>
            修改密碼
          </WalletItem>
        </WalletSection>

        <MenuList
          dataSource={menuItems}
          renderItem={(item) => (
            <List.Item onClick={item.onClick}>
              <MenuItem color={item.color}>
                <MenuIcon>{item.icon}</MenuIcon>
                {item.text}
              </MenuItem>
            </List.Item>
          )}
        />

        <Modal
          title='修改密碼確認'
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          okText='確認'
          cancelText='取消'
        >
          <p>是否發送驗證信以修改密碼？</p>
        </Modal>

        <Modal
          title='邀請好友'
          open={isInviteModalVisible}
          onCancel={handleInviteModalCancel}
          footer={null}
        >
          <p>您的邀請碼：</p>
          <Input.Group compact>
            <Input
              style={{ width: 'calc(100% - 32px)' }}
              value={inviteCode}
              readOnly
            />
            <Button icon={<CopyOutlined />} onClick={copyInviteInfo} />
          </Input.Group>
          <p style={{ marginTop: '10px' }}>
            點擊複製按鈕即可複製邀請信息和連結
          </p>
        </Modal>
      </Container>
    </DashboardContainer>
  )
}

export default UserDashboard
