import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'antd'
import {
  SmileOutlined,
  HeartOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'
import { colors } from '../utils/utils'

const TestimonialSection = styled.div`
  padding: 10px 100px;
  background-color: #fff;
  @media (max-width: 820px) {
    padding: 20px 10px;
  }
`

const TestimonialCard = styled.div`
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  margin: 10px;
  box-shadow: 0 4px 15px rgba(213, 114, 138, 0.1);
  text-align: left;
  height: 280px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #f9ebed;

  @media (max-width: 820px) {
    height: 250px;
    padding: 20px;
  }
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const UserAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid ${colors.primary};
`

const UserDetails = styled.div`
  flex: 1;
`

const UserName = styled.h3`
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 600;
`

const UserLocation = styled.p`
  margin: 5px 0 0;
  color: ${colors.primary};
  font-size: 14px;
`

const TestimonialText = styled.p`
  color: #666;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 20px;
  flex: 1;
`

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: auto;
`

const Tag = styled.span`
  background-color: #f9ebed;
  color: ${colors.primary};
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledCarousel = styled(Carousel)`
  .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${colors.primary}40 !important;
  }
  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li.slick-active button {
    background: ${colors.primary} !important;
  }

  .slick-dots-bottom {
    bottom: -30px;
  }

  .slick-dots li {
    background: none;
  }

  .ant-carousel .slick-dots li button {
    display: none;
  }
`

const testimonials = [
  {
    id: 1,
    name: '吳小姐',
    location: '桃園/護理師',
    avatar: 'https://f005.backblazeb2.com/file/beautyapp/avatars/IMG_1698s.jpg',
    text: '身為護理師，時間安排總是很緊湊，這個平台讓我能在下班後直接預約美容服務，不用花時間打電話詢問，真的超方便！',
    tags: ['時間靈活', '快速預約', '介面直觀']
  },
  {
    id: 2,
    name: '王小姐',
    location: '新竹/科技業',
    avatar: 'https://f005.backblazeb2.com/file/beautyapp/avatars/IMG_1699s.jpg',
    text: '平台上的評價系統很透明，可以看到其他顧客的真實體驗，這讓我在選擇造型師時更有信心，服務品質也都符合期待。',
    tags: ['評價透明', '服務優質', '值得信賴']
  },
  {
    id: 3,
    name: '謝小姐',
    location: '台中/自由工作者',
    avatar: 'https://f005.backblazeb2.com/file/beautyapp/avatars/IMG_1700s.jpg',
    text: '身為重度社交恐懼症患者，這個平台真的是救星！可以直接線上溝通、預約，不用打電話真的超貼心，而且美容師都很親切！',
    tags: ['社恐友善', '線上溝通', '貼心服務']
  },
  {
    id: 3,
    name: '黃小姐',
    location: '台北/工程師',
    avatar: 'https://f005.backblazeb2.com/file/beautyapp/avatars/IMG_4756.JPG',
    text: '一直想知道為什麼都沒有一個平台整合所有美容產業的資訊？身為消費者，我覺得這個平台真的超棒，可以一次比較所有美容師的資訊，不用一家一家詢問打電話約時間真的很方便！',
    tags: ['整合資訊', '方便預約', '省時省力']
  },
  {
    id: 1,
    name: '王小姐',
    location: '台北/教師',
    avatar: 'https://f005.backblazeb2.com/file/beautyapp/avatars/IMG_1701s.jpg',
    text: '經姪女介紹，使用這個平台預約美容，真的超級方便，不用再一家一家打電話詢問，而且美容師都很專業，服務品質都很棒！',
    tags: ['快速查找', '專業服務', '值得信賴']
  }
  // ... 可以繼續添加更多testimonials
]

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <TestimonialSection>
      <StyledCarousel {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id}>
            <TestimonialCard>
              <UserInfo>
                <UserAvatar src={testimonial.avatar} alt={testimonial.name} />
                <UserDetails>
                  <UserName>{testimonial.name}</UserName>
                  <UserLocation>{testimonial.location}</UserLocation>
                </UserDetails>
              </UserInfo>
              <TestimonialText>{testimonial.text}</TestimonialText>
              <TagContainer>
                {testimonial.tags.map((tag, index) => (
                  <Tag key={index}>
                    {index === 0 && <SmileOutlined />}
                    {index === 1 && <HeartOutlined />}
                    {index === 2 && <CheckCircleOutlined />}
                    {tag}
                  </Tag>
                ))}
              </TagContainer>
            </TestimonialCard>
          </div>
        ))}
      </StyledCarousel>
    </TestimonialSection>
  )
}

export default TestimonialSlider
