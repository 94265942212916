import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { Pagination, Empty, Divider } from 'antd'
import { Popup } from 'antd-mobile'
import Slider from 'react-slick'
import {
  CaretRightOutlined,
  EnvironmentOutlined,
  DownOutlined
} from '@ant-design/icons'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { categories, translateCategoryToChinese } from '../utils/utils'
import Loading from '../utils/Loading'
import cityData from '../utils/cityData'
import { Helmet } from 'react-helmet-async'
import {
  convertToEngName,
  districtNameMapping,
  convertFullAddressToEng,
  convertEngToFullAddress
} from '../utils/cityData'

const Container = styled.div`
  display: flex;
  font-family: Arial, sans-serif;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`
const FilterContent = styled.div`
  padding: 0px 20px 20px 20px;
  flex: 1;
  @media (max-width: 820px) {
    padding: 0px 20px 0px 20px;
  }
`

const MainContent = styled.div`
  padding: 0px 20px 20px 20px;
  flex: 3;
  @media (max-width: 820px) {
    padding: 0px 20px 20px 20px;
  }
`

const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media (max-width: 820px) {
    margin: 10px 0;
  }
`

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }

  @media (max-width: 820px) {
    padding: 7px;
  }
`

const CategoryIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 5px;
  @media (max-width: 820px) {
    width: 20px;
    height: 20px;
  }
`

const CategoryName = styled.div`
  font-size: 14px;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`

const BusinessList = styled.div`
  margin-top: 20px;
`

const BusinessCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;

  @media (max-width: 800px) {
    padding: 0 35px;
  }
`

const BusinessHeader = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 820px) {
    flex-direction: column;
    text-align: center;
  }
`

const BusinessLogo = styled.div`
  width: 300px;
  height: 200px;
  margin: 0 50px 0 20px;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 820px) {
    width: 100%;
    height: auto;
    margin: 15px 30px;
  }
`

const BusinessImage = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  overflow: scroll;
  @media (max-width: 800px) {
    width: 200px;
    height: 150px;
    margin: 10px;
  }
`

const BusinessInfo = styled.div`
  width: 100%;
  text-align: left;

  @media (max-width: 820px) {
    text-align: center;
  }
`
const InfoHeader = styled.div`
  cursor: pointer;
`

const BusinessName = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const BusinessRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  @media (max-width: 820px) {
    justify-content: center;
  }
`

const Rating = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #c1a268;
  margin-right: 10px;
`

const ReviewCount = styled.div`
  font-size: 14px;
  color: #888;
`

const BusinessAddress = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`

const ProductList = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;

  @media (max-width: 820px) {
    padding: 15px;
  }
`

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const ProductPrice = styled.div`
  color: #d5728a;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
`

const ProductName = styled.div`
  font-size: 14px;
  color: #333;
`

const MoreOffers = styled.div`
  font-size: 12px;
  color: #d5728a;
  padding-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`

const SliderContainer = styled.div`
  .slick-slide > div {
    display: flex;
    justify-content: center;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #d5728a;
  }
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 820px) {
    .ant-pagination {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      a {
        padding: 0 11px;
      }
    }

    .ant-pagination-total-text {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin: 0 4px;
      display: inline-flex;
      align-items: center;
    }

    .ant-pagination-options {
      margin-top: 10px;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

const RegionFilter = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 820px) {
    margin: 10px 0;
  }
`

const RegionTitle = styled.h3`
  width: 100%;
  margin-bottom: 10px;
`
const ServiceTitle = styled.h3`
  width: 100%;
`

const RegionAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #c56b81;
    color: #fff;
  }
  @media (max-width: 820px) {
    padding: 7px;
    margin-bottom: 10px;
  }
`

const RegionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`

const RegionItem = styled.div`
  padding: 5px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }

  @media (max-width: 820px) {
    grid-column: span 1;
  }
`
const EmptyContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`

const MobileRegionButton = styled.button`
  display: none;
  width: 100%;
  margin: 20px 0 10px 0;
  padding: 12px 15px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  font-size: 16px;

  &:active {
    background: #f5f5f5;
  }

  @media (max-width: 820px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
  }
`
const DesktopCategoryFilter = styled.div`
  @media (max-width: 820px) {
    display: none;
  }
`

const RegionButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
`

const RegionIcon = styled(EnvironmentOutlined)`
  color: #d5728a;
  font-size: 18px;
`

const RegionText = styled.span`
  color: ${(props) => (props.$hasSelection ? '#333' : '#999')};
`

const DownIcon = styled(DownOutlined)`
  color: #999;
  font-size: 12px;
  margin-left: auto;
`

const PopupContent = styled.div`
  padding: 20px;
  padding-bottom: 80px; // 添加底部 padding
  flex: 1;
  overflow-y: auto;
  position: relative;
`
const BackButtonContainer = styled.div`
  padding: 16px;
  background: white;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.06);
`

const BackButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #c4617a;
  }

  &:active {
    transform: scale(0.98);
  }
`

const PopupTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

const PopupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`

const DesktopRegionFilter = styled(RegionFilter)`
  @media (max-width: 820px) {
    display: none;
  }
`

const DistrictsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`

const DistrictItem = styled(RegionItem)`
  font-size: 14px;
  padding: 5px;
`

const LoadingMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

// 新增區域篩選的樣式組件
const DistrictFilter = styled(DesktopRegionFilter)`
  margin-top: 20px;
`

const TitleH1 = styled.h1`
  position: relative;
  font-size: 20px;
  color: #666;
  padding: 18px 25px;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 12px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 40%;
    width: 4px;
    background: linear-gradient(to bottom, #d5728a, #e598a8);
    border-radius: 2px;
  }

  @media (max-width: 820px) {
    font-size: 1.2rem;
    padding: 14px 20px;

    &::after {
      font-size: 0.9rem;
      padding: 5px 10px;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    align-items: flex-start;
  }
`

const LocationText = styled.span`
  margin-right: 8px;
`

const ServiceText = styled.span`
  color: #d5728a;
`

const BusinessesPage = () => {
  const { category, region, page } = useParams()
  const navigate = useNavigate()
  const [businesses, setBusinesses] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(category || 'all')
  const [currentPage, setCurrentPage] = useState(parseInt(page) || 1)
  const [loading, setLoading] = useState(true)
  const [totalBusinesses, setTotalBusinesses] = useState(0)
  const [selectedRegion, setSelectedRegion] = useState(
    decodeURIComponent(region) || 'all'
  )
  const [showPopup, setShowPopup] = useState(false)
  const [areaLoading, setAreaLoading] = useState(false)
  const [selectedCity, setSelectedCity] = useState(null)
  const [districts, setDistricts] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState(null)

  const fetchBusinesses = useCallback(async () => {
    setLoading(true)
    try {
      let response
      if (selectedRegion && selectedRegion !== 'all') {
        let regionParam = selectedRegion
        if (selectedCity === '新北市') {
          regionParam = selectedDistrict
            ? `${selectedCity}${selectedDistrict}`
            : '新北市'
        }

        response = await axios.get(`${API_BASE_URL}/api/search/region`, {
          params: {
            region: regionParam,
            serviceType:
              selectedCategory !== 'all' ? selectedCategory : undefined,
            page: currentPage
          }
        })
      } else {
        if (selectedCategory === 'all') {
          response = await axios.get(`${API_BASE_URL}/api/business_C`, {
            params: { page: currentPage }
          })
        } else {
          response = await axios.get(`${API_BASE_URL}/api/business_C/type`, {
            params: {
              type: selectedCategory,
              page: currentPage
            }
          })
        }
      }

      if (response.data && Array.isArray(response.data.businesses)) {
        setBusinesses(response.data.businesses)
        setCurrentPage(response.data.currentPage || 1)
        setTotalBusinesses(response.data.totalBusinesses || 0)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }, [
    selectedCategory,
    selectedRegion,
    currentPage,
    selectedCity,
    selectedDistrict
  ])

  useEffect(() => {
    fetchBusinesses()
  }, [fetchBusinesses])

  useEffect(() => {
    if (category && !region) {
      navigate(`/businesses/${category}/all/1`)
      return
    }

    const currentRegion = convertFullAddressToEng(selectedRegion)
    if (
      selectedCategory !== category ||
      currentRegion !== region ||
      parseInt(page) !== currentPage
    ) {
      navigate(
        `/businesses/${selectedCategory}/${currentRegion}/${currentPage}`
      )
    }
  }, [selectedCategory, selectedRegion, currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleCategoryChange = (newCategory) => {
    setSelectedCategory(newCategory)
    setCurrentPage(1)
    navigate(
      `/businesses/${newCategory}/${convertFullAddressToEng(
        selectedRegion
      )}/${1}`
    )
  }

  // 添加 handleCardClick 函數
  const handleCardClick = (username) => {
    navigate(`/store/${username}`)
  }

  // 添加 sliderSettings
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }
    ]
  }

  const handleCitySelect = async (city) => {
    setAreaLoading(true)
    try {
      if (city === 'all') {
        setSelectedCity(null)
        setSelectedDistrict(null)
        setDistricts([])
        setSelectedRegion('all')
      } else {
        const cityInfo = cityData.districts.find((d) => d.name === city)
        if (cityInfo && cityInfo.areas) {
          setDistricts(cityInfo.areas)
        }
        setSelectedCity(city)
        setSelectedDistrict(null)
        setSelectedRegion(city)
      }
      setCurrentPage(1)
    } catch (error) {
      console.error('Error in city selection:', error)
    } finally {
      setAreaLoading(false)
      setShowPopup(false)
    }
  }

  const handleDistrictSelect = async (district) => {
    setAreaLoading(true)
    try {
      setSelectedDistrict(district)
      setSelectedRegion(`${selectedCity}${district}`)
      setCurrentPage(1)
    } catch (error) {
      console.error('Error in district selection:', error)
    } finally {
      setAreaLoading(false)
      setShowPopup(false)
    }
  }

  // 保留城市選擇時區域初始化邏輯
  useEffect(() => {
    if (selectedCity && selectedCity !== 'all') {
      const cityInfo = cityData.districts.find((d) => d.name === selectedCity)
      if (cityInfo && cityInfo.areas) {
        setDistricts(cityInfo.areas)
      }
    } else {
      setDistricts([])
    }
  }, [selectedCity])

  // 構建結構化數據
  const getStructuredData = () => {
    const serviceType =
      selectedCategory === 'all'
        ? '美容美甲睫毛'
        : translateCategoryToChinese(selectedCategory)

    const location = selectedRegion === 'all' ? '台灣' : selectedRegion

    return {
      '@context': 'https://schema.org',
      '@type': 'Service',
      name: `${location}${serviceType}店家線上預約`,
      areaServed: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressRegion: location,
          addressCountry: '台灣'
        }
      },
      serviceType: serviceType,
      provider: {
        '@type': 'Organization',
        name: '美感 BeauteFeel'
      }
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <Helmet>
        <title>
          {`${selectedRegion === 'all' ? '台灣' : selectedRegion}${
            selectedCategory === 'all'
              ? '美容'
              : translateCategoryToChinese(selectedCategory)
          }店家線上預約 - 美感｜BeauteFeel`}
        </title>
        <meta
          name='description'
          content={`在${
            selectedRegion === 'all' ? '台灣' : selectedRegion
          }尋找優質${
            selectedCategory === 'all'
              ? '美容美甲美髮等'
              : translateCategoryToChinese(selectedCategory)
          }服務，多家精選店家，提供完整價格、評價資訊，立即線上預約！`}
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Review',
            itemReviewed: {
              '@type': 'LocalBusiness',
              name: `${selectedRegion === 'all' ? '台灣' : selectedRegion}${
                selectedCategory === 'all'
                  ? '美容、美甲、美髮'
                  : translateCategoryToChinese(selectedCategory)
              }店家`,
              areaServed: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  addressRegion:
                    selectedRegion === 'all' ? '台灣' : selectedRegion,
                  addressCountry: '台灣'
                }
              }
            },
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '4.5',
              bestRating: '5',
              worstRating: '1'
            },
            author: {
              '@type': 'Organization',
              name: '美感 BeauteFeel'
            },
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.5',
              reviewCount: totalBusinesses,
              ratingCount: totalBusinesses,
              bestRating: '5',
              worstRating: '1'
            }
          })}
        </script>
      </Helmet>
      <FilterContent>
        {/* 桌面版縣市篩選 */}
        <DesktopRegionFilter className='desktop-only'>
          <Divider style={{ margin: '0' }}>
            <RegionTitle>縣市篩選</RegionTitle>
          </Divider>
          <RegionAll
            $active={selectedRegion === 'all'}
            onClick={() => handleCitySelect('all')}
          >
            全部地區
          </RegionAll>
          <RegionGrid>
            {cityData.counties.map((city) => (
              <RegionItem
                key={city}
                $active={selectedCity === city}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </RegionItem>
            ))}
          </RegionGrid>
        </DesktopRegionFilter>

        {/* 新增：桌面版區域篩選 */}
        {selectedCity && selectedCity !== 'all' && (
          <DistrictFilter className='desktop-only'>
            <RegionTitle>區域篩選</RegionTitle>
            <RegionGrid>
              {districts.map((district) => (
                <RegionItem
                  key={district}
                  $active={selectedDistrict === district}
                  onClick={() => handleDistrictSelect(district)}
                >
                  {district}
                </RegionItem>
              ))}
            </RegionGrid>
          </DistrictFilter>
        )}

        {/* 手机版按钮 */}
        <MobileRegionButton onClick={() => setShowPopup(true)}>
          <RegionButtonContent>
            <RegionIcon />
            <RegionText $hasSelection={selectedRegion !== 'all'}>
              {selectedRegion === 'all' ? '按地區搜尋' : selectedRegion}
            </RegionText>
          </RegionButtonContent>
          <DownIcon />
        </MobileRegionButton>

        {/* 手机版弹窗 */}
        <Popup
          visible={showPopup}
          onMaskClick={() => setShowPopup(false)}
          position='bottom'
          bodyStyle={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <PopupContent>
            <PopupTitle>選擇地區</PopupTitle>
            <RegionAll
              $active={selectedRegion === 'all'}
              onClick={() => handleCitySelect('all')}
            >
              全部地區
            </RegionAll>
            <PopupGrid>
              {cityData.counties.map((city) => (
                <RegionItem
                  key={city}
                  $active={selectedRegion === city}
                  onClick={() => handleCitySelect(city)}
                >
                  {city}
                </RegionItem>
              ))}
            </PopupGrid>

            {/* 显示区域选择 */}
            {selectedCity && districts.length > 0 && (
              <DistrictsGrid>
                {districts.map((district) => (
                  <DistrictItem
                    key={district}
                    $active={selectedDistrict === district}
                    onClick={() => handleDistrictSelect(district)}
                  >
                    {district}
                  </DistrictItem>
                ))}
              </DistrictsGrid>
            )}
          </PopupContent>
          <BackButtonContainer>
            <BackButton onClick={() => setShowPopup(false)}>返回</BackButton>
          </BackButtonContainer>
        </Popup>

        {/* Loading 遮罩 */}
        {areaLoading && (
          <LoadingMask>
            <Loading />
          </LoadingMask>
        )}
        <DesktopCategoryFilter className='desktop-only'>
          <Divider style={{ margin: '0' }}>
            <ServiceTitle>服務類型</ServiceTitle>
          </Divider>
        </DesktopCategoryFilter>
        <CategoriesContainer>
          {categories.map((category, index) => (
            <CategoryItem
              key={index}
              $active={selectedCategory === category.type}
              onClick={() => handleCategoryChange(category.type)}
            >
              <CategoryIcon src={`${category.icon}`} alt={category.name} />
              <CategoryName>{category.name}</CategoryName>
            </CategoryItem>
          ))}
        </CategoriesContainer>
      </FilterContent>

      <MainContent>
        <TitleH1 count={totalBusinesses}>
          <LocationText>
            {selectedRegion === 'all' ? '台灣' : selectedRegion}
          </LocationText>
          <ServiceText>
            {selectedCategory === 'all'
              ? '所有美容、美甲、美髮等'
              : translateCategoryToChinese(selectedCategory)}
            店家
            {totalBusinesses > 0 && `（${totalBusinesses}）`}
          </ServiceText>
        </TitleH1>
        <BusinessList>
          {Array.isArray(businesses) && businesses.length > 0 ? (
            businesses.map((business, index) => (
              <BusinessCard key={index}>
                <BusinessHeader>
                  <BusinessLogo>
                    {business.photos && business.photos.length > 1 ? (
                      <SliderContainer>
                        <Slider {...sliderSettings}>
                          {business.photos.map((photo, idx) => (
                            <BusinessImage
                              key={idx}
                              src={`${PHOTO_URL}${photo.url}`}
                              alt={`${business.name}-${idx}`}
                              onClick={() => handleCardClick(business.username)}
                            />
                          ))}
                        </Slider>
                      </SliderContainer>
                    ) : (
                      <BusinessImage
                        src={
                          business.photos && business.photos?.length > 0
                            ? `${PHOTO_URL}${business.photos[0].url}`
                            : '/assets/business_photo.png'
                        }
                        alt={business.name}
                        onClick={() => handleCardClick(business.username)}
                      />
                    )}
                  </BusinessLogo>
                  <BusinessInfo>
                    <InfoHeader
                      onClick={() => handleCardClick(business.username)}
                    >
                      <BusinessName>{business.name}</BusinessName>
                      <BusinessRating>
                        <Rating>
                          評分:{' '}
                          {business.averageRating
                            ? Number.isInteger(business.averageRating)
                              ? business.averageRating
                              : business.averageRating.toFixed(1)
                            : '暫無評分'}
                        </Rating>
                        <ReviewCount>
                          ({business.reviewCount ? business.reviewCount : 0}
                          評論)
                        </ReviewCount>
                      </BusinessRating>
                      <BusinessAddress>{business.address}</BusinessAddress>
                    </InfoHeader>
                    <ProductList>
                      {business.products?.slice(0, 3).map((product, index) => (
                        <ProductItem key={index}>
                          <ProductPrice>$ {product.price}</ProductPrice>
                          <ProductName>{product.name}</ProductName>
                        </ProductItem>
                      ))}
                      {business.products?.length > 3 && (
                        <MoreOffers
                          onClick={() => handleCardClick(business.username)}
                        >
                          查看更多優惠 <CaretRightOutlined />
                        </MoreOffers>
                      )}
                    </ProductList>
                  </BusinessInfo>
                </BusinessHeader>
              </BusinessCard>
            ))
          ) : (
            <EmptyContainer>
              <Empty
                description='沒有該地區或類型的店家，請嘗試其他地區或類型'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </EmptyContainer>
          )}
        </BusinessList>

        <PaginationContainer>
          <Pagination
            current={currentPage}
            total={totalBusinesses}
            pageSize={10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </PaginationContainer>
      </MainContent>
    </Container>
  )
}

export default BusinessesPage
