import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { message, Empty, Button, Drawer, Flex } from 'antd'
import moment from 'moment'
import 'moment/locale/zh-tw'
import api from '../utils/api'
import { colors } from '../utils/utils'
import { EyeOutlined } from '@ant-design/icons'
import { PHOTO_URL, API_BASE_URL } from '../utils/apiutils'
import axios from 'axios'

const Container = styled.div``

const Card = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: 768px) {
    padding: 12px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

const Status = styled.div`
  height: 24px;
  background-color: ${(props) =>
    props.status === 'pending'
      ? '#FFA000'
      : props.status === 'store-accepted'
      ? '#4CAF50'
      : props.status === 'store-rejected'
      ? '#F44336'
      : '#9E9E9E'};
  color: white;
  padding: 4px 8px;
  margin-right: 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
`

const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const JobTitle = styled.h3`
  margin: 0 0 4px 0;
  color: #333;
  font-size: 18px;
  font-weight: bold;
`

const BusinessName = styled.p`
  margin: 0 0 4px 0;
  font-size: 16px;
  color: ${colors.primary};
  cursor: pointer;
`

const JobType = styled.span`
  background-color: ${colors.lightPink};
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
`

const Location = styled.span`
  color: #666;
  font-size: 14px;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #d45d7a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`

const FilterButton = styled.button`
  background-color: ${(props) => (props.active ? colors.primary : 'white')};
  color: ${(props) => (props.active ? 'white' : colors.primary)};
  border: 1px solid ${colors.primary};
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
  @media (max-width: 480px) {
    padding: 5px 13px;
  }
`

const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #f0f0f0;
`

const ViewDetailsButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: ${colors.lightGrey};
  border-color: ${colors.lightGrey};
  &:hover,
  &:focus {
    background-color: ${colors.lightGrey} !important;
    border-color: ${colors.lightGrey} !important;
  }
`
const DrawerContent = styled.div`
  padding: 0 30px 30px 30px;
`

const DrawerTitle = styled.h2`
  font-size: 20px;
  color: ${colors.primary};
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: bold;
`

const DrawerDescription = styled.p`
  font-size: 16px;
  color: ${colors.text};
  margin-bottom: 20px;
  line-height: 1.6;
  background-color: ${colors.paleYellow};
  padding: 10px;
  border-radius: 10px;
`

const DrawerInfo = styled.p`
  font-size: 14px;
  color: ${colors.text};
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`

const AreaTag = styled.span`
  background-color: ${colors.lightGrey};
  color: ${colors.text};
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
`

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 20px;
`

const PortfolioImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
`

const AppliedJobs = () => {
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('all')
  const navigate = useNavigate()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [selectedJob, setSelectedJob] = useState(null)
  const [portfolios, setPortfolios] = useState([])

  useEffect(() => {
    fetchApplications()
  }, [])

  const fetchApplications = async () => {
    try {
      setLoading(true)
      const response = await api.get('/api/user/applications')
      // 過濾掉 'invited' 狀態的應用
      const filteredApplications = response.data.data.filter(
        (app) => app.status !== 'invited'
      )
      setApplications(filteredApplications)
    } catch (error) {
      console.error('獲取應徵記錄失敗:', error)
      message.error('獲取應徵記錄失敗，請稍後再試')
    } finally {
      setLoading(false)
    }
  }

  const renderJobType = (recruitmentType) => {
    switch (recruitmentType) {
      case 'employee':
        return '正式員工'
      case 'experienceModel':
        return '體驗模特兒'
      case 'reciprocalModel':
        return '互惠模特兒'
      default:
        return '未知類型'
    }
  }

  const renderStatus = (status) => {
    switch (status) {
      case 'pending':
        return '待處理'
      case 'store-accepted':
        return '已接受'
      case 'store-rejected':
        return '已拒絕'
      default:
        return '未知狀態'
    }
  }

  const filterApplications = (applications) => {
    switch (activeTab) {
      case 'pending':
        return applications.filter((app) => app.status === 'pending')
      case 'store-accepted':
        return applications.filter((app) => app.status === 'store-accepted')
      case 'store-rejected':
        return applications.filter((app) => app.status === 'store-rejected')
      default:
        return applications
    }
  }

  const handleTabChange = (key) => {
    setActiveTab(key)
  }

  const handleJobClick = async (job) => {
    setSelectedJob(job)
    setDrawerVisible(true)

    // 獲取店家作品集
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/business_C/${job.businessId}/stylists-portfolios`
      )
      const allStylists = response.data.data
      const allPortfolios = allStylists.flatMap((stylist) =>
        stylist.portfolio.map((portfolio) => ({
          ...portfolio,
          stylistName: stylist.name,
          stylistAvatar: stylist.avatar
        }))
      )
      setPortfolios(allPortfolios.slice(0, 10)) // 只取前10個作品
    } catch (error) {
      console.error('獲取店家作品集失敗:', error)
      message.error('獲取店家作品集失敗')
    }
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    )
  }

  return (
    <Container>
      <ButtonGroup>
        <FilterButton
          active={activeTab === 'all'}
          onClick={() => handleTabChange('all')}
        >
          全部
        </FilterButton>
        <FilterButton
          active={activeTab === 'pending'}
          onClick={() => handleTabChange('pending')}
        >
          待處理
        </FilterButton>
        <FilterButton
          active={activeTab === 'store-accepted'}
          onClick={() => handleTabChange('store-accepted')}
        >
          已接受
        </FilterButton>
        <FilterButton
          active={activeTab === 'store-rejected'}
          onClick={() => handleTabChange('store-rejected')}
        >
          已拒絕
        </FilterButton>
      </ButtonGroup>
      {filterApplications(applications).length > 0 ? (
        filterApplications(applications).map((application) => (
          <Card key={application._id}>
            <CardHeader>
              <JobTitle>
                <Status status={application.status}>
                  {renderStatus(application.status)}
                </Status>
                {application.title}
              </JobTitle>
            </CardHeader>
            <CardBody>
              <MainInfo>
                <BusinessName
                  onClick={() => navigate(`/store/${application.business}`)}
                >
                  {application.businessName}
                </BusinessName>
                <div>
                  <JobType>
                    {renderJobType(application.recruitmentType)}
                  </JobType>
                  <Location>
                    {application.location?.city}{' '}
                    {application.location?.district}
                  </Location>
                </div>
              </MainInfo>
              <DateTimeContainer>
                可體驗時間：
                {moment(application.dateTimeRange.start).format(
                  'MM/DD HH:mm'
                )}{' '}
                - {moment(application.dateTimeRange.end).format('MM/DD HH:mm')}
              </DateTimeContainer>
            </CardBody>
            <CardActions>
              <DateTimeContainer>
                應徵日期：{moment(application.createdAt).format('YYYY/MM/DD')}
              </DateTimeContainer>
              <ViewDetailsButton
                icon={<EyeOutlined />}
                onClick={() => handleJobClick(application)}
              >
                查看詳情
              </ViewDetailsButton>
            </CardActions>
          </Card>
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='暫無應徵記錄'
        />
      )}
      <Drawer
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        placement='right'
        width={window.innerWidth > 768 ? 500 : '100%'}
      >
        {selectedJob && (
          <DrawerContent>
            <DrawerTitle>{selectedJob.title}</DrawerTitle>
            <Flex gap={2}>
              <JobType>{renderJobType(selectedJob.recruitmentType)}</JobType>
              <AreaTag>{selectedJob.location?.city}</AreaTag>
              <AreaTag>{selectedJob.location?.district}</AreaTag>
            </Flex>
            <DrawerInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                <circle cx='12' cy='10' r='3'></circle>
              </svg>
              {selectedJob.businessName}
            </DrawerInfo>
            <DrawerInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <rect x='3' y='4' width='18' height='18' rx='2' ry='2'></rect>
                <line x1='16' y1='2' x2='16' y2='6'></line>
                <line x1='8' y1='2' x2='8' y2='6'></line>
                <line x1='3' y1='10' x2='21' y2='10'></line>
              </svg>
              {moment(selectedJob.dateTimeRange?.start).format(
                'YYYY/MM/DD HH:mm'
              )}{' '}
              -
              {moment(selectedJob.dateTimeRange?.end).format(
                'YYYY/MM/DD HH:mm'
              )}
            </DrawerInfo>
            <DrawerInfo>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>
                <polyline points='14 2 14 8 20 8'></polyline>
                <line x1='16' y1='13' x2='8' y2='13'></line>
                <line x1='16' y1='17' x2='8' y2='17'></line>
                <polyline points='10 9 9 9 8 9'></polyline>
              </svg>
              招聘項目:{' '}
              {selectedJob.recruitmentItems.map((item) => item.name).join('、')}
            </DrawerInfo>
            <DrawerDescription>{selectedJob.description}</DrawerDescription>
            <DrawerTitle
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  `https://beautefeel.com/store/${selectedJob.businessUsername}/portfolio`,
                  '_blank'
                )
              }}
            >
              店家作品集
            </DrawerTitle>
            <PortfolioGrid>
              {portfolios.map((portfolio) => (
                <PortfolioImage
                  key={portfolio._id}
                  src={`${PHOTO_URL}${portfolio.imageUrls[0]}`}
                  alt={portfolio.title}
                  onClick={() => {
                    window.open(
                      `https://beautefeel.com/store/${selectedJob.businessUsername}/portfolio`,
                      '_blank'
                    )
                  }}
                />
              ))}
            </PortfolioGrid>
          </DrawerContent>
        )}
      </Drawer>
    </Container>
  )
}

export default AppliedJobs
