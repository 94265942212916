import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Select, Drawer, Pagination } from 'antd'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'
import { BiSolidCategory } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import api from '../utils/api'
import { PHOTO_URL } from '../utils/apiutils'

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`

const ProductCard = styled.div`
  background: white;
  /* border-radius: 8px; */
  overflow: hidden;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`

const ProductImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
`

const ProductInfo = styled.div`
  padding: 15px;
`

const ProductName = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #333;
`

const ProductPrice = styled.div`
  color: #d5728a;
  font-weight: bold;
  margin-top: 8px;
`

const ProductStatus = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 5px;
`

const PurchaseButton = styled.button`
  width: 100%;
  padding: 8px;
  background: #d5728a;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background: #bf5170;
  }
`

const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  background-color: #f7f7f7;
  padding: 5px 15px;
  border-radius: 5px;
  gap: 10px;

  @media (max-width: 768px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`

const CategorySelect = styled(Select)`
  min-width: 100px;
  max-width: 200px;
  .ant-select-selector {
    border: none !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const MobileCategoryButton = styled.button`
  display: none;
  border: none;
  background-color: #d5728a;
  color: #fff;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`

const PcCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #d5728a;

  @media (max-width: 768px) {
    display: none;
  }
`

const CategoryTag = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${(props) => (props.$active ? '#d5728a' : '#f0f0f0')};
  color: ${(props) => (props.$active ? '#fff' : '#d5728a')};
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.$active ? '#C4617A' : '#e0e0e0')};
  }
`

const MobileFilterResult = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    color: #333;
  }
`

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;

  @media (max-width: 768px) {
    flex: 1;
    margin: 0 10px;
    justify-content: flex-end;
  }
`

const SearchInput = styled.input`
  padding: 8px 15px;
  padding-right: 35px;
  width: ${(props) => (props.$isExpanded ? '240px' : '40px')};
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: ${(props) => (props.$isExpanded ? '1' : '0')};
  visibility: ${(props) => (props.$isExpanded ? 'visible' : 'hidden')};

  &:focus {
    outline: none;
    border-color: #ccc;
    box-shadow: 0 2px 12px rgba(213, 114, 138, 0.2);
  }

  &::placeholder {
    color: #999;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 20px;
    opacity: 1;
    visibility: visible;
    font-size: 13px;
  }
`

const SearchButton = styled.button`
  position: absolute;
  right: 0;
  border: none;
  background-color: #d5728a;
  color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 2;

  &:hover {
    background-color: #bf5170;
  }

  .anticon {
    font-size: 16px;
  }
`

const StoreProducts = ({ businessId }) => {
  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('default')
  const [selectedCategoryName, setSelectedCategoryName] = useState('全部')
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isSearchExpanded, setIsSearchExpanded] = useState(false)
  const searchInputRef = useRef(null)
  const navigate = useNavigate()
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0
  })

  // 獲取分類列表
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get(
          `/api/client/store/${businessId}/categories`
        )
        setCategories(response.data.data)
      } catch (error) {
        console.error('獲取分類失敗:', error)
        setError('獲取分類失敗')
      }
    }

    if (businessId) {
      fetchCategories()
    }
  }, [businessId])

  // 獲取商品列表
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true)
      try {
        const params = {
          page: pagination.current,
          pageSize: pagination.pageSize,
          category: selectedCategory !== 'all' ? selectedCategory : undefined,
          search: searchTerm || undefined,
          sortBy
        }

        const response = await api.get(
          `/api/client/store/${businessId}/products`,
          { params }
        )

        setProducts(response.data.data.products)
        setFilteredProducts(response.data.data.products)
        setPagination({
          ...pagination,
          ...response.data.data.pagination
        })
      } catch (error) {
        console.error('獲取商品失敗:', error)
        setError('獲取商品失敗')
      } finally {
        setLoading(false)
      }
    }

    if (businessId) {
      fetchProducts()
    }
  }, [businessId, pagination.current, selectedCategory, sortBy])

  // 搜索防抖
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        setPagination((prev) => ({ ...prev, current: 1 })) // 重置頁碼
      }
    }, 500)

    return () => clearTimeout(debounceTimer)
  }, [searchTerm])

  // 處理篩選和搜索
  useEffect(() => {
    let result = [...products]

    // 分類篩選
    if (selectedCategory !== 'all') {
      result = result.filter(
        (product) => product.categoryId === selectedCategory
      )
    }

    // 搜索篩選 - 添加空值檢查
    if (searchTerm) {
      result = result.filter((product) =>
        (product.title || '').toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    // 排序
    switch (sortBy) {
      case 'price-asc':
        result.sort((a, b) => a.price - b.price)
        break
      case 'price-desc':
        result.sort((a, b) => b.price - a.price)
        break
      case 'name':
        result.sort((a, b) => (a.title || '').localeCompare(b.title || ''))
        break
      default:
        break
    }

    setFilteredProducts(result)
  }, [products, selectedCategory, searchTerm, sortBy])

  const handleCategoryChange = (value) => {
    setSelectedCategory(value)
    const categoryName =
      value === 'all'
        ? '全部'
        : categories.find((cat) => cat.id === value)?.name || '全部'
    setSelectedCategoryName(categoryName)
    setDrawerVisible(false)
  }

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible)
  }

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded)
    if (!isSearchExpanded) {
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 100)
    }
  }

  // 添加點擊外部關閉搜索框的功能
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSearchExpanded &&
        !event.target.closest('.search-container') &&
        !searchTerm
      ) {
        setIsSearchExpanded(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSearchExpanded, searchTerm])

  const handleProductClick = (businessId, productId) => {
    navigate(`/product/${businessId}/${productId}`)
  }

  return (
    <>
      {error && (
        <div style={{ textAlign: 'center', color: 'red', padding: '20px' }}>
          {error}
        </div>
      )}

      {loading ? (
        <div style={{ textAlign: 'center', padding: '40px' }}>加載中...</div>
      ) : (
        <>
          <CategoryContainer>
            <PcCategoryTitle>
              <BiSolidCategory style={{ marginRight: '5px' }} /> 按分類篩選
            </PcCategoryTitle>
            <CategorySelect
              placeholder='選擇分類'
              onChange={handleCategoryChange}
              value={selectedCategory}
              aria-label='選擇商品分類'
            >
              <Select.Option value='all'>全部</Select.Option>
              {categories?.map((category) => (
                <Select.Option key={category._id} value={category._id}>
                  {category.name}
                </Select.Option>
              ))}
            </CategorySelect>

            <MobileCategoryButton
              onClick={toggleDrawer}
              aria-label='打開分類選擇'
            >
              <FilterOutlined /> 分類
            </MobileCategoryButton>
            <SearchContainer className='search-container'>
              <SearchInput
                ref={searchInputRef}
                $isExpanded={isSearchExpanded}
                type='text'
                placeholder='搜索商品...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchButton
                onClick={toggleSearch}
                aria-label={isSearchExpanded ? '關閉搜索' : '打開搜索'}
              >
                <SearchOutlined />
              </SearchButton>
            </SearchContainer>

            <MobileFilterResult>
              {selectedCategoryName !== '全部' &&
                `分類：${selectedCategoryName}`}
              {searchTerm && ` | 搜索：${searchTerm}`}
            </MobileFilterResult>
          </CategoryContainer>

          <Drawer
            title='選擇分類'
            placement='right'
            onClose={toggleDrawer}
            open={drawerVisible}
            width={300}
          >
            <CategoryTag
              $active={selectedCategory === 'all'}
              onClick={() => handleCategoryChange('all')}
              role='button'
              aria-pressed={selectedCategory === 'all'}
            >
              全部
            </CategoryTag>
            {categories.map((category) => (
              <CategoryTag
                key={category.id}
                $active={selectedCategory === category.id}
                onClick={() => handleCategoryChange(category.id)}
                role='button'
                aria-pressed={selectedCategory === category.id}
              >
                {category.name}
              </CategoryTag>
            ))}
          </Drawer>

          <ProductGrid>
            {filteredProducts.map((product) => (
              <ProductCard
                key={product.id}
                onClick={() => handleProductClick(businessId, product.id)}
                style={{ cursor: 'pointer' }}
              >
                <ProductImage
                  src={`${PHOTO_URL}${product.images[0]}`}
                  alt={product.title}
                />
                <ProductInfo>
                  <ProductName>{product.title}</ProductName>
                  <ProductPrice>
                    NT$ {product.price}
                    {product.originalPrice && (
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: '#999',
                          marginLeft: '8px',
                          fontSize: '14px'
                        }}
                      >
                        NT$ {product.originalPrice}
                      </span>
                    )}
                  </ProductPrice>
                  {product.specifications?.map((spec) => (
                    <ProductStatus key={spec._id}>
                      {spec.name}: {spec.options.join(', ')}
                    </ProductStatus>
                  ))}
                  <PurchaseButton
                    onClick={(e) => {
                      e.stopPropagation()
                      handleProductClick(businessId, product.id)
                    }}
                  >
                    選購
                  </PurchaseButton>
                </ProductInfo>
              </ProductCard>
            ))}
          </ProductGrid>

          {/* 添加分頁組件 */}
          {filteredProducts.length > 0 && (
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page) =>
                setPagination((prev) => ({ ...prev, current: page }))
              }
              style={{ textAlign: 'center', marginTop: '20px' }}
            />
          )}
        </>
      )}
    </>
  )
}

export default StoreProducts
