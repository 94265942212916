import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import NearbyMap from '../components/NearbyMap'
import { useNavigate } from 'react-router-dom'
import api from '../utils/api'
import Loading from '../utils/Loading'
import { Helmet } from 'react-helmet-async'

const MapContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001;
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #d5728a;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`

const LoadingMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
`

const NearbyMapPage = () => {
  const [salons, setSalons] = useState([])
  const [loading, setLoading] = useState(true)
  const [userLocation, setUserLocation] = useState(null)
  const [locationError, setLocationError] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    // 獲取用戶位置
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          // 獲取位置後，調用API獲取附近的沙龍
          fetchNearbySalons(position.coords.latitude, position.coords.longitude)
        },
        (error) => {
          console.error('Error getting location:', error)
          setLocationError('無法獲取您的位置。請確保您已授予位置權限。')
          setLoading(false)
        }
      )
    } else {
      setLocationError('您的瀏覽器不支持地理定位。')
      setLoading(false)
    }
  }, [])

  const fetchNearbySalons = async (lat, lng) => {
    try {
      const response = await api.get(`/api/search/nearby?lat=${lat}&lng=${lng}`)
      setSalons(response.data)
      setLoading(false)
    } catch (error) {
      console.error('獲取附近沙龍信息失敗:', error)
      setLocationError('獲取附近沙龍信息失敗。')
      setLoading(false)
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  return (
    <MapContainer>
      <Helmet>
        <title>{`全台美業店家地圖｜在線搜索｜線上查詢預約平台 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <CloseButton onClick={handleClose}>關閉</CloseButton>
      {loading ? (
        <Loading />
      ) : locationError ? (
        <LoadingMessage>{locationError}</LoadingMessage>
      ) : (
        <NearbyMap salons={salons} userLocation={userLocation} />
      )}
    </MapContainer>
  )
}

export default NearbyMapPage
