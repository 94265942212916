import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { API_BASE_URL } from '../utils/apiutils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #f5f5f5;
  @media (max-width: 820px) {
    padding: 100px 20px 100px 20px;
    height: 100%;
  }
`

const Card = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`

const Title = styled.h1`
  color: #333;
  margin-bottom: 20px;
`

const Message = styled.p`
  color: #666;
  font-size: 18px;
`

const Icon = styled(motion.div)`
  font-size: 64px;
  margin-bottom: 20px;
`

const UserBookingConfirmation = () => {
  const [status, setStatus] = useState('loading')
  const [message, setMessage] = useState('')
  const [action, setAction] = useState('')
  const location = useLocation()

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token')
    if (token) {
      handleBookingAction(token)
    } else {
      setStatus('error')
      setMessage('未提供有效的確認令牌')
    }
  }, [location])

  const handleBookingAction = async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/booking-action`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token }) // 將 token 放在請求體中
      })
      const data = await response.json()
      if (response.ok) {
        setStatus('success')
        setMessage(data.message || '操作成功完成')
        setAction(data.action) // 假設後端返回 action 字段
        console.log('data', data)
      } else {
        setStatus('error')
        setMessage(data.error || '操作失敗')
      }
    } catch (error) {
      console.error('處理預約操作時出錯:', error)
      setStatus('error')
      setMessage(error.message || '網絡錯誤，請稍後再試')
    }
  }

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return (
          <>
            <Icon
              animate={{ rotate: 360 }}
              transition={{ loop: Infinity, duration: 2 }}
            >
              🔄
            </Icon>
            <Title>正在處理您的請求...</Title>
            <Message>請稍候，我們正在處理您的預約操作。</Message>
          </>
        )
      case 'success':
        return (
          <>
            <Icon
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: 'spring' }}
            >
              {action === 'confirm' ? '✅' : '🚫'}
            </Icon>
            <Title>
              {action === 'confirm' ? '預約已被您確認！' : '預約已被您取消！'}
            </Title>
            <Message>{message}</Message>
          </>
        )
      case 'error':
        return (
          <>
            <Icon
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: 'spring' }}
            >
              ❌
            </Icon>
            <Title>出現了問題</Title>
            <Message>{message}</Message>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Container>
      <Card
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {renderContent()}
      </Card>
    </Container>
  )
}

export default UserBookingConfirmation
